.o-references-section {
  &__container {}

  &__tiles {
    display: grid;
    gap: 2rem;
    list-style: none;
    padding: 0;
    max-width: 996px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }
  }

  &__tile {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3rem 2rem;
    background-color: $white;
    box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
    border-radius: 2rem;

    @include media-breakpoint-up(md) {
      max-width: 486px;
      padding: 5rem 3rem;
    }

    &-top-bar {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #dcdce5;
      margin: -0.5rem -0.5rem 2rem -0.5rem;

      &-text {
        font-size: 14px;
        line-height: 20px;
        color: #919aaf;
        margin: 0.5rem;
      }

      &-link {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        padding: 0.25rem 1.5rem;
        color: $grey-400;
        background-color: #eaf0fe;
        border-radius: 2rem;
        margin: 0.5rem;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

        &:hover {
          color: $blue-400;
          background-color: #eaf0fe;
          transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
        }
      }
    }

    &-logo {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
      }

      img {
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }

    &-heading {
      margin-bottom: 1rem;
      font-family: $font-family-geomanist;
      font-weight: $font-weight-medium;
      font-size: 18px;
      line-height: 24px;
      color: $black;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-description {
      font-family: $font-family-roboto;
      color: $grey-400;
      font-size: 14px;
      line-height: 22px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &-cooperation {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      margin-top: 1.25rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3.25rem;
        margin-top: 2.25rem;
      }

      &-text {
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-size: 14px;
        line-height: 20px;
        color: #919aaf;
        margin: 0.75rem;
        margin-right: 0.625rem;
        margin-left: 0;
      }

      &-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 20px;
        margin: -0.75rem;
      }

      &-logo {
        display: flex;
        flex-shrink: 0;
        position: relative;
        margin: 0.75rem;

        &:not(:last-child) {
          padding-right: 1.5rem;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 20px;
            background-color: #dcdce5;
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &-buttons {
      margin-top: auto;
    }

    &-button {
      width: 100%;
      padding: 1rem 4rem;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 56, 80, 0.6);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
      padding: 0 2rem;
    }

    &.is-active {
      pointer-events: all;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &-container {
      width: 100%;
      height: 100%;
      max-width: 1210px;
      max-height: 100vh;
      background-color: #f2f3f5;
      overflow-y: auto;

      @include media-breakpoint-up(md) {
        border-radius: 1rem;
        max-height: calc(100vh - 4rem);
        height: initial;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      padding: 1rem;

      &-left {
        margin-right: 2rem;

        @include media-breakpoint-up(md) {
          margin-right: 0;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }

    &-heading {
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      margin: 0 0 0.5rem 0;

      @include media-breakpoint-up(md) {
        margin: 0 2rem 0 0;
        font-size: 20px;
        line-height: normal;
      }
    }

    &-cooperation {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;

      &-text {
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-size: 10px;
        line-height: 16px;
        color: #919aaf;
        margin: 0.75rem;
        margin-right: 0.625rem;

        @include media-breakpoint-up(md) {
          font-size: 14px;
          line-height: normal;
        }
      }

      &-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 20px;
        margin: -0.75rem;
      }

      &-logo {
        display: flex;
        flex-shrink: 0;
        position: relative;
        margin: 0.75rem;

        &:not(:last-child) {
          padding-right: 1.5rem;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 20px;
            background-color: #dcdce5;
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &-close {
      display: flex;
      flex-shrink: 0;
      border: none;
      padding: 0;
      background: transparent;
      margin-left: 1rem;

      &:hover {
        svg {
          circle {
            opacity: 0.6;
            transition: opacity 0.3s ease-in-out;
          }
        }
      }

      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        circle {
          transition: opacity 0.3s ease-in-out;
        }
      }
    }

    &-body {
      padding: 0 1.5rem 0 1rem;
      margin: 1rem 0;

      @include media-breakpoint-up(md) {
        max-height: 80vh;
        overflow-y: auto;
        padding: 0 1rem;
      }
    }

    &-reference-image {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
body.js-preload {
  .o-header.is-news-bar {
    transform: translateY(calc(-100% + 34px + 24px));
    transition: all $transition-base;

    @include media-breakpoint-up(lg) {
      transform: translateY(calc(-100% + 34px + 24px + 24px));
    }

    .o-news-bar {
      opacity: 0;
      pointer-events: none;
      transition: all $transition-base;
    }
  }
}

.o-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1.5rem 0;
  z-index: 999;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: background-color $transition-base, box-shadow $transition-base,
    border-bottom-color $transition-base;

  @include media-breakpoint-up(lg) {
    padding: 3rem 0;
  }

  &.is-active {
    .a-language-switcher--mobile-open-button {
      opacity: 1;
      pointer-events: all;
      transition: opacity 0.2s ease-in-out, border-color 0.2s ease-in-out;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &.is-sticky {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    border-bottom-color: #f2f3f6;
    background-color: $white;
  }

  &.is-news-bar {
    transform: translateY(-1.5rem);
    transition: all $transition-base;

    @include media-breakpoint-up(lg) {
      transform: translateY(-3rem);
    }

    .o-news-bar {
      opacity: 1;
      pointer-events: all;
      transition: all $transition-base;
    }

    &.is-active {
      padding-top: 1.5rem;

      .o-news-bar {
        display: none;
      }
    }
  }

  &.is-news-bar.is-active {
    transform: none;
    transition: none;
  }

  &.is-tier-list-popup-active {
    box-shadow: none;
    border-bottom-color: transparent;
    transition: border-bottom-color $transition-base;
  }

  &.is-blog {
    background-color: $white;
    padding-bottom: 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 999;
  }

  &__logo {
    width: 93px;
    display: block;

    @include media-breakpoint-up(lg) {
      width: 128px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__group {
    display: flex;
    align-items: center;
  }

  &__menu {
    font-family: $font-family-geomanist;
    display: none;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    transition: transform $transition-base;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .menu {
    &__item {
      margin-right: 3rem;
      transition: transform $transition-base;

      &:hover {
        .menu__item-external {
          svg path {
            fill: $primary;
            transition: fill $transition-base;
          }
        }
      }

      &--buttons {
        margin-top: 4rem;
      }

      &--with-children {
        position: relative;

        &:hover {
          .menu__item-label {
            color: $primary;
          }

          .menu__item-arrow {
            transform: rotate(180deg);
          }

          .menu__submenu-wrapper,
          .m-extended-submenu {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.2s ease-in-out;
          }
        }
      }

      &--additional-content-on-hover {
        position: relative;

        &:hover {
          .additional-content {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.3s ease-in-out;
          }
        }

        .additional-content {
          display: flex;
          align-items: center;
          position: absolute;
          right: -3rem;
          top: -3rem;
          width: max-content;
          max-height: 90vh;
          max-width: 533px;
          background-color: $white;
          padding: 3rem;
          border-radius: 0.5rem;
          box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s ease-in-out;

          &__col {
            &--1 {
              max-width: 211px;
              margin-right: 3rem;
            }

            &--2 {
              max-width: 250px;
              align-self: flex-start;
            }
          }

          &__image {
            img {
              width: auto;
              height: auto;
              max-width: 211px;
              max-height: calc(90vh - 6rem);
              border-radius: 0.5rem;
            }
          }

          &__heading {
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin-bottom: 1rem;
          }

          &__desc {
            margin-top: 1rem;
            color: $grey-400;
            font-size: 14px;
            line-height: 22px;

            li {
              font-family: $font-family-roboto;
              margin-bottom: 1.25rem;
              line-height: 2.75rem;
            }

            ul {
              padding-left: 0;
              list-style: none;

              &:not(:last-child) {
                margin-bottom: 1.25rem;
              }

              li {
                position: relative;
                padding-left: 1.5rem;

                &:last-child {
                  margin-bottom: 0;
                }

                &::marker {
                  content: '';
                  display: none;
                }

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0.875rem;
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #3c38bc;
                }
              }
            }
          }

          &__buttons {
            display: flex;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid #dcdce5;
          }

          &__button {
            margin-left: auto;

            &:hover {
              transform: none;
            }
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &-link,
      &-label {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $black;
        transition: color $transition-base;
      }

      &-label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &-arrow {
        margin-left: 0.75rem;
        transition: transform $transition-base;
      }

      &-external {
        display: flex;
        align-items: center;
        margin-left: 1.5rem;

        svg path {
          transition: fill $transition-base;
        }
      }

      &-link {
        &--active {
          color: $primary;
        }

        &:hover {
          color: $primary;
        }
      }

      &--language-switcher {
        position: initial;
        padding-top: 1.5rem;
        margin-top: 1.5rem;

        @include media-breakpoint-up(lg) {
          position: relative;
          padding-left: 2rem;
          margin-right: 2rem;
          padding-top: 0;
          margin-top: 0;
        }

        &::before {
          display: none;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 1px;
          background-color: #dcdce5;

          @include media-breakpoint-up(lg) {
            display: block;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 32px;
          }
        }
      }
    }

    &__submenu-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      padding-top: 2rem;
      transform: translate(-2rem, 100%);
      width: max-content;
      opacity: 0;
      pointer-events: none;
      transition: opacity $transition-base;
    }

    &__submenu-triangle {
      position: absolute;
      left: 5rem;
      top: 2rem;
      transform: translateY(-100%) translateY(1px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 12px 10px;
      border-color: transparent transparent #dcdce5 transparent;

      &--inner {
        left: 0;
        top: 0;
        transform: translateX(-50%) translateY(2px);
        border-width: 0 9px 11px 9px;
        border-color: transparent transparent #fff transparent;
      }
    }

    &__submenu {
      list-style-type: none;
      margin: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid #dcdce5;
      padding: 2rem 0;
    }

    .submenu {
      &__item {
        margin-bottom: 1.5rem;
        padding: 0 2rem;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          .submenu__item-external {
            svg path {
              fill: $primary;
              transition: fill $transition-base;
            }
          }
        }

        &--with-children {
          position: relative;

          &:hover {
            .submenu__item-label {
              color: $primary;
            }

            .submenu__item-arrow {
              svg {
                transform: translateX(3px) rotate(-90deg);
                transition: transform $transition-base;
              }
            }

            .submenu__sub-submenu-wrapper {
              opacity: 1;
              pointer-events: all;
              transition: opacity $transition-base;
            }
          }
        }

        &-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }

        &-arrow {
          margin-left: 1.5rem;

          svg {
            transform: rotate(-90deg);
            transition: transform $transition-base;
          }
        }

        &-external {
          display: flex;
          align-items: center;
          margin-left: 1.5rem;

          svg path {
            transition: fill $transition-base;
          }
        }

        &-link,
        &-label {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: $black;
          transition: color $transition-base;
        }

        &-link {
          &--active {
            color: $primary;
          }

          &:hover {
            color: $primary;
          }
        }
      }

      &__sub-submenu-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(100%, -2rem);
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-base;
      }

      &__sub-submenu {
        list-style-type: none;
        margin: 0;
        width: max-content;
        max-width: 300px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #dcdce5;
        padding: 2rem;
      }
    }

    .sub-submenu {
      &__item {
        margin-bottom: 1.5rem;

        &:hover {
          .sub-submenu__item-external {
            svg path {
              fill: $primary;
              transition: fill $transition-base;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-link {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: $black;
          transition: color $transition-base;

          &--active {
            color: $primary;
          }

          &:hover {
            color: $primary;
          }
        }

        &-external {
          display: flex;
          align-items: center;
          margin-left: 1.5rem;

          svg path {
            transition: fill $transition-base;
          }
        }
      }
    }
  }

  &__hamburger {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__side-nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $white;
    padding: 7rem 0 6rem;
    z-index: 990;
    transform: translateX(100%);
    transition: transform $transition-base;

    &.is-active {
      transform: translateX(0);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .side-nav {
    &__container {
      height: calc(100% - 1.5rem);
      margin-top: 1.5rem;
    }

    &__mobile-menu {
      position: relative;
      height: 100%;
      padding: 0;
      list-style-type: none;
      overflow-y: auto;

      .menu__item {
        &--buttons {
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
          margin-right: 0;

          .menu__button {
            &:not(:last-child) {
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }

    .mobile-menu {
      font-family: $font-family-geomanist;
      line-height: 24px;

      .mobile-menu__item {
        &--integrations {
          .submenu__item-link-icon {
            width: 5rem !important;
            align-self: center;

            img {
              width: 100%;
            }
          }
        }

        &--benefits {
          .submenu {
            padding-left: 0;

            &__item {
              &-link {
                align-items: center;

                &-icon {
                  display: none;
                }
              }
            }
          }
        }
      }

      &__item {
        border-top: 1px solid #dcdce5;

        &--buttons {
          margin-top: 4rem;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-link {
          font-size: 18px;
          color: $black;
          display: flex;
          align-items: center;
          padding: 1.875rem 0 2rem;
          transition: color $transition-base;

          &--active {
            color: $primary;
          }

          &:hover {
            color: $primary;
          }
        }

        &-label {
          font-size: 18px;
          font-weight: $font-weight-book;
          color: #919aaf;
          display: block;
          padding: 2rem 0;
        }

        &-arrow {
          margin-left: 1rem;

          svg {
            transition: transform $transition-base;
          }
        }

        &-external {
          display: flex;
          align-items: center;
          margin-left: 1.5rem;

          svg path {
            transition: fill $transition-base;
          }
        }

        &-multi-nested {
          font-weight: $font-weight-normal;
          color: $black;

          &+.mobile-menu__submenu {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            padding-bottom: 0;
            transition: opacity 0.2s ease-in-out, max-height 0.2s ease-in-out,
              padding-bottom 0.1s ease-in-out 0.1s;
          }

          &.is-active {
            &+.mobile-menu__submenu {
              max-height: 1000px;
              opacity: 1;
              padding-bottom: 0.5rem;
              pointer-events: all;
              transition: padding-bottom 0.2s ease-in-out,
                max-height 0.2s ease-in-out 0.15s, opacity 0.2s ease-in-out;
            }

            .mobile-menu__item-arrow svg {
              transform: rotate(180deg);
              transition: transform $transition-base;
            }
          }
        }
      }

      &__button {
        margin-right: 2rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      &__submenu {
        padding: 0 0 0.5rem 1.5rem;
        margin: 0;
        list-style-type: none;
      }

      .submenu {
        &__item {
          &:first-child {
            .submenu__item {

              &-link,
              &-label {
                padding-top: 0;
              }
            }
          }

          &.is-active {
            .submenu__sub-submenu {
              max-height: 1000px;
              opacity: 1;
              pointer-events: all;
              transition: max-height $transition-base 0.15s,
                opacity $transition-base;
            }

            .submenu__item-arrow {
              svg {
                transform: rotate(180deg);
                transition: transform $transition-base;
              }
            }
          }

          &-arrow {
            margin-left: 1rem;

            svg {
              transition: transform $transition-base;
            }
          }

          &-external {
            display: flex;
            align-items: center;
            margin-left: 1.5rem;

            svg path {
              transition: fill $transition-base;
            }
          }

          &-label,
          &-link {
            font-size: 18px;
            color: $black;
            display: flex;
            align-items: flex-start;
            padding: 1.5rem 0;
            transition: color $transition-base;
          }

          &-link {
            &--active {
              color: $primary;
            }

            &:hover {
              color: $primary;

              .submenu__item-link-icon {
                svg {
                  path {
                    fill: $blue-400;
                    transition: fill 0.2s ease-in-out;
                  }
                }
              }
            }

            &-image {
              margin-right: 1.5rem;
              flex-shrink: 0;

              img {
                width: 100%;
                max-width: 8.75rem;
                height: auto;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                object-position: center;
                border-radius: 1rem;
              }
            }

            &-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.5rem;
              margin-right: 1rem;
              flex-shrink: 0;

              svg {
                width: 100%;
                height: 100%;

                path {
                  fill: #919aaf;
                  transition: fill 0.2s ease-in-out;
                }
              }
            }
          }

          &-read-more {
            display: flex;
            align-items: center;

            &-text {
              font-family: $font-family-geomanist;
              font-weight: $font-weight-normal;
              color: $blue-400;
              font-size: 18px;
              line-height: 24px;
              transition: fill 0.2s ease-in-out;
            }

            &-icon {
              display: flex;
              margin-left: 1rem;

              svg {
                transition: transform 0.2s ease-in-out;

                path {
                  transition: fill 0.2s ease-in-out;
                }
              }
            }
          }
        }

        &__sub-submenu {
          max-height: 0;
          opacity: 0;
          list-style: none;
          overflow: hidden;
          padding-left: 2rem;
          transition: opacity $transition-base,
            max-height $transition-base 0.15s;
        }
      }

      .sub-submenu {
        &__item {
          &:first-child {
            .sub-submenu__item {

              &-link,
              &-label {
                padding-top: 0.5rem;
              }
            }
          }

          &:hover {
            .sub-submenu__item-external {
              svg path {
                fill: $primary;
                transition: fill $transition-base;
              }
            }
          }

          &-link {
            font-size: 18px;
            color: $black;
            display: flex;
            align-items: center;
            padding: 1.5rem 0;
            transition: color $transition-base;

            &--active {
              color: $primary;
            }

            &:hover {
              color: $primary;
            }
          }

          &-external {
            display: flex;
            align-items: center;
            margin-left: 1.5rem;

            svg path {
              transition: fill $transition-base;
            }
          }
        }
      }

      &__fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &>* {
          margin: 0 2rem 2rem;
          border-top: 1px solid #dcdce5;
          background: #fff;
          padding-top: 1.375rem;
        }

        .menu__button {
          padding: 0.875rem 1.5rem;
          line-height: 16px;
        }
      }
    }
  }
}

body:not(.single-blog) {
  .o-header {
    @include media-breakpoint-up(lg) {
      &.is-blog.is-sticky {
        .o-header__menu {
          .menu__item:not(:last-child) {
            transform: translateY(-250%);
            opacity: 0;
            pointer-events: none;
            transition: transform $transition-base, opacity $transition-base;
          }
        }

        .o-blog-nav {
          z-index: 1000;
          padding-top: 0;
          transform: translateY(-110%);
          transition: transform $transition-base 0.2s, padding-top $transition-base 0.2s;

          &__content-wrapper {
            padding-bottom: 0;
            border-bottom: none;
            max-width: calc(100% - (var(--main-menu-featured-button) - 165px) - 3rem);
            transition: padding-bottom $transition-base, max-width $transition-base;
          }
        }
      }
    }
  }
}

body.single-blog {
  .o-header {
    @include media-breakpoint-up(lg) {
      &.is-blog.is-sticky {
        .o-header__menu {
          .menu__item:not(:last-child) {
            transform: translateY(-250%);
            opacity: 0;
            pointer-events: none;
            transition: transform $transition-base, opacity $transition-base;
          }
        }

        .o-blog-nav {
          z-index: 1000;
          padding-top: 0;
          transform: translateY(-110%);
          transition: transform $transition-base 0.2s, padding-top $transition-base 0.2s;

          &__content-wrapper {
            padding-bottom: 0;
            border-bottom: none;
            max-width: calc(100% - 15.5rem - var(--main-menu-featured-button));
            margin-left: 15.5rem;
            transition: padding-bottom $transition-base, margin-left $transition-base, max-width $transition-base;

            @media screen and (min-width: 992px) and (max-width: 1270px) {
              max-width: calc(100% - 15.5rem - 6rem - var(--main-menu-featured-button));
              margin-left: 18.5rem;
            }
          }
        }
      }
    }
  }
}
.m-quotations {
  &--is-slider {
    .m-quotations {
      &__item {
        padding-bottom: 3.5rem;
      }
    }
  }

  &__item {
    background-color: #f4f9fe;
    padding: 1.5rem;
    border-radius: 1rem;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
    }

    &-col {
      &--1 {
        @include media-breakpoint-up(md) {
          margin-right: 1.5rem;
          flex-shrink: 0;
        }
      }

      &--2 {}
    }

    &-icon {
      display: flex;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      svg {
        width: 3rem;
        height: auto;
      }
    }

    &-content {
      color: $grey-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 1.75rem;
      line-height: 2.75rem;

      * {
        margin: 0;
      }
    }

    &-signature {
      color: #38404A;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 1.75rem;
      line-height: 2.75rem;
      margin: 0;

      &-row {
        display: flex;
        align-items: center;
        margin-top: 1rem;
      }
    }

    &-avatar {
      margin-right: 1.5rem;

      img {
        width: 6.75rem;
        height: 6.75rem;
        border-radius: 50%;
      }
    }
  }

  .swiper {
    &-container {}

    &-wrapper {}

    &-slide {}

    &-pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &-bullet {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #dcdce5;
        margin: 0 0.75rem;

        &-active {
          background-color: #919aaf;
        }
      }
    }
  }
}
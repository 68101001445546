.o-landing-page-newsletter-form {

  &__container {}

  &__wrapper {
    position: relative;
    max-width: 760px;
    margin: 0 auto;
    background-color: $white;
    padding: 3rem;
    border: 1px solid #dcdce5;
    box-shadow: 0 0 16px 0 rgba(39, 56, 80, 0.1);
    border-radius: 2rem;
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    text-align: center;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 4rem;
    }
  }

  &__form {}

  &__inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &--1 {
      .o-landing-page-newsletter-form__input {
        max-width: 100%;
      }
    }
  }

  &__input {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      max-width: calc(50% - 1.5rem);
    }

    &--full {
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }

    .choices {
      &__inner {
        background-color: #fff;
        border-color: #dcdce5;
      }

      &__list {
        &--dropdown {
          border-color: #dcdce5 !important;
        }
      }
    }
  }

  &__inputs .a-select__custom-text-option {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      max-width: calc(50% - 1.5rem);
    }

    &--full {
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }

  &__consent {}

  &__checkbox {
    .a-checkbox__label {
      margin-bottom: 0;
    }
  }

  &__button {
    position: relative;
    width: 100%;
    margin-top: 2rem;

    &.is-loading {
      pointer-events: none;

      .o-landing-page-newsletter-form {
        &__loader {
          display: flex;
        }
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
  }

  &__notification {
    max-width: calc(100% - 2rem);
  }

  &__logos {
    width: 100%;
    border-top: 1px solid #dcdce5;
    margin-top: 3rem;
  }

  &__blob {
    display: none;

    @include media-breakpoint-up(md) {
      position: absolute;
      display: block;
      z-index: -1;
    }

    &--1 {
      top: -49.5px;
      right: -111.1px;
    }

    &--2 {
      left: -100px;
      bottom: -100px;
    }
  }
}
.o-slider-section {
  &__container {
    max-width: 1146px;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 4rem;
    }
  }

  &__slider {
    position: relative;
    max-width: 996px;
    margin: 0 auto;
    cursor: grab;

    .swiper-container {
      padding: 6rem 0;
      margin-top: -3rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .swiper-slide {
      height: auto;
      opacity: 60%;
      transform: scale(0.95);
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

      @include media-breakpoint-up(md) {
        transform: scale(0.9);
        pointer-events: none;
      }

      &-active {
        pointer-events: all;
        opacity: 100%;
        transform: scale(1.1);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

        @include media-breakpoint-up(md) {
          transform: scale(1.15);
        }
      }
    }
  }

  .slider {
    &__pagination {
      position: absolute;
      left: 50%;
      bottom: 0;
      max-width: calc(100% - 4rem);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-50%);
      z-index: 1;

      .swiper-pagination-bullet {
        width: 1rem;
        height: 1rem;
        background-color: #dcdce5;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        @include media-breakpoint-up(md) {
          width: 2rem;
          height: 2rem;
        }

        &:not(:last-child) {
          margin-right: 2rem;
        }

        &-active {
          background-color: #919aaf;
          transition: background-color 0.3s ease-in-out;
        }
      }
    }

    &__button {
      display: none;
      position: absolute;
      top: 50%;
      width: 3rem;
      height: 3rem;
      background-color: transparent;
      border: none;
      z-index: 1;
      transition: opacity 0.3s ease-in-out;

      @include media-breakpoint-up(md) {
        display: block;
      }

      &.swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 24px;
        height: 24px;
        mask-image: url("../../../../../assets/private/img/slider-arrow.svg");
        background-color: $blue-400;
        mask-size: contain;
        mask-repeat: no-repeat;
        transform: translate(-50%, -50%);
        transition: background-color $transition-base;
      }

      &:hover {
        &::before {
          background-color: $primary;
        }
      }

      &--prev {
        left: 2rem;
        transform: translate(-50%, -50%);

        &::before {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }

      &--next {
        right: 2rem;
        transform: translate(50%, -50%);
      }
    }

    &__slide {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    }

    .slide {
      &__img-wrapper {
        border-radius: 1rem 1rem 0 0;
        height: 87.28px;
        padding: 3.6rem;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(md) {
          height: 104.35px;
          padding: 4.5rem;
        }
      }

      &__additional-img-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 120px;
        max-height: 32px;

        @include media-breakpoint-up(md) {
          max-width: 235px;
          max-height: 48px;
        }

        img {
          width: auto;
          height: auto;
        }

        img,
        svg {
          max-height: 29.73px;

          @include media-breakpoint-up(md) {
            max-height: 41.74px;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.8rem 1.8rem 2.7rem;
        background-color: $white;
        border-radius: 0 0 1rem 1rem;
        height: 100%;

        @include media-breakpoint-up(md) {
          padding: 3rem 3rem 4rem 3rem;
          border-radius: 0 0 1rem 1rem;
        }
      }

      &__quote {
        font-size: 12.6px;
        line-height: 19.8px;
        color: $grey-400;
        margin-bottom: 2.7rem;

        @include media-breakpoint-up(md) {
          font-size: 15.3px;
          line-height: 1.4;
        }
      }

      &__bottom {
        margin-top: auto;
      }

      &__signature {
        @include media-breakpoint-up(md) {
          max-width: 320px;
        }
      }

      &__name {
        font-size: 12.6px;
        font-weight: $font-weight-medium;
        line-height: 2.3375rem;
        color: $black;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: 11.9px;
        }
      }

      &__company {
        margin-bottom: 0;
        font-size: 12.6px;
        line-height: 2.3375rem;
        font-weight: $font-weight-normal;
        color: $black;

        @include media-breakpoint-up(md) {
          font-size: 11.9px;
        }
      }

      &__button {
        display: flex;
        align-items: center;
        margin-top: 1.7rem;
        font-size: 11.9px;

        svg {
          margin-left: 0.85rem;
        }
      }
    }
  }
}
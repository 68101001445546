.o-news-bar {
  display: block;
  width: 100%;
  background-color: $blue-500;
  z-index: 1000;
  margin-bottom: 3rem;

  &.is-hidden {
    display: none;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-up(md) {
      text-align: center;
      align-items: center;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }

  &__content {
    margin-left: auto;
    margin-bottom: 0;
    font-size: 1.75rem;
    line-height: 22px;
    font-weight: $font-weight-normal;
    color: $white;
    margin-right: 0.5rem;

    @include media-breakpoint-up(md) {
      margin-right: 0;
      font-size: 2.25rem;
      line-height: 28px;
    }

    &>* {
      margin-bottom: 0;
      font-size: 2.25rem;
      line-height: 28px;
      font-weight: $font-weight-normal;
      color: $white;
    }
  }

  &__link {
    flex-shrink: 0;
    margin-top: 1.5rem;
    font-size: 1.75rem;
    line-height: 22px;
    color: $orange-400;
    transition: color $transition-base;

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
      line-height: 28px;
    }

    &:hover {
      color: $orange-300;
      transition: color $transition-base;
    }
  }

  &__close {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    margin-left: auto;
  }
}
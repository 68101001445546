.o-quote-section {
  &__container {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__col {
    width: 100%;

    &--img {
      width: 160px;
      height: 160px;
      margin: 0 auto 4rem;

      @include media-breakpoint-up(md) {
        margin: 0 8rem 0 0;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
      }
    }

    &--content {
      @include media-breakpoint-up(md) {
        max-width: 754px;
      }
    }

    &--full {
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__quote {
    color: $grey-400;
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: 1.44;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &__name {
    color: #222;
    font-size: 14px;
    font-weight: $font-weight-book;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  &__position {
    color: $blue-400;
    font-size: 14px;
    font-weight: $font-weight-book;
    margin-top: 0.75rem;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
}
.o-our-target-group {
  &__container {}

  &__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5.5rem 0;
    list-style: none;
    margin: 0 -1.5rem;

    @include media-breakpoint-up(sm) {
      padding: 4rem 0;
      margin: 0 -1.5rem;
    }
  }
}

.o-our-target-group-item {
  display: flex;
  flex-direction: column;
  margin: -5.5rem 1.5rem;
  max-width: 368px;

  @include media-breakpoint-up(sm) {
    margin: -4rem 1.5rem;
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      border-radius: 2rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 4rem);
    height: 100%;
    margin: 0 auto;
    background-color: $white;
    border-radius: 2rem;
    box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
    transform: translateY(-14rem);
    padding: 3rem 2rem;

    @include media-breakpoint-up(sm) {
      padding: 4rem 3rem;
    }
  }

  &__row {
    &--1 {
      display: flex;
      align-items: center;
      padding-bottom: 2rem;
      border-bottom: 1px solid #dcdce5;
      margin-bottom: 2rem;
    }

    &--2 {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__icon {
    width: 5rem;
    flex-shrink: 0;
    margin-right: 3rem;

    @include media-breakpoint-up(sm) {
      width: 6.75rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    margin: 0;

    @include media-breakpoint-up(sm) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__desc {
    color: $grey-400;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 28px;
    }

    p {
      margin: 0;
    }

    a {
      color: $blue-400;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $blue-300;
        transition: color 0.2s ease-in-out;
      }
    }
  }

  &__buttons {
    margin-top: auto;
    padding-top: 2rem;
  }

  &__button {
    &:not(.a-arrow-button) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
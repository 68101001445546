.o-roi-calculator {
  color: $white;
  background-color: $purple-200;
  padding: 5rem 0;

  @include media-breakpoint-up(lg) {
    padding: 8rem 0;
  }

  &__wave {
    display: none;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      left: -47%;
      top: 0;
      width: 100%;
    }

    svg {
      @include media-breakpoint-up(lg) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__container {}

  &__row {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__col {
    width: 100%;

    &--1 {
      @include media-breakpoint-up(lg) {
        position: relative;
        padding-right: 3rem;
        width: 51.1%;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: #eaf0fe;
          opacity: 0.2;
        }
      }
    }

    &--2 {
      margin-top: 3rem;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }

      @include media-breakpoint-up(lg) {
        display: block;
        padding-left: 3rem;
        width: 48.9%;
        margin-top: 0;
      }
    }
  }

  &__heading {
    color: #f2f3f5;
    font-size: 26px;
    line-height: 32px;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 56px;
    }
  }

  &__description {
    color: #eaf0fe;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__form {
    margin-top: 2.5rem;

    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }

    &-group {
      margin-bottom: 4rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-label {
      font-family: $font-family-geomanist;
      font-weight: $font-weight-medium;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    &-explanation {
      display: block;
      color: #eaf0fe;
      font-size: 12px;
      line-height: 18px;
      margin-top: 0.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-input-wrapper {
      display: inline-flex;
      position: relative;

      @include media-breakpoint-up(lg) {
        margin-left: 4rem;
      }

      &.suffix {
        &::after {
          font-family: $font-family-roboto;
          color: #919aaf;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }

        &--currency {
          &::after {
            content: 'zł';
          }

          .o-roi-calculator__form-input {
            padding-right: 2.5rem;
          }
        }

        &--percent {
          &::after {
            content: '%';
          }

          .o-roi-calculator__form-input {
            padding-right: 2.25rem;
          }
        }
      }
    }

    &-input {
      &--number {
        font-family: $font-family-roboto;
        font-size: 14px;
        font-weight: 400;
        width: 172px;
        padding: 0.782rem 1rem;
        border: 1px solid #dcdce5;
        border-radius: 0.5rem;
        background-color: $white;
        -moz-appearance: textfield;
        text-align: right;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          outline: none;
        }
      }

      &--select {
        width: 172px;
      }
    }

    &-button {
      position: relative;
      width: 100%;
      height: 48px;

      &-text {
        font-size: 18px;
        line-height: 24px;
        font-weight: $font-weight-book;
      }

      &-loader {
        display: none;

        &-ellipsis {
          div {
            background-color: $purple-200;
          }
        }
      }
    }
  }

  &__result {
    @include media-breakpoint-up(sm) {
      width: 33.3333%;
      margin-bottom: 4rem;
    }

    @include media-breakpoint-up(lg) {
      width: initial;
      margin-bottom: 0;
    }

    &.with-explantation {
      @include media-breakpoint-up(sm) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      @include media-breakpoint-up(lg) {
        display: block;
        width: initial;
      }

      .o-roi-calculator__result-explanation {
        @include media-breakpoint-up(sm) {
          margin-left: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-left: auto;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    &-loader {
      display: none;
    }

    &-value {
      font-family: $font-family-geomanist;
      font-weight: $font-weight-medium;
      font-size: 28px;
      line-height: 40px;

      @include media-breakpoint-up(sm) {
        flex-shrink: 0;
        font-size: 32px;
      }

      @include media-breakpoint-up(lg) {
        flex-shrink: 1;
        font-size: 40px;
        line-height: 56px;
      }
    }

    &-key {
      font-family: $font-family-roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      max-width: 136px;
      margin-left: 2rem;

      @include media-breakpoint-up(sm) {
        max-width: 200px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 28px;
        max-width: 277px;
        margin-left: 3rem;
      }
    }

    &-explanation {
      font-family: $font-family-roboto;
      color: #eaf0fe;
      margin-left: auto;
      display: block;
      font-size: 12px;
      line-height: 18px;
      margin-top: 0.5rem;

      @include media-breakpoint-up(lg) {
        max-width: 466px;
        font-size: 14px;
        line-height: 20px;
        margin-top: 1rem;
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &__cta {
    width: 100%;

    &.is-hidden {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 98px;
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        .o-roi-calculator__cta-link {
          &-value {
            text-decoration: none;
          }
        }
      }

      &-value {
        font-size: 14px;
        line-height: 22px;
        color: $white;
        text-decoration: underline;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      &-icon {
        margin-left: 1rem;
        flex-shrink: 0;
        pointer-events: none;

        svg {
          width: 12px;
          height: auto;

          @include media-breakpoint-up(lg) {
            width: 16px;
          }
        }
      }
    }
  }

  &__error-message {
    display: none;
    font-size: 14px;
    line-height: 22px;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 28px;
      max-width: 466px;
      margin-left: auto;
    }

    &.is-active {
      display: block;
    }

    &-content {
      p {
        display: inline;
      }

      strong {
        font-weight: $font-weight-book;
      }
    }

    &-link {
      display: inline-block;
      color: $white;
      margin-top: 28px;
      text-decoration: underline;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(242, 52%, 61%);
  }

  100% {
    background-color: hsl(242, 46%, 57%);
  }
}
.a-textarea {
  &.has-error {
    .a-textarea {
      &__label {
        color: $red-error;
      }

      &__textarea {
        border-color: $red-error;
      }
    }
  }

  &__label {
    display: block;
    font-size: 14px;
    color: $grey-400;
    margin-bottom: 1rem;
    transition: color $transition-base;
  }

  &__textarea {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #dcdce5;
    background-color: $white;
    padding: 1rem;
    color: $black;
    font-size: 14px;
    transition: border-color $transition-base;
    resize: none;
    overflow-y: hidden;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }
}

.o-grid-section {
  &__container {
    max-width: 1200px;
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-size: 24px;
    font-weight: $font-weight-medium;
    text-align: center;
    color: $black;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 34px;
      font-weight: $font-weight-book;
      margin-bottom: 8rem;
    }
  }

  &__boxes {
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      margin: 0 -1rem;
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__box {
    width: 100%;
    max-width: 284px;
    margin: 0 auto 1rem;

    @include media-breakpoint-up(md) {
      margin: 0 1rem 2rem;
      width: calc(25% - 2rem);
    }
  }

  .box {
    &__link {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-color: $white;
      padding: 3rem;
      transition: background-color $transition-base;

      &:hover {
        .box {
          &__description {
            color: #6360c9;
          }
        }
      }
    }

    &__icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &__heading {
      font-family: $font-family-geomanist;
      font-size: 20px;
      font-weight: $font-weight-book;
      color: $primary;

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }

    &__description {
      margin-top: 1.5rem;
      font-size: 16px;
      line-height: 1.5;
      color: $grey-400;
      transition: color $transition-base;
    }
  }
}
.o-cta-bar {
  position: relative;
  background-color: $purple-200;
  padding: 4rem 0;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding: 10rem 0;
  }

  &__wave {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 300px;
    transform: translate(-50%, 40%) rotate(90deg);

    @include media-breakpoint-up(md) {
      width: auto;
      max-width: unset;
      left: auto;
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
    }

    @include media-breakpoint-up(lg) {
      right: 300px;
      bottom: -100px;
    }

    svg {
      width: 100%;
    }
  }

  &__container {
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      width: 70%;
      max-width: 720px;
      padding-right: 4rem;
    }
  }

  &__heading {
      font-family: $font-family-geomanist;
    font-size: 24px;
    font-weight: $font-weight-book;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 38px;
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__description {
    margin-top: 2rem;
    font-size: 16px;
    line-height: 1.25;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
      font-size: 18px;
      line-height: 1.4;
      text-align: left;
    }
  }

  &__buttons {
    margin-top: 4rem;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      width: 30%;
    }
  }
}
.o-share-media {
  margin-top: 3rem;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  &__container {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }

  &__items {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1.5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
      }
    }

    svg {
      display: block;
      width: 3rem;
      height: 3rem;

      path {
        transition: fill $transition-base;
      }
    }

    &--twitter {
      svg {
        path {
          fill: #919aaf;
        }
      }

      &:hover {
        svg {
          path {
            fill: #1b9df0;
          }
        }
      }
    }

    &--facebook {
      svg {
        path {
          fill: #919aaf;
        }
      }

      &:hover {
        svg {
          path {
            fill: #1877f2;
          }
        }
      }
    }

    &--linkedin {
      svg {
        path {
          fill: #919aaf;
          transition: fill $transition-base;
        }
      }

      &:hover {
        svg {
          path {
            fill: #0a66c2;
          }
        }
      }
    }
  }
}
.o-two-columns-section {

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &--reverse-desktop {
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;

        .o-two-columns-section {
          &__col {
            &--content {
              padding-left: 0;
              padding-right: 50px;
            }
          }
        }
      }
    }

    &--reverse-mobile {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;

        .o-two-columns-section {
          &__badges {
            &-items {
              margin-bottom: 0;
            }
          }

          &__col {
            &--img {
              margin-bottom: 0;
            }

            &--content {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  }

  &__wave {
    position: absolute;
    top: 50%;
    z-index: 1;

    &--right {
      right: 0;
      transform: translate(50%, -50%);
    }

    &--left {
      left: 0;
      transform: translate(-50%, -50%);
    }

    &--grey {
      svg {

        g,
        path,
        ellipse {
          stroke: #f2f3f5;
        }
      }
    }

    &--blue {
      svg {

        g,
        path,
        ellipse {
          stroke: $primary;
        }
      }
    }
  }

  &__col {
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &--img {
      margin-bottom: 3rem;

      @include media-breakpoint-up(md) {
        margin: 0;
        max-width: 574px;
      }
    }

    &--img-with-icon {
      padding-left: 4rem;
      padding-right: 2rem;

      @include media-breakpoint-up(md) {
        padding: 0;
      }

    }

    &--img-hidden-mobile {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &--content {
      @include media-breakpoint-up(md) {
        max-width: 521px;
        padding-left: 50px;
      }
    }
  }

  &__image-slider {
    .swiper {
      &-slide {
        img {
          width: 100%;
          height: auto;
        }
      }

      &-pagination {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        margin-top: 2rem;

        &-bullet {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: #dcdce5;
          margin: 0 0.75rem;

          @include media-breakpoint-up(sm) {
            width: 2rem;
            height: 2rem;
          }

          &-active {
            background-color: #919aaf;
          }
        }
      }
    }
  }

  &__img-wrapper {
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-top: 0 !important;
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid #dcdce5;
    padding: 2rem;
    box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);

    @include media-breakpoint-up(md) {
      padding: 4rem;
      width: 120px;
      height: 120px;

    }

    &--hidden-mobile {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    &--left,
    &--right {
      left: 0;
      transform: translate(-50%, -34%);
    }

    &--left {
      @include media-breakpoint-up(md) {
        transform: translate(-30%, -34%);
      }
    }

    &--right {
      @include media-breakpoint-up(md) {
        left: auto;
        right: 0;
        transform: translate(30%, -34%);
      }
    }
  }

  &__icon {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__heading {
    margin-bottom: 3rem;

    &+.o-two-columns-section__img-wrapper {
      margin-bottom: 2rem;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 1.25;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.4;
    }

    ul li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__list {
    padding: 0;
    margin: 3rem 0 0;
    list-style-type: none;

    &-item {
      position: relative;
      padding-left: 2rem;
      font-size: 16px;
      line-height: 1.25;
      color: $grey-400;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 1.4;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          top: 9px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__badges {
    border-top: 1px solid #dcdce5;

    @include media-breakpoint-up(md) {
      border-top: none;
    }

    &-items {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 1.25rem -0.75rem;

      @include media-breakpoint-up(md) {
        margin: 2.25rem -0.75rem;
        justify-content: flex-start;
      }
    }

    &-item {
      margin: 0.75rem;

      img {
        max-width: 100px;
        height: auto;
      }
    }
  }

  &__quotations {
    margin-top: 2rem;
  }

  &__buttons {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
    }
  }

  &__button {
    width: 100%;
    margin-bottom: 1rem;

    &.a-arrow-button {
      width: initial;
    }
  }
}
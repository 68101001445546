.o-announcement-box {    
    &__content-wrapper {
        position: relative;
        max-width: 664px;
        margin: 0 auto;
        background-color: #fff;
        padding: 4rem 3rem 5rem;
        border: 1px solid #dcdce5;
        border-radius: 12px;
        box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);
        
        @include media-breakpoint-up(md) {
            padding: 10rem 5rem;
        }
    }
    
    &__heading {
        max-width: 220px;
        margin: 0 auto;
        
        @include media-breakpoint-up(md) {
            max-width: unset;
        }
        
        & > * {
            font-family: $font-family-geomanist;
            color: $blue-400;
            font-size: 24px;
            font-weight: $font-weight-medium;
            text-align: center;
            margin-bottom: 0;
            
            @include media-breakpoint-up(md) {
                font-size: 40px;
            }
        }
    }
    
    &__desc {
        margin-top: 3rem;
        
        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }
        
        & > * {
            font-family: $font-family-geomanist;
            color: $grey-400;
            font-size: 14px;
            line-height: 1.44;
            font-weight: $font-weight-normal;
            text-align: center;
            margin-bottom: 0;
            
            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }
    
    &__blob {
        position: absolute;
        z-index: -1;
        
        &--1 {
            left: 0;
            bottom: 0;
            transform: translate(-50%, 50%) translate(12.5px, -25px);
            
            svg {
                @include media-breakpoint-down(sm) {       
                    width: 192px;
                    height: auto;
                }
            }
        }
        
        &--2 {
            top: 0;
            right: 0;
            transform: translate(50%, -50%) translate(-12.5px, 25px);
        }
    }
}
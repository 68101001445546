.o-implementation-stage-timeline-section {
  &__container {
    @include media-breakpoint-up(xl) {
      max-width: 1381px;
    }
  }

  &__section-heading {
    color: $white;
    font-size: 26px;
    line-height: 34px;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    margin-bottom: 3rem;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 6rem;
      text-align: center;
    }
  }

  &__row {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__col {
    width: 100%;

    &--1 {
      @include media-breakpoint-up(lg) {
        max-width: 68.2670%;
        padding-right: 3rem;
        border-right: 3px solid $orange-400;
        margin-right: 1.75rem;
      }
    }

    &--2 {
      margin-top: 3rem;
      padding-top: 1.5rem;
      border-top: 2px solid $orange-400;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        max-width: 30.5326%;
      }
    }
  }

  &__image {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__text-above-testimonials {
    color: $orange-400 !important;
    font-size: 14px;
    line-height: 22px;
    font-family: $font-family-roboto;
    font-weight: $font-weight-book;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 28px;
      padding: 0 3rem;
    }
  }

  .swiper {
    &-container {
      margin-top: 1.75rem;
    }

    &-wrapper {}

    &-slide {
      background-color: #31435f;
      padding: 2rem 2rem 4.5rem;
      border-radius: 1rem;

      @include media-breakpoint-up(lg) {
        padding: 2rem 3rem 4.5rem;
      }

      &__text {
        color: $white;
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-style: italic;
        font-size: 14px;
        line-height: 22px;
        margin: 0;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      &__signature {
        color: $white;
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    &-pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2rem;
      display: flex;
      justify-content: center;
      z-index: 1;

      &-bullet {
        display: block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #dcdce5;
        margin: 0 0.75rem;

        &-active {
          background-color: #919aaf;
        }
      }
    }
  }
}
.o-cta-form {
  &__container {}

  &__form {
    border: 1px solid $blue-400;
    border-radius: 12px;
  }

  &__content-wrapper {
    padding: 4rem 3rem;

    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: 81.81818181818183%;
      margin: 0 auto;
      padding: 6rem 0;
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    text-align: center;
    font-size: 24px;
    color: $blue-400;
    font-weight: $font-weight-book;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 34px;
      margin-bottom: 3rem;
    }
  }

  &__description {
    text-align: center;
    margin-bottom: 5rem;

    &>* {
      font-size: 16px;
      color: #4b4b4b;
      line-height: 1.25;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 1.44;
      }
    }
  }

  &__group {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      max-width: 630px;
      margin: 0 auto;
    }
  }

  &__inputs {
    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: 412px;
    }
  }

  &__input {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    .choices {
      &__inner {
        background-color: #fff;
        border-color: #dcdce5;
      }

      &__list {
        &--dropdown {
          border-color: #dcdce5 !important;
        }
      }
    }
  }

  &__inputs .a-select__custom-text-option {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__consent {
    margin: 2rem auto 0;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
      max-width: 630px;
    }

    .a-checkbox__label {
      max-width: 560px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  &__bottom {
    display: flex;
  }

  &__button {
    position: relative;

    &.is-loading {
      pointer-events: none;

      .o-cta-form {
        &__loader {
          display: flex;
        }
      }
    }

    &--mobile {
      margin: 4.25rem auto 0;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
  }

  &__loader-ellipsis {}

  &__notification {}

  &__logos {
    width: 100%;
    border-top: 1px solid #dcdce5;
    margin-top: 3rem;
  }
}
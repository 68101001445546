.m-author {
  margin-bottom: 8rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 10rem;
  }

  &__row {
    display: flex;
    margin: 0 0 3rem 0;

    @include media-breakpoint-up(md) {
      margin: 0 0 1.5rem 0;
    }
  }

  &__avatar {
    width: 8rem;
    height: 8rem;
    margin-right: 2rem;
    flex-shrink: 0;

    @include media-breakpoint-up(md) {
      width: 7rem;
      height: 7rem;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__info {
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__name {
    margin: 0 0 2rem 0;
    font-family: $font-family-roboto;
    color: $blue-400;
    font-size: 20px;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(md) {
      margin: 0;
      font-size: 24px;
    }
  }

  &__socials {
    &.m-social-media {
      margin: 2rem 0 0 0;

      .item {
        &:not(:last-child) {
          margin-right: 2rem;
        }

        .item__link {
          padding: 0;
          width: 3rem;
          height: 3rem;

          svg {
            width: 3rem;
            height: 3rem;
          }
        }
      }

      @include media-breakpoint-up(md) {
        margin: 0 0 0 2rem;
      }
    }
  }

  &__desc {
    margin: 3rem 0 8rem;
    color: $grey-400;
    font-size: 16px;
    line-height: 1.25;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(md) {
      margin: 1.5rem 0 10rem;
      font-size: 18px;
      line-height: 1.44;
    }
  }
}
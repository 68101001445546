.o-image {
  figure {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__caption {
    color: $purple-200;
    text-align: center;
    margin-top: 1rem;
    font-size: 14px;
    line-height: 1.43;

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
      font-size: 16px;
      line-height: 1.63;
    }
  }
}
.o-types-of-facilities-section {
  &__container {
    max-width: calc(1318px + 32px);

    @include media-breakpoint-up(lg) {
      max-width: calc(1318px + 48px);
    }
  }

  &__wrapper {
    box-shadow: 0 0 16px 0 rgba(39, 56, 80, 0.1);
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      border-radius: 1rem;
    }
  }

  &__nav {
    @include media-breakpoint-up(md) {
      display: flex;
      border-bottom: 1px solid #dcdce5;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      position: relative;
      width: 100%;
      padding: 1.5rem;
      font-family: $font-family-roboto;
      font-weight: $font-weight-medium;
      color: $grey-400;
      font-size: 2.25rem;
      line-height: 3rem;
      background-color: $white;
      cursor: pointer;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

      @include media-breakpoint-up(md) {
        padding: 3rem;
        line-height: 3.5rem;
        border-top: 1rem solid transparent;
      }

      @include media-breakpoint-down(sm) {
        &:not(:first-child) {
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 1px;
            background-color: #dcdce5;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        svg {
          flex-shrink: 0;
        }
      }

      &:hover {
        color: $orange-400;
        background-color: #fffbf4;
      }

      &.is-active {
        color: $black;
        background-color: $white;
        border-top: 0.5rem solid $orange-400;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: $white;
        }

        @include media-breakpoint-down(sm) {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: calc(100% - 3rem);
            height: 1px;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            background-color: #dcdce5;
          }
        }

        @include media-breakpoint-down(sm) {
          svg {
            transform: rotate(180deg);

            path {
              fill: #FF8C2F;
            }
          }
        }
      }
    }
  }

  &__content {
    display: none;
    width: 100%;
    background-color: $white;
    padding: 1.5rem;

    @include media-breakpoint-up(md) {
      padding: 4rem;
    }

    &.is-active {
      display: block;
    }
  }

  &__row {
    &--1 {
      display: flex;
      flex-direction: column-reverse;
      gap: 1.5rem;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;
      }
    }

    &--2 {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid #dcdce5;

      @include media-breakpoint-up(md) {
        margin-top: 4rem;
        padding-top: 0;
        border-top: none;
      }
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      width: 100%;
    }

    &--1 {
      @include media-breakpoint-up(md) {
        flex: 1 1 352px;
        max-width: 352px;
      }
    }

    &--2 {
      @include media-breakpoint-up(md) {
        flex: 1 1 814px;
        max-width: 814px;
      }
    }
  }

  &__image {
    img {
      width: 100%;
      height: auto;
      border-radius: 2rem;
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 3.25rem;
    line-height: 4.25rem;
    color: $grey-400;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 5rem;
      line-height: 7rem;
    }

    &+.o-types-of-facilities-section__desc {
      @include media-breakpoint-up(md) {
        margin-top: 3rem;
      }
    }
  }

  &__desc {
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    font-size: 1.75rem;
    line-height: 2.75rem;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
      line-height: 3.5rem;
    }
  }

  &__button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
    }

    @include media-breakpoint-up(lg) {
      gap: 2rem 3rem;
    }

    &-item {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      &:not(.a-arrow-button) {
        @include media-breakpoint-down(sm) {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          font-size: 2rem;
          font-weight: $font-weight-book;
        }

        @include media-breakpoint-up(md) {
          padding-left: 5.75rem;
          padding-right: 5.75rem;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
      gap: 3rem;
    }

    &-heading {
      margin: 0;

      @include media-breakpoint-up(md) {
        position: relative;
      }

      &-text {
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-size: 1.75rem;
        line-height: 2.75rem;
        color: $grey-400;
        background-color: $white;

        @include media-breakpoint-up(md) {
          position: relative;
          font-size: 2.25rem;
          line-height: 3.5rem;
          z-index: 1;
          padding-right: 2rem;
        }
      }

      @include media-breakpoint-up(md) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          height: 1px;
          background-color: #dcdce5;
        }
      }
    }

    .swiper {
      &-pagination {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 1;

        &-bullet {
          display: block;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: #dcdce5;
          margin: 0 0.75rem;

          &-active {
            background-color: #919aaf;
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 96px;
    width: auto;
    height: 54px;
    border-radius: 1rem;
    padding: 1.5rem;
    background-color: #f4f9fe;

    @include media-breakpoint-up(md) {
      // width: 189px;
      height: 72px;
      padding: 2rem 1.5rem;
    }

    &--hover {
      .o-types-of-facilities-section__item {
        &-overlay {
          transition: opacity 0.2s linear;
        }

        &-image {
          opacity: 1;
          pointer-events: all;
          transition: opacity 0.2s linear 0.2s;
        }

        &-text {
          transition: opacity 0.2s linear;
        }

        &-link {
          transition: opacity 0.2s linear;
        }
      }

      @include media-breakpoint-up(md) {
        &:hover {
          .o-types-of-facilities-section__item {
            &-overlay {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }

            &-image {
              opacity: 0;
              pointer-events: none;
              transition: opacity 0.2s linear;
            }

            &-text {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }

            &-link {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }
          }
        }
      }
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 1rem;
      background: #f4f9fe;
      opacity: 0;
    }

    &-text {
      color: $grey-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 10px;
      line-height: 14px;
      opacity: 0;
      pointer-events: none;
      text-align: center;

      &+.o-types-of-facilities-section__item-link {
        padding-top: 0.375rem;
        border-top: 1px solid #dcdce5;
        margin-top: 0.5rem;
      }
    }

    &-link {
      color: $blue-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 10px;
      line-height: 16px;
      opacity: 0;
      pointer-events: none;
      text-align: center;
    }
  }
}
.l-not-found {
  padding-top: 10rem;

  @include media-breakpoint-up(md) {
    padding-top: 13rem;
  }

  &__wave {
    &--grey {
      color: #f2f3f5;
    }
  }

  .wave {
    &__container {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 600px;
        height: 600px;
        transform: translate(-35%, -35%);

        @include media-breakpoint-up(md) {
          width: auto;
          height: auto;
          transform: translate(-42%, -46%);
        }
      }
    }
  }

  &__wrapper {
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
    }
  }

  &__heading {
    font-size: 40px;
    font-weight: 600;
    color: $primary;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 120px;
      margin-bottom: 3rem;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    color: #000;

    @include media-breakpoint-up(md) {
      font-size: 34px;
    }
  }

  &__col {
    &--img {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &--content {
      order: 2;
    }
  }

  &__img {
    display: block;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}
.m-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;

  @include media-breakpoint-up(md) {
    margin-top: 8rem;
  }

  &--mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  .page-numbers {
    color: $black;
    font-weight: $font-weight-normal;
    font-size: 18px;
    text-align: center;
    padding: 0.625rem;
    margin: 0 0.75rem;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    transition: color $transition-base, background-color $transition-base;

    &:hover {
      color: $blue-400;
      background-color: #f8fafc;
      transition: color $transition-base, background-color $transition-base;
    }

    &.current {
      color: $white;
      background-color: $blue-400;
    }

    &.dots {
      display: none;
      & + * {
        display: none;
      }
    }
  }
}

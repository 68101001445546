.m-extended-submenu {
  width: max-content;
  position: absolute;
  left: 0;
  bottom: 0;
  padding-top: 2rem;
  transform: translate(-325px, 100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  @media screen and (min-width: 1216px) {
    transform: translate(-512px, 100%);
  }

  &--col-1 {
    transform: translate(-3rem, 100%);

    .m-extended-submenu {
      &__triangle {
        left: 6rem;

        &--inner {
          left: 0;
        }
      }
    }
  }

  &--col-2 {
    transform: translate(-3rem, 100%);

    .m-extended-submenu {
      &__triangle {
        left: 6rem;

        &--inner {
          left: 0;
        }
      }
    }

    &.m-extended-submenu--ad {
      transform: translate(-325px, 100%);

      @media screen and (min-width: 1216px) {
        transform: translate(-480px, 100%);
      }

      .m-extended-submenu {
        &__triangle {
          left: 346px;

          @media screen and (min-width: 1216px) {
            left: 500px;
          }

          &--inner {
            left: 0;
          }
        }
      }
    }
  }

  &--col-3 {
    transform: translate(-3rem, 100%);

    .m-extended-submenu {
      &__triangle {
        left: 6rem;

        &--inner {
          left: 0;
        }
      }
    }
  }

  &--normal {
    .m-extended-submenu {
      &__item {
        &-link {
          &:hover {
            .m-extended-submenu__item-link {
              &-icon {
                svg {
                  path {
                    fill: $blue-400;
                    transition: fill 0.2s ease-in-out;
                  }
                }
              }
            }
          }

          &-icon {
            svg {
              path {
                fill: #919aaf;
                transition: fill 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  &--integrations {
    transform: translate(-28rem, 100%);

    .m-extended-submenu {
      &__triangle {
        left: 32rem;

        &--inner {
          left: 0;
        }
      }

      &__item {
        &-link {
          &:hover {
            .m-extended-submenu__item-link {
              &-icon {

                img,
                svg {
                  filter: contrast(1);
                  transition: filter 0s ease-in-out;
                }
              }
            }
          }

          &-icon {
            width: 5rem;

            img,
            svg {
              filter: contrast(0);
              transition: filter 0s ease-in-out;
            }
          }
        }
      }

      &__column {
        width: calc(220px + 3rem) !important;
      }
    }
  }

  &--platform {
    left: 50%;
    transform: translate(-50%, 100%);

    .m-extended-submenu {
      &__item {
        &-link {
          &:hover {
            .m-extended-submenu__item-link {
              &-icon {
                svg {
                  path {
                    fill: $blue-400;
                    transition: fill 0.2s ease-in-out;
                  }
                }
              }
            }
          }

          &-icon {
            svg {
              path {
                fill: #919aaf;
                transition: fill 0.2s ease-in-out;
              }
            }
          }
        }
      }

      &__triangle {
        left: 50%;
        transform: translateX(-50%) translateY(-100%) translateY(1px);

        &--inner {
          left: 0;
          transform: translateX(-50%) translateY(2px);
        }
      }

      &__column {
        width: calc(250px + 3rem) !important;
      }
    }
  }

  &--knowledge-base {
    .m-extended-submenu {
      &__item {
        &-link {
          &:hover {
            .m-extended-submenu__item-link {
              &-icon {
                svg {
                  path {
                    fill: $blue-400;
                    transition: fill 0.2s ease-in-out;
                  }
                }
              }
            }
          }

          &-icon {
            svg {
              path {
                fill: #919aaf;
                transition: fill 0.2s ease-in-out;
              }
            }
          }
        }
      }

      &__column {
        width: calc(229px + 3rem) !important;
      }
    }
  }

  &--benefits {
    max-width: 1152px;
    transform: translate(-326px, 100%);

    @media screen and (min-width: 1216px) {
      transform: translate(-364px, 100%);
    }

    .m-extended-submenu {
      &__triangle {
        left: 350px;

        @media screen and (min-width: 1216px) {
          left: 390px;
        }

        &--inner {
          left: 0;
        }
      }

      &__column {
        width: calc(33.3333% + 3rem);

        &:last-child:not(.m-extended-submenu__column--ad) {
          width: calc(33.3333%);
        }
      }

      &__item {
        &-link {
          flex-wrap: wrap;
          justify-content: center;

          &:hover {
            .m-extended-submenu__item-link {
              &-icon {
                svg {
                  path {
                    fill: $blue-400;
                    transition: fill 0.2s ease-in-out;
                  }
                }
              }
            }
          }

          &-image {
            margin-bottom: 3rem;
          }

          &-icon {
            svg {
              path {
                fill: #919aaf;
                transition: fill 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  &__triangle {
    position: absolute;
    left: 346px;
    top: 2rem;
    transform: translateY(-100%) translateY(1px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent #dcdce5 transparent;

    @media screen and (min-width: 1216px) {
      left: 532px;
    }

    &--inner {
      left: 0;
      top: 0;
      transform: translateX(-50%) translateY(2px);
      border-width: 0 9px 11px 9px;
      border-color: transparent transparent #fff transparent;
    }
  }

  &__wrapper {
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #dcdce5;
    padding: 3rem;
    border-radius: 6px;
  }

  &__row {
    &--1 {
      display: flex;
    }

    &--2 {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      padding-top: 3rem;
      border-top: 1px solid #dcdce5;
    }
  }

  &__column {
    padding: 0;
    list-style: none;
    width: calc(294px + 3rem);
    padding-right: 3rem;
    margin-right: 3rem;
    border-right: 1px solid #dcdce5;

    @media screen and (min-width: 1216px) {
      width: calc(382px + 3rem);
    }

    &:last-child:not(.m-extended-submenu__column--ad) {
      width: 294px;
      padding-right: 0;
      margin-right: 0;
      border-right: none;
    }

    &--ad {
      width: 224px;
      padding-right: 0;
      margin-right: 0;
      border-right: none;
      align-self: center;

      @media screen and (min-width: 1216px) {
        width: 244px;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &-link {
      display: flex;
      align-items: center;

      &:hover {
        .m-extended-submenu__item-link {
          &-text {
            color: $blue-400;
            transition: color 0.2s ease-in-out;
          }
        }
      }

      &-image {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        width: 3rem;
        margin-right: 1rem;
        flex-shrink: 0;

        svg,
        img {
          width: 100%;
          height: auto;
        }
      }

      &-text {
        color: #000;
        font-family: $font-family-geomanist;
        font-size: 18px;
        line-height: 24px;
        transition: color 0.2s ease-in-out;
      }
    }

    &-description {
      margin-top: 2rem;
    }

    &-read-more {
      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid #dcdce5;

        &:hover {
          .m-extended-submenu__item-read-more-link {
            &-text {
              color: $blue-300;
            }

            &-icon {
              svg {
                transform: translateX(0.25rem);

                path {
                  fill: $blue-300;
                }
              }
            }
          }
        }

        &-text {
          font-family: $font-family-geomanist;
          font-weight: $font-weight-normal;
          color: $blue-400;
          font-size: 18px;
          line-height: 24px;
          transition: fill 0.2s ease-in-out;
        }

        &-icon {
          display: flex;
          margin-left: 1rem;

          svg {
            transition: transform 0.2s ease-in-out;

            path {
              transition: fill 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }

  &__ad {
    &-image {
      display: block;
      margin-bottom: 1rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-family: $font-family-geomanist;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: $grey-400;
      margin: 0;
    }

    &-link {
      width: 100%;
      padding: 1rem 1.5rem;
      margin-top: 1rem;
      line-height: 16px;
    }
  }

  &__read-more {
    display: flex;
    align-items: center;

    &:hover {
      .m-extended-submenu__read-more {
        &-text {
          color: $blue-300;
        }

        &-icon {
          svg {
            transform: translateX(0.25rem);

            path {
              fill: $blue-300;
            }
          }
        }
      }
    }

    &-text {
      font-family: $font-family-geomanist;
      font-weight: $font-weight-normal;
      color: $blue-400;
      font-size: 18px;
      line-height: 24px;
      transition: fill 0.2s ease-in-out;
    }

    &-icon {
      display: flex;
      margin-left: 1rem;

      svg {
        transition: transform 0.2s ease-in-out;

        path {
          transition: fill 0.2s ease-in-out;
        }
      }
    }
  }
}
.a-featured-image {
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    max-width: 760px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
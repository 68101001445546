.o-statistics-section-v2 {
  background-color: $blue-500;

  &__container {
    max-width: calc(1320px + 32px);

    @include media-breakpoint-up(lg) {
      max-width: calc(1320px + 48px);
    }
  }

  &__group {
    &--1 {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 4rem;
      }

      .o-statistics-section-v2__image {
        img {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &+.o-statistics-section-v2__group--2 {
        margin-top: 6rem;

        @include media-breakpoint-up(lg) {
          margin-top: 10rem;
        }
      }
    }

    &--2 {
      .o-statistics-section-v2__image {
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__row {
    &--1 {}

    &--2 {
      margin-top: 3rem;

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 4rem;
        gap: 4rem;
      }
    }
  }

  &__col {
    width: 100%;

    &--1 {
      @include media-breakpoint-up(lg) {
        flex: 1 1 681px;
        max-width: 681px;
      }
    }

    &--2 {
      @include media-breakpoint-up(lg) {
        flex: 1 1 535px;
        max-width: 535px;
      }
    }

    &--3 {
      @include media-breakpoint-up(lg) {
        flex: 1 1 535px;
        max-width: 535px;
      }
    }

    &--4 {
      @include media-breakpoint-up(lg) {
        flex: 1 1 648px;
        max-width: 648px;
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    color: $white;
    font-size: 3.25rem;
    line-height: 4.25rem;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: 5rem;
      line-height: 7rem;
    }

    &+.o-statistics-section-v2__list {
      margin-top: 3rem;

      @include media-breakpoint-up(lg) {
        margin-top: 4rem;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 0;
    padding: 0;
    margin: 0 -1rem;
    list-style: none;

    @include media-breakpoint-up(lg) {
      gap: 7.5rem 0;
      margin: 0 -1.5rem;
      max-width: calc(648px + 3rem);
    }

    &-item {
      width: 100%;
      max-width: calc(50% - 2rem);
      margin: 0 1rem;

      @include media-breakpoint-up(lg) {
        margin: 0 1.5rem;
        max-width: calc(33.3333% - 3rem);
      }

      &-value {
        font-family: $font-family-geomanist;
        font-weight: $font-weight-medium;
        color: $orange-400;
        font-size: 4.25rem;
        line-height: 5rem;

        @include media-breakpoint-up(lg) {
          font-size: 6.5rem;
          line-height: 7.5rem;
        }
      }

      &-desc {
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        color: $white;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid #919aaf;
        font-size: 1.75rem;
        line-height: 2.75rem;

        @include media-breakpoint-up(lg) {
          font-size: 2.25rem;
          line-height: 3.5rem;
        }
      }
    }

    &+.o-statistics-section-v2__button {
      margin-top: 6rem;

      @include media-breakpoint-up(lg) {
        margin-top: 4rem;
      }
    }
  }

  &__button {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include media-breakpoint-up(lg) {
      gap: 2rem 3rem;
    }

    &-item {
      width: 100%;
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: calc(50% - 1.5rem);
      }
    }
  }

  &__image {
    position: relative;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
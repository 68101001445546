.a-input {

  &.has-error {
    .a-input {
      &__label {
        color: $red-error;
      }

      &__input,
      &__select {
        border-color: $red-error;
      }
    }
  }

  &__label {
    display: block;
    font-size: 14px;
    color: $grey-400;
    margin-bottom: 1rem;
    transition: color $transition-base;
  }

  &__input,
  &__select {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #dcdce5;
    background-color: $white;
    padding: 1rem;
    color: $black;
    font-size: 14px;
    transition: border-color $transition-base;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  &__select {
    cursor: pointer;
    appearance: none;
    background-image: url('../../../../../assets/private/img/arrow-dropdown.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1rem);
    background-position-y: 14px;
    background-size: 10px 6px;
  }
}
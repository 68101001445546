.o-benefits-section {

  &__container {
    overflow: hidden;
    max-width: 1110px;
  }

  &__boxes {
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0 -3.5rem;

      &::after {
        content: '';
        width: calc((100% / 3) - 7rem);
        max-width: 260px;
        margin: 0 3.5rem;
      }
    }
  }

  &__box {
    width: 100%;
    max-width: 260px;
    margin: 0 auto 3rem;
    padding-top: 3rem;

    @include media-breakpoint-up(md) {
      margin: 0 3.5rem 3rem;
      width: calc((100% / 3) - 7rem);
    }
  }

  .box {
    &__icon-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 230px;
      margin-bottom: 3rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 6rem;
      }

      img {
        &.icon-background {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 90%;
          max-height: 100%;
          object-fit: contain;
          transform: translate(-50%, -50%);
          filter: drop-shadow(0 0 18px rgba(46, 64, 127, 0.1));
          height: auto;

          @include media-breakpoint-up(md) {
            width: 100%;
          }
        }

        &.icon {
          position: relative;
          display: block;
          max-width: 100px;
          max-height: 100px;
          z-index: 1;
          height: auto;
        }
      }
    }

    &__heading {
      margin-bottom: 2rem;
      max-width: 250px;
    }

    &__audio-button {
      margin-top: 3rem;
    }

    &__description {
      max-width: 250px;
      font-size: 16px;
      line-height: 1.25;
      color: $grey-400;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    &__button {
      margin-top: 2rem;
    }
  }
}
.o-table-of-contents {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    position: relative;
  }

  &::before {
    @include media-breakpoint-up(lg) {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% - 0.25rem); // 100% - scrollbar width
      height: 2rem;
      background-color: #fff;
      z-index: 1;
    }
  }

  &::after {
    @include media-breakpoint-up(lg) {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0.125rem;
      width: calc(100% - 2rem);
      height: 0.125rem;
      background-color: #dcdce5;
      z-index: 2;
    }
  }


  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 24px;
    line-height: 32px;
    color: $grey-400;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto;

    @media screen and (min-width: 768px) {
      max-height: 500px;
    }

    @media screen and (min-width: 768px) and (max-height: 750px) {
      max-height: 300px;
    }

    @media screen and (min-width: 768px) and (max-height: 550px) {
      max-height: 200px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 2.25rem;
      padding-bottom: 2.125rem;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dcdce5;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $blue-400;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $blue-300;
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &--h3 {
        margin-left: 2rem;
      }

      &--h4 {
        margin-left: 4rem;
      }

      &--h5 {
        margin-left: 6rem;
      }

      &--h6 {
        margin-left: 8rem;
      }

      a {
        font-weight: $font-weight-normal;
        font-size: 14px;
        line-height: 22px;
        color: $grey-400;
        transition: color $transition-base;

        &:hover {
          color: $blue-400;
          transition: color $transition-base;
        }

        &.is-active {
          font-weight: $font-weight-book;
        }
      }
    }
  }
}
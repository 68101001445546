.m-author-page-link {
    margin: 1.5rem 0 3rem 0;
    
    &__text {
        margin: 0;
        font-size: 16px;
        color: #222222;
        
        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 1.44;
        }
    }
}
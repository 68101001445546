.l-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //padding-top: $header-height;

  &__main {
    flex: 1 1 auto;

    &.is-backdrop {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 99;
      }
    }
  }
}

.o-player-section {
  &__container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &--reverse-desktop {
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }

      .o-player-section__col {
        &--content {
          @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-right: 4rem;
          }
        }
      }
    }

    &--reverse-mobile {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }

      .o-player-section__player-wrapper {
        margin-bottom: 0;
      }

      .o-player-section__col {
        &--content {
          @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 2rem;
          }

          .o-player-section__buttons {
            display: none;

            @include media-breakpoint-up(md) {
              display: flex;
            }
          }
        }

        &--mobile {
          display: block;

          @include media-breakpoint-up(md) {
            display: none;
          }

          .o-player-section__buttons {
            @include media-breakpoint-down(sm) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &--content {
      display: none;

      @include media-breakpoint-up(md) {
        max-width: 452px;
        margin-left: 4rem;
      }

      &.is-active {
        display: block;
      }
    }

    &--player {
      width: 100%;
      max-width: 448px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        max-width: 496px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &--additional {
      display: none;
    }

    &--mobile {
      display: none;
    }
  }

  &__player-wrapper {
    display: none;
    position: relative;
    padding: 0 1rem 1rem;
    border-radius: 1.5rem;
    background-image: linear-gradient(to top, #def 26%, rgba(239, 247, 255, 0) 100%);
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      padding: 0 2rem 2rem;
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 52.8px;
      background-image: linear-gradient(to bottom, #eff7ff, rgba(239, 247, 255, 0));
      z-index: 1;
      border-radius: 1.5rem 1.5rem 0 0;
      pointer-events: none;
    }

    &.is-active {
      display: block;
    }

    &--with-additional-recordings {
      margin-bottom: 0;
    }
  }

  &__change-buttons {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    margin: 1.25rem -0.75rem 4rem;

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      justify-content: center;
      overflow-x: hidden;
      margin: 2.25rem -0.75rem -0.75rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__change-button {
    border: none;
    flex-shrink: 0;
    background-color: transparent;
    border-radius: 1rem;
    padding: 1.25rem;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-book;
    font-size: 14px;
    color: $black;
    margin: 0 0.75rem;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    @include media-breakpoint-up(md) {
      flex-shrink: initial;
      font-size: 16px;
      margin: 0.75rem;
      padding: 1.5rem;
    }

    &:hover {
      color: $blue-400;
      transition: color 0.2s ease-in-out;
    }

    &.is-active {
      color: $blue-400;
      background-color: #eaf0fe;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    }
  }

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
        10014px 0 0 0 $primary;
    }

    16.667% {
      box-shadow: 9984px -10px 0 0 $primary, 9999px 0 0 0 $primary,
        10014px 0 0 0 $primary;
    }

    33.333% {
      box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
        10014px 0 0 0 $primary;
    }

    50% {
      box-shadow: 9984px 0 0 0 $primary, 9999px -10px 0 0 $primary,
        10014px 0 0 0 $primary;
    }

    66.667% {
      box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
        10014px 0 0 0 $primary;
    }

    83.333% {
      box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
        10014px -10px 0 0 $primary;
    }

    100% {
      box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
        10014px 0 0 0 $primary;
    }
  }

  .dot-typing {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $primary;
    color: $primary;
    box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
      10014px 0 0 0 $primary;
    animation: dotTyping 1.5s infinite ease-in-out;

    @include media-breakpoint-up(md) {
      width: 10px;
      height: 10px;
    }
  }

  &__chat {
    overflow: hidden;
    height: 256px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
      height: 300px;
    }
  }

  .chat {
    &__avatar {
      position: absolute;
      right: -48px;
      bottom: -24px;
      padding-top: 0;
      width: 78px;
      height: 76px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__message {
      position: relative;
      padding: 1rem 2rem;
      max-width: 236px;
      margin-bottom: 2rem;
      font-size: 11px;
      line-height: 1.45;
      display: none;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);

      @include media-breakpoint-up(md) {
        padding: 1rem 2rem;
        max-width: 374px;
        font-size: 14px;
        margin-bottom: 6rem;
      }

      &.is-avatar {
        margin-right: 6rem;
        padding-right: 4rem;

        &+.chat__additional-messages--left {
          margin-right: calc(78px + 2rem);
        }
      }

      &.has-additional-messages {
        margin-bottom: 0;
      }

      p {
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        color: $grey-400;
      }

      &:first-child {
        margin-top: 6rem;

        &+.chat__additional-messages {
          margin-bottom: 6rem;

          .chat__additional-message:last-child {
            margin-bottom: 0;
          }
        }
      }

      &--left {
        margin-left: auto;
        border-radius: 1rem 1rem 0 1rem;
      }

      &--right {
        margin-right: auto;
        border-radius: 1rem 1rem 1rem 0;
      }

      &--white {
        border: solid 1px #dcdce5;
        background-color: $white;
        color: $black;
      }

      &--blue {
        border: solid 1px #dcdce5;
        background-color: #d5e1fe;
      }

      &--typing {
        padding: 1.625rem 2.875rem;
        height: 35px;

        @include media-breakpoint-up(md) {
          height: 42px;
          padding: 2rem 3rem;
        }
      }

      &--avatar-typing {
        margin-right: 6rem;
        padding-right: 6rem;
      }
    }

    &__additional-messages {
      transform: translateY(-5px);
      max-width: 235px;
      width: fit-content;

      &--left {
        margin-left: auto;
        margin-right: 2rem;
      }

      &--right {
        margin-left: 2rem;
        margin-right: 0;
      }

      @include media-breakpoint-up(md) {
        max-width: 374px;
      }
    }

    &__additional-message {
      display: none;
      pointer-events: none;
      opacity: 0;
      width: fit-content;
      font-size: 14px;
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      color: $white;
      background-color: #7c9d91;
      margin-bottom: 0.5rem;
      max-width: 200px;


      &:last-child {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 6rem;
        }
      }

      &--right {
        margin-left: 0;
        margin-right: 2rem;
      }

      &--left {
        margin-left: 2rem;
        margin-right: auto;
      }
    }

    .message {
      &__typing {
        position: relative;
        width: 23px;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
          width: 46px;
        }
      }
    }
  }

  &__heading {
    line-height: 32px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 34px;
      line-height: 40px;
      font-weight: $font-weight-book;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }

    ul li {
      &:not(:last-child) {
        margin-bottom: 0.75rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  &__buttons {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }

  &__button {
    font-size: 16px;

    &:not(.a-arrow-button) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
      max-width: 248px;
      margin-right: 2rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__quotations {
    margin: 2rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
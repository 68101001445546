.o-case-study-sum-up-section {
  &__container {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &__col {
    width: 100%;

    &--1 {
      @include media-breakpoint-up(lg) {
        margin-right: 6rem;
        width: 34%;
        max-width: 368px;
      }
    }

    &--2 {
      margin-top: 3rem;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        width: 66%;
        max-width: 736px;
      }
    }
  }

  &__aside {
    background-color: #eff7ff;
    border-radius: 2rem;
    padding: 3rem 2rem;

    @include media-breakpoint-up(lg) {
      position: sticky;
      top: 0;
      max-width: 368px;
      padding: 4rem 3rem;
    }
  }

  &__name {
    color: $blue-500;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 3rem;
    line-height: 4rem;
    margin: 0;
  }

  &__group {
    margin-top: 3rem;

    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }

  &__label {
    color: $grey-400;
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0.5rem;
    }
  }

  &__link {
    display: inline-block;
    color: $blue-400;
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    font-size: 1.75rem;
    line-height: 2.75rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.25rem;
      line-height: 3.5rem;
      margin-top: 0.5rem;
    }
  }

  &__text {
    color: $black;
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    font-size: 1.75rem;
    line-height: 2.75rem;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: 2.25rem;
      line-height: 3.5rem;
    }
  }

  &__list {
    list-style: none;
    margin: 0;

    &-item {
      position: relative;
      color: $black;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 1.75rem;
      line-height: 2.75rem;

      @include media-breakpoint-up(lg) {
        font-size: 2.25rem;
        line-height: 3.5rem;
      }

      &::before {
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $black;
        top: 1.5rem;
        left: -1.75rem;
        transform: none;
      }
    }
  }

  &__images {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 -1rem;

    img {
      width: max-content;
      max-width: 100%;
      height: auto;
      margin: 0.5rem 1rem;
    }
  }

  &__content {
    &>*:last-child {
      margin-bottom: 0;
    }

    .o-image {
      margin-top: 4rem;

      .container {
        padding: 0;
      }
    }

    .o-social-proof-categories-section {
      margin-top: 4rem;

      &__container {
        padding: 0;
      }
    }
  }

  &__text-editor {
    font-size: 18px;
    line-height: 28px;

    & *:not(h1, h2, h3, h4, h5, h6) {
      color: $grey-400;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .wp-caption {
      max-width: 100%;
    }

    ul li {
      &:not(:last-child) {
        margin-bottom: 0.25rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  &__buttons {
    margin: 2rem 0;
  }

  &__button {
    margin: 0.5rem 0;

    &:not(.a-arrow-button) {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }

  &__quotations {
    margin: 2rem 0;
  }
}
.o-testimonial-section {
  &--wave-background {
    position: relative;
    background-color: $blue-400;
    padding: 4rem 0;

    @include media-breakpoint-up(md) {
      padding: 6rem 0;
    }

    .o-testimonial-section {
      &__heading {
        color: $white;
      }
    }
  }

  &--has-slides {
    position: relative;
    padding: 3rem 0 6rem;

    @include media-breakpoint-up(md) {
      padding: 6rem 0 8.5rem;
    }

    .o-testimonial-section {
      &__container {
        max-width: calc(956px + 4rem + 4rem);

        @include media-breakpoint-up(md) {
          max-width: calc(956px + 6rem + 6rem);
        }
      }

      &__content {
        height: 100%;
      }
    }
  }

  &__wave-background {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    svg {
      opacity: 0.2;
      pointer-events: none;
      transform: translate(-1454px, -862px);

      @include media-breakpoint-up(md) {
        transform: translate(200px, -1262px);
      }
    }
  }

  &__container {
    max-width: calc(956px + 4rem);

    @include media-breakpoint-up(md) {
      max-width: calc(956px + 6rem);
    }
  }

  &__heading {
    color: $black;
    text-align: center;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
  }

  .swiper {
    &-container {}

    &-wrapper {}

    &-slide {
      height: auto;
      padding: 0 2rem;

      @include media-breakpoint-up(md) {
        padding: 0 3rem;
      }
    }

    &-button {

      &-prev,
      &-next {
        position: absolute;
        top: 2rem;
        bottom: 2rem;
        width: 2rem;
        height: calc(100% - 4rem);
        opacity: 0.8;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s ease-in-out;

        @include media-breakpoint-up(md) {
          width: 3rem;
        }

        &:hover {
          opacity: 0.85;
          transition: opacity 0.2s ease-in-out;
        }
      }

      &-prev {
        left: 0;
        border-radius: 2rem 0 0 2rem;
      }

      &-next {
        right: 0;
        border-radius: 0 2rem 2rem 0;
      }

      &-disabled {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
      }
    }

    &-pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      @include media-breakpoint-up(md) {
        bottom: 3.5rem;
      }

      &-bullet {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #dcdce5;
        margin: 0 1rem;

        @include media-breakpoint-up(md) {
          width: 2rem;
          height: 2rem;
        }

        &-active {
          background-color: #919aaf;
        }
      }
    }
  }

  &__content {
    padding: 3rem 2rem;
    border-radius: 2rem;
    background-color: $white;

    @include media-breakpoint-up(md) {
      display: flex;
      padding: 3rem;
    }
  }

  &__col {

    &--1 {
      border-bottom: 1px solid #dcdce5;
      padding-bottom: 1.5rem;
      margin-bottom: 1.375rem;

      @include media-breakpoint-up(md) {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding-right: 3rem;
        margin-right: 2.875rem;
        border-right: 1px solid #dcdce5;
      }

      &+.o-testimonial-section__col--2 {
        @include media-breakpoint-up(md) {
          max-width: 656px;
          align-self: center;
        }
      }
    }

    &--2 {
      width: 100%;
    }
  }

  &__logotype {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      max-width: 156px;
      object-fit: contain;
      object-position: left;

      @include media-breakpoint-up(md) {
        max-width: 204px;
        object-position: center;
      }
    }
  }

  &__quote {
    color: $black;
    font-family: $font-family-roboto;
    font-style: italic;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }

    p {
      margin: 0;
    }
  }

  &__footer {
    margin-top: 2.25rem;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 2rem;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    flex: 2;

    &-col {
      width: 100%;

      &--1 {
        margin-right: 2rem;
        max-width: 59px;

        @include media-breakpoint-up(md) {
          max-width: 74px;
        }
      }

      &--2 {}
    }

    &-photo {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 100%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name {
      color: $grey-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 14px;
      line-height: 22px;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-company {
      color: #919aaf;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: $blue-400;
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight-normal;
    transition: color $transition-base;

    &:hover {
      color: $blue-300;
      transition: color $transition-base;

      svg {
        path {
          fill: $blue-300;
          transition: fill $transition-base;
        }
      }
    }

    svg {
      margin-left: 1rem;

      path {
        transition: fill $transition-base;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex: 1;

      @include media-breakpoint-down(sm) {
        margin-top: 2rem;
        padding-top: 1.5rem;
        border-top: 1px solid #dcdce5;
      }

      @include media-breakpoint-up(md) {
        padding-left: 2rem;
        justify-content: flex-end;
      }
    }
  }
}
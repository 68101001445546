.m-case-study-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 2rem;
  background-color: $white;
  box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
  border-radius: 2rem;

  @include media-breakpoint-up(md) {
    max-width: 486px;
    padding: 5rem 3rem;
  }

  &__top-bar {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #dcdce5;
    margin: -0.5rem -0.5rem 2rem -0.5rem;

    &-text {
      font-size: 14px;
      line-height: 20px;
      color: #919aaf;
      margin: 0.5rem;
    }

    &-link {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      padding: 0.25rem 1.5rem;
      color: $grey-400;
      background-color: #eaf0fe;
      border-radius: 2rem;
      margin: 0.5rem;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

      &:hover {
        color: $blue-400;
        background-color: #eaf0fe;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      }
    }
  }

  &__logo {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &__heading {
    margin-bottom: 1rem;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 18px;
    line-height: 24px;
    color: $black;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    font-family: $font-family-roboto;
    color: $grey-400;
    font-size: 14px;
    line-height: 22px;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__mini-logos {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    margin-top: 1.25rem;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      margin-bottom: 3.25rem;
      margin-top: 2.25rem;
    }

    &-text {
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 14px;
      line-height: 20px;
      color: #919aaf;
      margin: 0.75rem;
      margin-right: 0.625rem;
      margin-left: 0;

    }

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 20px;
      margin: -0.75rem;
      max-width: 100%;
    }

    &-logo {
      display: flex;
      flex-shrink: 0;
      position: relative;
      margin: 0.75rem;
      max-width: 100%;

      &:not(:last-child) {
        padding-right: 1.5rem;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 20px;
          background-color: #dcdce5;
        }
      }

      img {
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  &__buttons {
    margin-top: auto;
  }

  &__button {
    width: 100%;
    padding: 1rem 4rem;
    font-size: 18px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
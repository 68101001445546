.o-tag-heading {
  margin-bottom: 5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 8rem;
  }

  &__container {
  }

  &__heading {
    font-size: 20px;
    font-weight: $font-weight-normal;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      font-size: 34px;
    }
  }
}

.o-step-list-section-observer {
  height: 1px;
  position: relative;
  top: -1px;
}

.o-step-list-section {
  --list-item-width: 160px;
  --current-list-item-position: 0px;

  display: none;
  margin-top: -1px;
  background-color: $white;
  border-bottom: 1px solid transparent;
  z-index: 998;
  transition: box-shadow $transition-base, border-color $transition-base;

  @include media-breakpoint-up(xl) {
    display: block;
  }

  &.is-active {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    border-bottom-color: #f2f3f6;
    position: fixed;
    top: $desktop-header-height;
    left: 0;
    width: 100%;
  }

  &__container {
    max-width: 1370px;
  }

  &__list {
    position: relative;
    display: flex;
    margin: 0;
    padding: 3rem 0;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: var(--list-item-width);
      height: 4px;
      border-radius: 8px;
      background-color: $primary;
      transform: translateX(var(--current-list-item-position));
      transition: width $transition-base, transform $transition-base;
    }

    &-item {
      max-width: 200px;
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }

      &.is-active {
        .list-item {
          &__number {
            color: #eaf0fe;
          }
        }
      }
    }

    .list-item {
      &__link {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
      }

      &__text {
        max-width: 176px;
        position: relative;
        z-index: 1;
        font-size: 16px;
        font-weight: $font-weight-book;
        color: $black;
        margin-bottom: 1rem;
        margin-top: 14px;
        margin-left: 20px;
      }

      &__number {
        width: 0;
        font-size: 100px;
        line-height: 0.8;
        font-weight: $font-weight-medium;
        color: #f2f3f5;
        transition: color $transition-base;
      }
    }
  }
}
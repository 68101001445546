.a-audio-button {
  position: relative;
  background: #fff;
  border: 2px solid #dcdce5;
  border-radius: 1.5rem;
  box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);
  transition: border-color 0.2s ease-in-out, outline-color 0.2s ease-in-out;
  padding: 1.375rem 0;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0;
  }

  &:hover {
    border-color: $blue-300;
    transition: border-color 0.2s ease-in-out, outline-color 0.2s ease-in-out;
  }

  &--small {
    padding: 1rem 0;
    border-radius: 0.5rem;
    border-width: 1px;

    .a-audio-button {
      &__wrapper {
        @include media-breakpoint-up(md) {
          margin: 0 5rem;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 24px;
      }

      &__icon {
        margin-right: 1rem;

        &--play {
          svg {
            display: flex;

            width: 16px;
            height: 16px;
          }
        }

        &--pause {
          svg {
            display: flex;

            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin: 0 2rem;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      margin: 0 3rem;
    }
  }

  &__progress-layer {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: $blue-400;
    overflow: hidden;
    pointer-events: none;

    .a-audio-button__text {
      color: #fff;
    }
  }

  &__icon {
    margin-right: 2rem;

    &--play {
      svg {
        width: 18px;
        height: 20px;

        @include media-breakpoint-up(md) {
          width: 21px;
          height: 24px;
        }
      }
    }

    &--pause {
      svg {
        width: 18px;
        height: 20px;

        @include media-breakpoint-up(md) {
          width: 21px;
          height: 24px;
        }
      }
    }

    &--white {
      svg {

        path,
        g {
          fill: #fff;
        }
      }
    }

    &--hidden {
      display: none;
    }
  }

  &__text {
    font-family: $font-family-geomanist;
    color: $blue-400;
    font-size: 20px;
    line-height: 1.2;
    white-space: nowrap;
    font-weight: $font-weight-book;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 1.25;
    }
  }

  audio {
    display: none !important;
  }
}
.m-steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  list-style: none;
  margin: 1.5rem 0 3rem 0;
  padding: 1.5rem 0;
  border-top: 1px solid #dcdce5;
  border-bottom: 1px solid #dcdce5;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    margin: 0.5rem 0 4rem 0;
    padding: 0 0 2rem 0;
    border-top: none;
  }

  &__item {
    display: flex;
    align-items: center;


    &:not(:last-child) {
      @include media-breakpoint-up(md) {
        position: relative;

        &::after {
          content: url('../../../../../assets/private/img/step-arrow-desktop.svg');
          margin-left: 1.5rem;
          height: 3rem;
        }
      }

      .m-steps__item-number {
        position: relative;

        &::after {
          content: url('../../../../../assets/private/img/step-arrow-mobile.svg');
          position: absolute;
          width: 1.5rem;
          height: 2rem;
          top: calc(100% + 0.25rem);
          left: 0.375rem;

          @include media-breakpoint-up(md) {
            display: none;
            height: 1.5rem;
          }
        }
      }
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      background-color: #dcdce5;
      font-size: 1.25rem;
      line-height: normal;
      color: $grey-400;
      text-align: center;
      margin-right: 1.5rem;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        width: 3.5rem;
        height: 3.5rem;
        font-size: 1.75rem;
      }
    }

    &-text {
      font-size: 1.75rem;
      line-height: 2.5rem;
      color: $grey-400;
    }
  }

  &__separator {
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include media-breakpoint-up(md) {
      width: unset;
    }

    &-icon {}

    svg {
      transform: rotate(90deg);
      width: 1.75rem;
      height: auto;

      @include media-breakpoint-up(md) {
        transform: rotate(0);
      }

      path {
        fill: #dcdce5;
      }
    }
  }
}
.m-recommended-article {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__col {
    &--1 {
      flex-shrink: 0;
      width: 100%;
      max-width: 74px;
      margin-right: 2rem;
    }

    &--2 {}
  }

  &__image {
    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 0.5rem;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {
    color: $black;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0.25rem;
  }

  &__category {
    color: #919aaf;
    font-size: 14px;
    line-height: 22px;
  }
}
.o-logo-section-v2 {
  &__container {}

  &__section-heading {
    color: $black;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 56px;
      text-align: left;
    }
  }

  &__groups {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dcdce5;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  &__group {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }

    &:not(:first-child) {
      padding-top: 3rem;

      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }

    &:last-child {
      .o-logo-section-v2__group-items {
        border-bottom: none;
      }
    }

    &.is-open {
      .o-logo-section-v2__group-items {
        max-height: unset;

        &-dropdown {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-heading {
      position: relative;
      color: $grey-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 14px;
      line-height: 22px;
      padding-right: 4rem;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
        margin: 2.5rem 3rem 0 0;
        width: 100%;
        max-width: 200px;
        padding-right: 0;
        flex-shrink: 0;
      }
    }

    &-items {
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fit, 136px);
      gap: 2rem;
      width: 100%;
      padding: 1.5rem 0;
      border-bottom: 1px solid #dcdce5;
      overflow: hidden;
      max-height: 161px;

      @include media-breakpoint-up(md) {
        gap: 1.5rem 3rem;
        grid-template-columns: repeat(auto-fit, 160px);
        padding: 1.5rem 4rem 1.5rem 0;
        max-height: 97px;
      }

      &-dropdown {
        display: none;
        position: absolute;
        right: 0;
        top: 50%;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        background: none;
        border: none;
        padding: 0;
        transform: translateY(-50%);

        @include media-breakpoint-up(md) {
          width: 3rem;
          height: 3rem;
          transform: none;
        }

        svg {
          width: 10px;
          height: auto;

          @include media-breakpoint-up(md) {
            width: 15px;
          }
        }

        &--mobile {
          &.is-visible {
            display: flex;

            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }

        &--desktop {
          &.is-visible {
            display: none;

            @include media-breakpoint-up(md) {
              display: flex;
              top: 60px;
            }
          }
        }
      }
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 136px;
      height: 60px;
      background-color: #f4f9fe;
      border-radius: 1rem;
      padding: 1.5rem;

      @include media-breakpoint-up(md) {
        width: 160px;
        height: 72px;
        padding: 2rem 2.5rem;
      }

      &--hover {
        .o-logo-section-v2__group-item {
          &-image {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.2s linear 0.2s;
          }

          &-text {
            transition: opacity 0.2s linear;
          }

          &-link {
            transition: opacity 0.2s linear;
          }
        }

        &:hover {
          .o-logo-section-v2__group-item {
            &-image {
              opacity: 0;
              pointer-events: none;
              transition: opacity 0.2s linear;
            }

            &-text {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }

            &-link {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }
          }
        }
      }

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-text {
        color: $grey-400;
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-size: 10px;
        line-height: 14px;
        opacity: 0;
        pointer-events: none;
        text-align: center;

        &+.o-logo-section-v2__group-item-link {
          padding-top: 0.375rem;
          border-top: 1px solid #dcdce5;
          margin-top: 0.5rem;
        }
      }

      &-link {
        color: $blue-400;
        font-family: $font-family-roboto;
        font-weight: $font-weight-normal;
        font-size: 10px;
        line-height: 16px;
        opacity: 0;
        pointer-events: none;
        text-align: center;
      }
    }
  }
}
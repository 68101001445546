.o-two-cards-section {
  &__container {
    overflow: hidden;
    max-width: 1004px;
  }

  &__boxes {
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -4rem;
    }
  }

  &__box {
    width: 100%;
    max-width: 438px;
    margin: 0 auto 3rem;
    padding: 3rem;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 0 18px 0 rgba(46, 64, 127, 0.1);
    transition: opacity .3s ease-in-out;

    &.is-fade {
      opacity: 0.7;
    }

    @include media-breakpoint-up(md) {
      padding: 8rem 6rem;
      margin: 0 4rem 3rem;
      width: calc(50% - 8rem);
    }
  }

  .box {

    &__icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-bottom: 3rem;

      @include media-breakpoint-up(md) {
        width: 53px;
        height: 53px;
        margin-bottom: 4rem;
      }
    }

    &__icon {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    &__heading {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }

    &__description {
      font-size: 16px;
      line-height: 1.25;
      color: $grey-400;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    &__button {
      margin-top: 3rem;
    }
  }
}
.m-calendar {
  width: 100%;
  min-height: 282px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__input-calendar {
    &-container {
      width: 100%;
      height: 100%;
    }
  }

  &__calendar {
    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0.5rem;
      }

      &-date-name {
        color: #919aaf;
        font-size: 18px;
        line-height: 24px;
        font-family: $font-family-geomanist;
        font-weight: $font-weight-medium;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    &-prev-month,
    &-next-month {
      border: none;
      background: none;
      color: #919aaf;
      padding: 0;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-prev-month {
      margin-right: 1rem;

      @include media-breakpoint-up(md) {
        margin-right: 2rem;
      }
    }

    &-next-month {
      margin-left: 1rem;

      @include media-breakpoint-up(md) {
        margin-left: 2rem;
      }
    }

    &-table {
      border-collapse: separate;
      width: 100%;
      border-spacing: 0.5rem;

      @include media-breakpoint-up(md) {
        border-spacing: 1rem;
      }

      tr {
        td {
          height: 42px;
          text-align: center;
          color: #919aaf;
          font-size: 18px;
          line-height: 18px;
          font-family: $font-family-geomanist;
          border-radius: 0.5rem;

          @include media-breakpoint-up(md) {
            height: 60px;
            font-size: 32px;
            line-height: 32px;
            border-radius: 1rem;
          }

          &.day {}

          &.weekend {
            background-color: #f2f3f5;
          }

          &.current-day {}

          &.has-event {
            font-weight: $font-weight-medium;
            color: #5384fa;
            cursor: pointer;
          }

          &.is-active {
            background-color: #eaf0fe;
          }

          &.next-month,
          &.prev-month {
            opacity: 0.5 !important;
          }
        }
      }
    }

    &-event {
      &-dots {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: -0.375rem;
        pointer-events: none;
        margin-top: 0.25rem;

        @include media-breakpoint-up(md) {
          margin-top: 0.5rem;
          margin: -0.25rem;
        }
      }

      &-dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #5384fa;
        margin: 0.125rem;

        @include media-breakpoint-up(md) {
          width: 1rem;
          height: 1rem;
          margin: 0.25rem;
        }
      }
    }
  }

}
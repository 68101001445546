.o-about-us-section {
  &__container {
    overflow: hidden;
    max-width: 1110px;
  }

  &__boxes {
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0 -3.5rem;
    }
  }

  &__box {
    width: 100%;
    max-width: 314px;
    margin: 0 auto 3rem;
    padding: 3rem;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);
    border: solid 1px #dcdce5;

    @include media-breakpoint-up(md) {
      padding: 4rem;
      margin: 0 3.5rem 3rem;
      width: calc((100% / 3) - 7rem);
    }
  }

  .box {
    &__img-wrapper {
      position: relative;
      display: block;
      width: 64px;
      height: 64px;
      background-color: #d8d8d8;
      border-radius: 50%;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
        margin-bottom: 3rem;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__social-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      background-image: url('../../../../../assets/private/img/linkedin.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__heading {
      margin-bottom: 1rem;
    }

    &__position {
      font-size: 16px;
      font-weight: $font-weight-normal;
      line-height: 1.25;
      color: $primary;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    &__description {
      margin-top: 2rem;
      font-size: 16px;
      line-height: 1.25;
      color: $grey-400;

      @include media-breakpoint-up(md) {
        margin-top: 3rem;
        font-size: 18px;
        line-height: 1.4;
      }
    }
  }
}
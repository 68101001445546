.o-download-files-section {
  &__container {
    @include media-breakpoint-up(xl) {
      max-width: 1365px;
    }
  }

  &__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    @include media-breakpoint-up(md) {
      gap: 3rem;
    }
  }

  &__item {
    width: 100%;
    max-width: 52.875rem;
    display: flex;
    align-items: flex-start;
    padding: 2rem;
    border-radius: 2rem;
    background-color: $white;
    box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);

    @include media-breakpoint-up(md) {
      padding: 3rem;
    }

    &:hover {
      .o-download-files-section__item {
        &-icon {
          img {
            filter: brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(3089%) hue-rotate(240deg) brightness(80%) contrast(91%);
          }
        }

        &-download {
          &-text {
            color: $blue-300;
          }
        }
      }
    }

    &-col {
      width: 100%;

      &--1 {
        max-width: 5rem;
        margin-right: 1rem;

        @include media-breakpoint-up(md) {
          max-width: 7rem;
          margin-right: 3rem;
        }
      }

      &--2 {}
    }

    &-icon {
      img {
        width: 100%;
        height: auto;
      }
    }

    &-heading {
      font-family: $font-family-geomanist;
      font-weight: $font-weight-book;
      color: $black;
      font-size: 18px;
      line-height: 24px;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-desc {
      color: $grey-400;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      margin-top: 0.5rem;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-download {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid #dcdce5;

      &-text {
        color: $blue-400;
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}
.o-case-study-items-section {
  &__container {}

  &__items {
    display: grid;
    gap: 2rem;
    list-style: none;
    padding: 0;
    max-width: 996px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }
  }
}
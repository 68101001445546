.o-calendar-section {
  &__container {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      max-width: 1042px;
      padding-left: 8px;
      padding-right: 16px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  &__col {
    flex-basis: 50%;

    &--1 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      max-width: 456px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        padding-right: 0;
        padding-left: 0;
        padding-top: 2rem;
      }
    }

    &--2 {
      margin-top: 0.5rem;

      @include media-breakpoint-up(md) {
        max-width: 498px;
        margin-left: 1rem;
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 3rem;
      }
    }
  }
}
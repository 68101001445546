.o-request-demo-form {
  &__heading {
    text-align: center;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 5rem;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__hidden-fields {
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-base;
  }

  &__input {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      width: calc(50% - 1.5rem);
      margin-bottom: 3rem;
    }

    &--full-width {
      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    &.js-intl-tel {
      .iti {
        width: 100%;
      }
    }

    .choices {
      &__inner {
        background-color: #fff;
        border-color: #dcdce5;
      }

      &__list {
        &--dropdown {
          border-color: #dcdce5 !important;
        }
      }
    }
  }

  &__inputs .a-select__custom-text-option {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__consent {
    font-size: 16px;
    line-height: 1.25;
    color: $grey-400;
  }

  &__bottom {
    position: relative;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    position: relative;

    &.is-loading {
      pointer-events: none;

      .o-request-demo-form {
        &__loader {
          display: flex;
        }
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
  }
}
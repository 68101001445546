.o-social-proof-categories-section {
  &.is-short-navigation {
    .o-social-proof-categories-section {
      &__navigation {
        &-item {
          &:last-child {
            &::after {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 1355px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;

    @include media-breakpoint-up(md) {
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: stretch;
    }
  }

  &__list-items {
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__list-item {
    &.is-active {
      position: relative;
      background-color: #f6fcf6;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 1rem;
        width: 100%;
        background-color: #48be4d;
      }

      .o-social-proof-categories-section {
        &__navigation {
          &-item {
            padding-top: 2rem;
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dcdce5;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__navigation {
    width: 100%;
    position: relative;
    padding: 0;
    list-style: none;
    margin: 0;

    @include media-breakpoint-up(md) {
      width: 24.483550%;
      max-width: 320px;
      border-radius: 8px 0 0 8px;
      background-color: $white;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 8px;
        background-color: #48be4d;
        border-radius: 8px 0 0 8px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      z-index: 1;
      padding: 1.5rem;

      @include media-breakpoint-up(md) {
        display: block;
        padding: 3rem 1.75rem 3rem 3rem;

        &:first-child {
          border-radius: 1rem 0 0 0;

          &::after {
            top: 0;
            height: calc(100% + 1px);
          }

          &::after,
          &::before {
            border-radius: 1rem 0 0 0;
          }
        }

        &:last-child {
          border-radius: 0 0 0 1rem;

          &::after {
            height: calc(100% + 1px);
          }

          &::after,
          &::before {
            border-radius: 0 0 0 1rem;
          }
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          pointer-events: none;
        }

        &::before {
          background-color: #f6fcf6;
          right: 0;
          left: 0;
          transform: translateX(100%);
          transition: transform 0.3s ease-in-out;
        }

        &::after {
          width: 8px;
          top: -1px;
          left: calc(100% - 8px);
          height: calc(100% + 2px);
          background-color: #48be4d;
          transition: left 0.3s ease-in-out;
          z-index: 1;
        }

        &:not(:first-child) {
          border-top: 1px solid #dcdce5;
        }

        &:hover {
          &::before {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
          }

          .o-social-proof-categories-section__navigation-item-text {
            color: #48be4d;
            transition: color 0.3s ease-in-out;
          }
        }
      }


      &.is-active {
        @include media-breakpoint-up(md) {
          &::before {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
          }

          &::after {
            left: 0;
            transition: left 0.3s ease-in-out;
          }
        }

        .o-social-proof-categories-section__navigation-item-text {
          color: $black;
          transition: color 0.3s ease-in-out;
        }
      }

      &-text {
        position: relative;
        z-index: 1;
        font-size: 18px;
        color: $grey-400;
        line-height: 24px;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
        margin-right: 1rem;
        pointer-events: none;

        @include media-breakpoint-up(md) {
          margin-right: 0;
          line-height: 28px;
        }
      }

      &-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 75.516449%;
    max-width: 987px;
    // padding: 4rem 3rem 4rem 2rem;
    background-color: #f6fcf6;
    border-radius: 0 1rem 1rem 0;
    z-index: 1;
  }

  &__item {
    display: none;

    &.is-active {
      display: block;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  &__content {
    width: 100%;
    align-self: center;
  }

  .o-testimonial-section {
    &--has-slides {
      padding: 4.5rem 0 5.5rem;

      @include media-breakpoint-up(md) {
        padding: 1rem 0 6rem;
        position: initial;
      }
    }

    &__content {
      background-color: transparent;
      padding: 0 3rem;
    }

    .swiper {
      &-slide {
        @include media-breakpoint-up(md) {
          padding: 0 5rem;
        }
      }

      &-button {

        &-prev,
        &-next {
          width: 3rem;
          background-color: #f6fcf6;
          z-index: 10;
          opacity: 1;
          height: 100%;
          top: 0;
          bottom: 0;
          transition: none;

          @include media-breakpoint-up(md) {
            background-color: #f4f9fe;
            width: 5rem;
          }

          svg {
            width: 1.125rem;
            height: auto;

            path {
              fill: #48be4d;
            }
          }

          &:hover {
            opacity: 1;
            transition: none;
          }
        }

        &-prev {
          border-radius: 0;

          @include media-breakpoint-up(md) {
            border-right: 1px solid #dcdce5;
          }
        }

        &-next {
          @include media-breakpoint-up(md) {
            border-left: 1px solid #dcdce5;
          }
        }

        &-disabled {
          opacity: 0;
          transition: none;
        }
      }

      &-pagination {
        bottom: 1.5rem;

        &-bullet {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
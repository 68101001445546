.o-short-quotes-section {
  position: relative;
  max-height: 350px;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    max-height: 564px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 6rem;
    pointer-events: none;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &--white,
  &--white-blue-gradient,
  &--white-grey-gradient {
    &::before {
      background-image: linear-gradient(to bottom, #fff, rgba(239, 247, 255, 0));
    }

    &::after {
      background-image: linear-gradient(to top, #fff, rgba(239, 247, 255, 0));
    }
  }

  &--grey,
  &--grey-white-gradient,
  &--grey-blue-gradient {
    &::before {
      background-image: linear-gradient(to bottom, #f6f9fc, rgba(239, 247, 255, 0));
    }

    &::after {
      background-image: linear-gradient(to top, #f6f9fc, rgba(239, 247, 255, 0));
    }
  }

  &--blue,
  &--blue-white-gradient,
  &--blue-grey-gradient {
    &::before {
      background-image: linear-gradient(to bottom, #eff7ff, rgba(239, 247, 255, 0));
    }

    &::after {
      background-image: linear-gradient(to top, #eff7ff, rgba(239, 247, 255, 0));
    }
  }

  &--animation-disabled {
    max-height: unset;

    &::before,
    &::after {
      display: none;
    }

    .o-short-quotes-section {
      &__blob {
        @include media-breakpoint-up(sm) {
          top: 0;
          transform: translateX(-50%);
        }
      }

      &__items {
        &-col {
          margin-top: 1rem;

          &--1 {
            margin-top: 3.625rem;
          }

          &--2 {
            margin-top: 10rem;
          }
        }
      }
    }
  }

  &__container {
    position: relative;
  }

  &__blob {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
    }
  }

  &__items {
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    @include media-breakpoint-up(md) {
      max-width: 972px;
    }

    &-col {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 14.5rem 0 0 0;
      transition: none;

      &--1 {
        @include media-breakpoint-up(sm) {
          margin-top: 17.125rem;
        }
      }

      &--2 {
        @include media-breakpoint-up(sm) {
          margin-top: 23.5rem;
        }
      }
    }
  }

  &__item {
    position: relative;
    padding: 1.5rem;
    border-radius: 2rem 2rem 0 2rem;
    margin: 2.5rem 2rem;
    background-color: $white;

    @include media-breakpoint-up(md) {
      margin: 2.1875rem 2rem;
      padding: 3rem;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 40px;
      max-height: 33px;
      position: absolute;
      left: -2rem;
      top: -2.625rem;

      @include media-breakpoint-up(md) {
        top: -2rem;
      }
    }

    &-quote {
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 14px;
      line-height: 22px;
      color: $grey-400;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }

      strong {
        font-weight: $font-weight-book;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.o-table-of-contents-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 1000;
  padding: 2rem 0;
  overflow-y: scroll;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition-base;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transition: opacity $transition-base;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    margin-right: 1rem;
    background: transparent;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-400;
  }

  &__close {
    display: flex;
    flex-shrink: 0;
    margin-left: auto;
    border: none;
    background: transparent;
    padding: 0;
  }

  .o-table-of-contents {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
}
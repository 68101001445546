.o-video-section {
  &__container {
    text-align: center;
    max-width: 824px;

    @include media-breakpoint-up(md) {
      max-width: 848px;
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    margin-bottom: 1rem;
    font-size: 26px;
    line-height: 32px;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(md) {
      font-size: 52px;
      line-height: 60px;
    }
  }

  &__desc {
    &>* {
      color: $grey-400;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  &__blob {
    display: none;
    position: absolute;
    z-index: -1;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &--1 {
      top: -30px;
      right: -133.7px;
    }

    &--2 {
      top: 82px;
      left: -100px;
    }

    &--3 {
      bottom: 37px;
      right: -60.9px;
    }
  }

  &__video-slider {
    .swiper {
      &-pagination {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        margin-top: 2rem;

        &-bullet {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: #dcdce5;
          margin: 0 0.75rem;

          @include media-breakpoint-up(sm) {
            width: 2rem;
            height: 2rem;
          }

          &-active {
            background-color: #919aaf;
          }
        }
      }
    }
  }

  &__video {
    width: 100%;
    border-radius: 1rem;

    @include media-breakpoint-up(md) {
      border-radius: 2rem;
    }

    &-container {
      position: relative;
      margin-top: 2rem;

      @include media-breakpoint-up(md) {
        margin-top: 6.75rem;
      }
    }

    &-wrapper {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 1rem;
        opacity: 1;
        pointer-events: none;
        transition: opacity $transition-base;

        @include media-breakpoint-up(md) {
          border-radius: 2rem;
        }
      }

      &.is-playing {
        &::after {
          opacity: 0;
          transition: opacity $transition-base;
        }

        .o-video-section__video-play-button {
          opacity: 0;
          pointer-events: none;
          transition: opacity $transition-base;
        }
      }

      &--iframe {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;

        &::after {
          display: none;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;

        @include media-breakpoint-up(md) {
          border-radius: 2rem;
        }
      }
    }

    &-play-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 1;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      padding: 0;
      border: none;
      background-color: $blue-400;
      transition: opacity $transition-base;

      @include media-breakpoint-up(md) {
        width: 6.75rem;
        height: 6.75rem;
      }

      svg {
        width: 4rem;
        height: 4rem;

        @include media-breakpoint-up(md) {
          width: 6.75rem;
          height: 6.75rem;
        }
      }
    }
  }
}
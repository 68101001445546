.a-button {
  font-family: $font-family-geomanist;
  display: inline-block;
  padding: 1.25rem 1.5rem;
  font-size: 14px;
  font-weight: $font-weight-book;
  line-height: 1.2;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all $transition-base;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &__icon {
    width: 24px;
    margin-right: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 32px;
    }
  }

  &--primary {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;

    &:hover {
      background-color: $blue-300;
      border-color: $blue-300;
      color: $white;
    }
  }

  &--secondary {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      color: $blue-300;
      border-color: $blue-300;
    }
  }

  &--white {
    border: 1px solid $white;
    background-color: $white;
    color: $black;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

    &:hover {
      color: $primary;
    }
  }

  &--small {
    @include media-breakpoint-up(md) {
      padding: 7px 12px;
    }
  }

  &--medium {
    @include media-breakpoint-up(md) {
      font-size: 16px;
      padding: 1.25rem 2rem;
    }
  }

  &--large {
    padding: 1.25rem 2rem;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      padding: 2rem 3rem;
    }
  }

  &--phone {
    display: flex;
    align-items: center;
    color: $blue-400;
    font-size: 20px;
    line-height: 1.2;
    padding: 1.5rem 2rem;
    border: solid 0.8px #dcdce5;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);

    &:hover {
      background-color: $blue-300;
      color: #fff;
      transform: none;
      box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);
    }

    @include media-breakpoint-up(md) {
      padding: 2rem;
      font-size: 24px;
      line-height: 1;
    }
  }
}

.a-arrow-button {
  font-family: $font-family-geomanist;
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: $font-weight-book;
  color: $primary;
  padding-right: 3rem;
  cursor: pointer;
  transition: all $transition-base;

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 0;
    width: 15px;
    height: 14px;
    background-color: $primary;
    mask-size: contain;
    mask-repeat: no-repeat;
    transition: background-color $transition-base, transform $transition-base;
    mask-image: url("../../../../../assets/private/img/arrow.svg");
  }

  &:hover {
    color: $blue-300;

    &::after {
      background-color: $blue-300;
      transform: translateX(8px);
    }
  }
}
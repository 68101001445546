.m-multi-layer-graphics {
  position: relative;

  &--integrations-pl {
    width: 100%;

    .m-multi-layer-graphics {
      &__wrapper {
        display: block;
        position: relative;
        width: 100%;
      }

      &__svg {
        position: absolute;

        svg {
          width: 100%;
          height: auto;

          #integrations-pl-path-1 {
            animation-name: integrations-pl-path-1;
            animation-duration: 5s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-2 {
            animation-name: integrations-pl-path-2;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-3 {
            animation-name: integrations-pl-path-3;
            animation-duration: 5s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-4 {
            animation-name: integrations-pl-path-4;
            animation-duration: 4s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-5 {
            animation-name: integrations-pl-path-5;
            animation-duration: 4s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-6 {
            animation-name: integrations-pl-path-6;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-7 {
            animation-name: integrations-pl-path-7;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-pl-path-8 {
            animation-name: integrations-pl-path-8;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }

      &__logo {
        position: absolute;

        &:hover {
          img {
            transform: translateY(-0.75rem);
            transition: transform 0.3s ease-in-out;
          }
        }

        img {
          width: 100%;
          height: auto;
          transition: transform 0.3s ease-in-out;
        }
      }
    }

    &-5 {
      .m-multi-layer-graphics {
        &__wrapper {
          padding-top: 87.88546255%;
        }

        &__logo {
          width: 24.0088%;

          @include media-breakpoint-up(md) {
            left: 6.6079%;
          }

          &--1 {
            top: 0.7518%;
          }

          &--2 {
            top: 25.8145%;
          }

          &--3 {
            top: 44.8621%;
          }

          &--4 {
            top: 66.4160%;
          }

          &--5 {
            top: 81.9548%;
          }
        }

        &__svg {
          width: 57.7092%;
          right: 13.21585%;
          top: 4.5%;

          @include media-breakpoint-up(md) {
            right: 6.6079%;
          }
        }
      }
    }

    &-6 {
      @include media-breakpoint-up(md) {
        padding: 4rem;
      }

      .m-multi-layer-graphics {
        &__wrapper {
          padding-top: 116.919191%;
        }

        &__logo {
          width: 27.5252525%;

          @include media-breakpoint-up(md) {
            left: 0;
          }

          &--1 {
            top: 0;
          }

          &--2 {
            top: 21.5982721%;
          }

          &--3 {
            top: 37.14902807%;
          }

          &--4 {
            top: 51.6198704%;
          }

          &--5 {
            top: 70.6263498%;
          }

          &--6 {
            top: 84.2332613%;
          }
        }

        &__svg {
          width: 66.1616161%;
          right: 0;
          top: 3.455723%;
        }
      }
    }
  }

  &--integrations-en {
    width: 100%;

    .m-multi-layer-graphics {
      &__wrapper {
        display: block;
        position: relative;
        width: 100%;
      }

      &__svg {
        position: absolute;

        svg {
          width: 100%;
          height: auto;

          #integrations-en-path-1 {
            animation-name: integrations-en-path-1;
            animation-duration: 5s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-2 {
            animation-name: integrations-en-path-2;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-3 {
            animation-name: integrations-en-path-3;
            animation-duration: 5s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-4 {
            animation-name: integrations-en-path-4;
            animation-duration: 4s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-5 {
            animation-name: integrations-en-path-5;
            animation-duration: 4s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-6 {
            animation-name: integrations-en-path-6;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-7 {
            animation-name: integrations-en-path-7;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }

          #integrations-en-path-8 {
            animation-name: integrations-en-path-8;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }

      &__logo {
        position: absolute;

        &:hover {
          img {
            transform: translateY(-0.75rem);
            transition: transform 0.3s ease-in-out;
          }
        }

        img {
          width: 100%;
          height: auto;
          transition: transform 0.3s ease-in-out;
        }
      }
    }

    &-6 {
      @include media-breakpoint-up(md) {
        padding: 4rem;
      }

      .m-multi-layer-graphics {
        &__wrapper {
          padding-top: 103%;
        }

        &__logo {
          width: 24%;

          @include media-breakpoint-up(md) {
            left: 5%;
          }

          &--1 {
            top: -1.5%;
          }

          &--2 {
            top: 15%;
          }

          &--3 {
            top: 39.6%;
          }

          &--4 {
            top: 57%;
          }

          &--5 {
            top: 75.5%;
          }

          &--6 {
            top: 92.5%;
          }
        }

        &__svg {
          width: 66.1616161%;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

@keyframes integrations-pl-path-1 {
  0% {
    transform: translateX(-14.5%);
  }

  100% {
    transform: translateX(40.5%);
  }
}

@keyframes integrations-pl-path-2 {
  0% {
    transform: translateX(-43.5%);
  }

  100% {
    transform: translateX(11.25%);
  }
}

@keyframes integrations-pl-path-3 {
  0% {
    transform: translateX(4.25%);
  }

  100% {
    transform: translateX(-40.25%);
  }
}

@keyframes integrations-pl-path-4 {
  0% {
    transform: translateX(-14.75%);
  }

  100% {
    transform: translateX(36.75%);
  }
}

@keyframes integrations-pl-path-5 {
  0% {
    transform: translateX(33.25%);
  }

  100% {
    transform: translateX(-32%);
  }
}

@keyframes integrations-pl-path-6 {
  0% {
    transform: translateX(-61%);
  }

  100% {
    transform: translateX(11%);
  }
}

@keyframes integrations-pl-path-7 {
  0% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(31.5%);
  }
}

@keyframes integrations-pl-path-8 {
  0% {
    transform: translateX(-34.25%);
  }

  100% {
    transform: translateX(10.25%);
  }
}

@keyframes integrations-en-path-1 {
  0% {
    transform: translateX(-14.5%);
  }

  100% {
    transform: translateX(40.5%);
  }
}

@keyframes integrations-en-path-2 {
  0% {
    transform: translateX(-43.5%);
  }

  100% {
    transform: translateX(11.25%);
  }
}

@keyframes integrations-en-path-3 {
  0% {
    transform: translateX(4.25%);
  }

  100% {
    transform: translateX(-40.25%);
  }
}

@keyframes integrations-en-path-4 {
  0% {
    transform: translateX(-14.75%);
  }

  100% {
    transform: translateX(36.75%);
  }
}

@keyframes integrations-en-path-5 {
  0% {
    transform: translateX(33.25%);
  }

  100% {
    transform: translateX(-32%);
  }
}

@keyframes integrations-en-path-6 {
  0% {
    transform: translateX(-61%);
  }

  100% {
    transform: translateX(11%);
  }
}

@keyframes integrations-en-path-7 {
  0% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(31.5%);
  }
}

@keyframes integrations-en-path-8 {
  0% {
    transform: translateX(-34.25%);
  }

  100% {
    transform: translateX(10.25%);
  }
}
.o-footer {
  padding: 4rem 0 0;
  background-color: $grey-100;

  @include media-breakpoint-up(md) {
    padding: 10rem 0 0;
  }

  &__groups {
    font-family: $font-family-geomanist;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.75rem;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
      margin-bottom: 0;
    }
  }

  &__group {
    &--1 {}

    &--2 {
      background-color: $white;
      padding-bottom: 3rem;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding-top: 1rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
      padding-top: 0;
    }
  }

  &__bottom-group {
    @include media-breakpoint-up(sm) {
      width: 180px;
    }

    &--badges {
      order: 2;
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
      padding-top: 2rem;
      padding-bottom: 0.25rem;
      border-top: 1px solid #dcdce5;

      @include media-breakpoint-up(sm) {
        order: 1;
        width: 85%;
        margin-top: 3rem;
        padding-top: 0;
        padding-bottom: 0;
        border-top: none;
      }
    }

    &--social-media {
      order: 1;
      padding-bottom: 1rem;

      @include media-breakpoint-up(sm) {
        order: 2;
        display: flex;
        justify-content: flex-end;
        width: 15%;
        padding-bottom: 0;
      }
    }

    &--copyrights {
      order: 3;
      padding-top: 2rem;
      border-top: 1px solid #dcdce5;
      width: 100%;

      @include media-breakpoint-up(sm) {
        padding-top: 3rem;
      }
    }
  }

  &__social-media {
    .item {
      &:not(:last-child) {
        @include media-breakpoint-up(md) {
          margin-right: 1rem;
        }
      }

      .item__link {
        @include media-breakpoint-up(md) {
          width: 4.5rem;
          height: 4.5rem;
        }

        svg {
          @include media-breakpoint-up(md) {
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }

  &__badge {
    margin-bottom: 2.75rem;
    margin-top: 1rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 3rem;
      margin-top: 0;
    }

    &:not(:last-child) {
      margin-right: 2.75rem;

      @include media-breakpoint-up(sm) {
        margin-right: 5rem;
      }
    }

    &--color-transition {
      svg {
        path {
          transition: fill $transition-base;
        }
      }

      &:hover {
        svg {
          path {
            fill: $blue-400;
            transition: fill $transition-base;
          }
        }
      }
    }

    &--opacity-transition {
      opacity: 1;
      transition: opacity $transition-base;

      &:hover {
        opacity: 0.6;
        transition: opacity $transition-base;
      }
    }

    &--mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    svg,
    img {
      width: auto;
      height: 4rem;
    }
  }

  &__badge-image {
    height: 5rem;

    @include media-breakpoint-up(md) {
      height: 4rem;
    }
  }
}
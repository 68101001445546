.l-section {
  position: relative;

  $spaces: (
    1: 3rem,
    2: 6rem,
    3: 8rem,
    4: 10rem,
    5: 15rem,
  );

&--mt {
  &-mobile {

    @each $i,
    $values in $spaces {
      &-#{$i} {
        @include media-breakpoint-down(sm) {
          margin-top: map-get($spaces, $i);
        }
      }
    }
  }

  @each $i,
  $values in $spaces {
    &-#{$i} {
      @include media-breakpoint-up(md) {
        margin-top: map-get($spaces, $i);
      }
    }
  }
}

&--mb {
  &-mobile {

    @each $i,
    $values in $spaces {
      &-#{$i} {
        @include media-breakpoint-down(sm) {
          margin-bottom: map-get($spaces, $i);
        }
      }
    }
  }

  @each $i,
  $values in $spaces {
    &-#{$i} {
      @include media-breakpoint-up(md) {
        margin-bottom: map-get($spaces, $i);
      }
    }
  }
}

&--pt {
  &-mobile {

    @each $i,
    $values in $spaces {
      &-#{$i} {
        @include media-breakpoint-down(sm) {
          padding-top: map-get($spaces, $i);
        }
      }
    }
  }

  @each $i,
  $values in $spaces {
    &-#{$i} {
      @include media-breakpoint-up(md) {
        padding-top: map-get($spaces, $i);
      }
    }
  }
}

&--pb {
  &-mobile {

    @each $i,
    $values in $spaces {
      &-#{$i} {
        @include media-breakpoint-down(sm) {
          padding-bottom: map-get($spaces, $i);
        }
      }
    }
  }

  @each $i,
  $values in $spaces {
    &-#{$i} {
      @include media-breakpoint-up(md) {
        padding-bottom: map-get($spaces, $i);
      }
    }
  }
}

&--background {
  &-white {
    background-color: $white;
  }

  &-grey {
    background-color: $grey-100;

    .o-player-section {
      &__change-button {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
          transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        }

        &.is-active {
          background-color: #fff;
        }
      }
    }
  }

  &-blue {
    background-color: $blue-100;

    .o-player-section {
      &__change-button {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
          transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        }

        &.is-active {
          background-color: #f4f9fe;
        }
      }
    }
  }

  &-blue-white-gradient {
    background-image: linear-gradient(to bottom, $blue-100 85%, $white);

    .o-player-section {
      &__change-button {
        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }

        &.is-active {
          background-color: #fff;
        }
      }
    }
  }

  &-blue-grey-gradient {
    background-image: linear-gradient(to bottom, $blue-100 85%, $grey-100);

    .o-player-section {
      &__change-button {
        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }

        &.is-active {
          background-color: #fff;
        }
      }
    }
  }

  &-grey-blue-gradient {
    background-image: linear-gradient(to bottom, $grey-100 85%, $blue-100);

    .o-player-section {
      &__change-button {
        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }

        &.is-active {
          background-color: #fff;
        }
      }
    }
  }

  &-grey-white-gradient {
    background-image: linear-gradient(to bottom, $grey-100 85%, $white);

    .o-player-section {
      &__change-button {
        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }

        &.is-active {
          background-color: #fff;
        }
      }
    }
  }

  &-white-blue-gradient {
    background-image: linear-gradient(to bottom, $white 85%, $blue-100);
  }

  &-white-grey-gradient {
    background-image: linear-gradient(to bottom, $white 85%, $grey-100);
  }
}

&--theme {
  &-dark {
    background-color: $blue-500;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }

    p {
      color: #8da3c3;
    }

    ul,
    ol {
      li {
        color: #87a4c6;

        &::before {
          background-color: $orange-400;
        }
      }
    }

    ul {
      li {
        &::marker {
          color: $orange-400;
        }
      }
    }

    a:not(.a-button) {
      color: $orange-400;
      transition: color $transition-base;

      &:hover {
        color: $orange-300;
        transition: color $transition-base;
      }
    }

    .a-button {
      &--primary {
        background-color: $orange-400;
        border: 1px solid $orange-400;
        color: $white;

        &:hover {
          background-color: $orange-300;
          border-color: $orange-300;
          color: $white;
        }
      }

      &--secondary {
        background-color: transparent;
        border: 1px solid $orange-400;
        color: $orange-400;

        &:hover {
          color: $orange-300;
          border-color: $orange-300;
        }
      }
    }

    .a-arrow-button {
      color: $orange-400;

      &::after {
        background-color: $orange-400;
      }

      &:hover {
        color: $orange-300;

        &::after {
          background-color: $orange-300;
        }
      }
    }

    .o-lifecycle-section {
      .item {
        &__number {
          color: #8da3c3;
        }

        &__description {
          p {
            color: #f2f3f5;
          }
        }
      }
    }
  }
}
}
.a-separator {
  width: 106%;
  transform: translateX(-3%);
  height: 26px;
  mask-image: url("../../../../../assets/private/img/separator.svg");
  mask-repeat: repeat-x;
  mask-size: contain;
  -webkit-mask-position-y: bottom;
  margin-bottom: -1px;

  &--blue {
    background-color: $blue-100;
  }

  &--grey {
    background-color: $grey-100;
  }

  &--dark {
    background-color: $blue-500;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    transform: none;
    height: 64px;
  }
}

@import "functions";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/grid-framework";

@import "variables/variables";

@import "reboot";

@import "grid";
@import "typography";

@import "~swiper/swiper";
@import "intl-tel-input/build/css/intlTelInput.css";

@import "vendor/cookiebot";
@import "vendor/choices";

@import "../../../includes/views/components/components";
@import "../../../includes/views/layouts/layouts";

html,
body {
  overflow-x: hidden;

  &::after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(39, 56, 80, 0.6);
    z-index: 998;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  &.is-overlay {
    &::after {
      opacity: 1;
      pointer-events: all;
      transition: opacity 0.3s ease-in-out;
    }
  }
}
.m-player {
  --seek-before-width: 0%;
  --buffered-width: 0%;

  display: flex;
  align-items: center;
  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  &__button {
    position: relative;
    width: 5rem;
    height: 5rem;
    padding: 0;
    flex-shrink: 0;
    border: none;
    background: transparent;
    margin-right: 2rem;

    &-play {
      pointer-events: none;

      svg {
        pointer-events: none;
      }
    }

    &-play,
    &-pause {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-pause {
      display: none;
    }

    &.is-playing {
      .m-player__button {
        &-play {
          display: none;
        }

        &-pause {
          display: block;
        }
      }
    }
  }

  &__seek-slider-wrapper {
    width: 100%;
    border-radius: 4px;
    padding: 11px 16px;
    border: solid 1px #dcdce5;
    background-color: $white;
  }

  &__seek-slider {
    display: block;
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    padding: 0;
    height: 16px;
    outline: none;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      border-radius: 2px;
      background: linear-gradient(to right,
          rgba($purple-200, 0.35) var(--buffered-width),
          #dcdce5 var(--buffered-width));
    }

    &::before {
      position: absolute;
      content: "";
      top: 6px;
      left: 0;
      width: var(--seek-before-width);
      border-radius: 2px 0 0 2px;
      height: 4px;
      background-color: $primary;
      cursor: pointer;
    }

    &::-moz-range-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: linear-gradient(to right,
          rgba(0, 125, 181, 0.6) var(--buffered-width),
          rgba(0, 125, 181, 0.2) var(--buffered-width));
    }

    &::-moz-range-progress {
      background-color: #007db5;
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &::-ms-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: transparent;
      border: solid transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      background-color: #007db5;
    }

    &::-ms-fill-upper {
      background: linear-gradient(to right,
          rgba(0, 125, 181, 0.6) var(--buffered-width),
          rgba(0, 125, 181, 0.2) var(--buffered-width));
    }

    &::-webkit-slider-thumb {
      position: relative;
      -webkit-appearance: none;
      box-sizing: content-box;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: $primary;
      cursor: pointer;
      margin: -6px 0 0 0;
      transition: all $transition-base;

      @include media-breakpoint-up(md) {
        margin: -10px 0 0 0;
        width: 3rem;
        height: 3rem;
      }
    }

    &::-moz-range-thumb {
      box-sizing: content-box;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: $primary;
      cursor: pointer;
      transition: all $transition-base;

      @include media-breakpoint-up(md) {
        width: 3rem;
        height: 3rem;
      }
    }

    &::-ms-thumb {
      box-sizing: content-box;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: $primary;
      cursor: pointer;
      transition: all $transition-base;

      @include media-breakpoint-up(md) {
        width: 3rem;
        height: 3rem;
      }
    }

    &:active {
      &::-ms-thumb {
        transform: scale(1.2);
        background: $blue-300;
      }

      &::-moz-range-thumb {
        transform: scale(1.2);
        background: $blue-300;
      }

      &::-webkit-slider-thumb {
        transform: scale(1.2);
        background: $blue-300;
      }
    }
  }
}
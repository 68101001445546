.o-logo-section-v3 {
  &__container {
    @include media-breakpoint-up(xl) {
      max-width: 1248px;
    }
  }

  &__section-heading {
    color: $black;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
    }

    &+.o-logo-section-v3__items {
      padding-top: 1rem;

      @include media-breakpoint-up(md) {
        padding-top: 3.5rem;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, 96px);
    gap: 0;
    width: 100%;
    justify-content: center;
    justify-items: center;

    @include media-breakpoint-up(md) {
      gap: 3rem;
      // grid-template-columns: repeat(auto-fit, 180px);
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 42px;
    border-radius: 1rem;
    padding: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 180px;
      height: 78px;
      padding: 2rem 1.5rem;
    }

    &--hover {
      .o-logo-section-v3__item {
        &-overlay {
          transition: opacity 0.2s linear;
        }

        &-image {
          opacity: 1;
          pointer-events: all;
          transition: opacity 0.2s linear 0.2s;
        }

        &-text {
          transition: opacity 0.2s linear;
        }

        &-link {
          transition: opacity 0.2s linear;
        }
      }

      @include media-breakpoint-up(md) {
        &:hover {
          .o-logo-section-v3__item {
            &-overlay {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }

            &-image {
              opacity: 0;
              pointer-events: none;
              transition: opacity 0.2s linear;
            }

            &-text {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }

            &-link {
              opacity: 1;
              transition: opacity 0.2s linear 0.2s;
            }
          }
        }
      }
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 1rem;
      background: rgba(255, 255, 255, 0.9);
      opacity: 0;
    }

    &-text {
      color: $grey-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 10px;
      line-height: 14px;
      opacity: 0;
      pointer-events: none;
      text-align: center;

      &+.o-logo-section-v3__item-link {
        padding-top: 0.375rem;
        border-top: 1px solid #dcdce5;
        margin-top: 0.5rem;
      }
    }

    &-link {
      color: $blue-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-size: 10px;
      line-height: 16px;
      opacity: 0;
      pointer-events: none;
      text-align: center;
    }
  }
}
.o-download-section {
  position: relative;
  color: $white;
  background-color: $purple-200;

  &__waves {
    position: absolute;
    right: -80px;
    top: 76px;
    height: 100%;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      right: -86px;
      top: -124px;
    }
  }

  &__container {
    padding: 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 10rem;
      margin: 0 auto;
      max-width: 1360px;
    }
  }

  &__content-wrapper {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      width: 50%;
      padding: 1.25rem 0;
    }

    &--1 {
      @include media-breakpoint-up(md) {
        margin-right: 3rem;
      }
    }

    &--2 {
      @include media-breakpoint-up(md) {
        padding-left: 3rem;
        border-left: 1px solid rgba(220, 220, 229, 0.5);
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    margin-bottom: 3rem;
    font-size: 24px;
    line-height: 32px;

    @include media-breakpoint-up(md) {
      font-size: 38px;
      line-height: normal;
      font-weight: $font-weight-book;
      margin-bottom: 0;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3rem;
    margin-top: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
    }
  }

  &__logos {
    width: 100%;
    border-top: 1px solid rgba(220, 220, 229, 0.5);
    margin-top: 3rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
      max-width: 496px;
    }

    &--mobile {
      display: block;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__input,
  &__inputs .a-select__custom-text-option {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      max-width: 565px;
    }

    &--half {
      @include media-breakpoint-up(md) {
        max-width: calc(50% - 1.5rem);
      }
    }

    &.has-error {
      .a-input {
        &__label {
          color: #ff8787;
        }

        &__input {
          border-color: #ff8787;
        }
      }

      .choices__inner {
        border-color: #ff8787;
      }

      .a-textarea {
        &__label {
          color: #ff8787;
        }

        &__textarea {
          border-color: #ff8787;
        }
      }
    }

    .a-input {
      &__label {
        color: $white;
      }

      &__input {}
    }

    .a-textarea {
      &__label {
        color: $white;
      }

      &__input {}
    }

    .choices {
      &__inner {
        background-color: #fff;
        border-color: #dcdce5;
      }

      &__list {
        &--dropdown {
          border-color: #dcdce5 !important;
        }
      }
    }
  }

  &__consent {
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
  }

  &__checkbox {
    display: flex;
    align-items: flex-start;

    &.has-error {
      .a-checkbox__icon {
        border-color: #ff8787;
      }
    }

    .a-checkbox {
      &__icon {
        background-color: $white;
        margin-right: 1.5rem;
        margin-top: 1rem;
      }

      &__label {
        color: $white;
        margin-bottom: 0;
        line-height: 18px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
          line-height: 22px;
        }

        a {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  &__button {
    position: relative;
    width: 100%;
    font-weight: $font-weight-medium;
    line-height: 10px;

    @include media-breakpoint-up(md) {
      max-width: 260px;
      font-size: 14px;
    }

    &.is-loading {
      pointer-events: none;

      .o-download-section {
        &__loader {
          display: flex;
          background-color: $white;
        }

        &__loader-ellipsis {
          &>* {
            background-color: $purple-200;
          }
        }
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
  }

  &__notification {
    @include media-breakpoint-up(md) {
      position: relative;
      left: unset;
      bottom: unset;
      transform: none;
      max-width: unset;
      width: 100%;
      margin-top: 2rem;
    }
  }
}
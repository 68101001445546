.tax-blog-tag {
  overflow: visible;

  .l-wrapper {
    &__main {
      padding-top: 17.5rem;

      @include media-breakpoint-up(md) {
        padding-top: 23rem;
      }

      .container {
        max-width: 936px;
      }
    }
  }
}

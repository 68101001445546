.o-title-section {
  display: flex;

  &__heading {
    margin: 0 auto 3rem;
    text-align: center;
  }

  &__description {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 1.25;
    color: $grey-400;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.4;
    }
  }
}
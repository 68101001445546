.o-player-section-v2 {
  &.is-short-navigation {
    .o-player-section-v2 {
      &__navigation {
        &-item {
          &:last-child {
            &::after {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 1355px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;

    @include media-breakpoint-up(md) {
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: stretch;
    }
  }

  &__list-items {
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__list-item {
    &.is-active {
      position: relative;
      background-color: #eff7ff;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 1rem;
        width: 100%;
        background-color: $blue-400;
      }

      .o-player-section-v2 {
        &__navigation {
          &-item {
            padding-top: 2rem;
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dcdce5;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__navigation {
    width: 100%;
    position: relative;
    padding: 0;
    list-style: none;
    margin: 0;

    @include media-breakpoint-up(md) {
      width: 24.483550%;
      max-width: 320px;
      border-radius: 8px 0 0 8px;
      background-color: $white;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 8px;
        background-color: $blue-400;
        border-radius: 8px 0 0 8px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      z-index: 1;
      padding: 1.5rem;

      @include media-breakpoint-up(md) {
        display: block;
        padding: 3rem 1.75rem 3rem 3rem;

        &:first-child {
          border-radius: 1rem 0 0 0;

          &::after {
            top: 0;
            height: calc(100% + 1px);
          }

          &::after,
          &::before {
            border-radius: 1rem 0 0 0;
          }
        }

        &:last-child {
          border-radius: 0 0 0 1rem;

          &::after {
            height: calc(100% + 1px);
          }

          &::after,
          &::before {
            border-radius: 0 0 0 1rem;
          }
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          pointer-events: none;
        }

        &::before {
          background-color: $blue-100;
          right: 0;
          left: 0;
          transform: translateX(100%);
          transition: transform 0.3s ease-in-out;
        }

        &::after {
          width: 8px;
          top: -1px;
          left: calc(100% - 8px);
          height: calc(100% + 2px);
          background-color: $blue-400;
          transition: left 0.3s ease-in-out;
          z-index: 1;
        }

        &:not(:first-child) {
          border-top: 1px solid #dcdce5;
        }

        &:hover {
          &::before {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
          }

          .o-player-section-v2__navigation-item-text {
            color: $blue-400;
            transition: color 0.3s ease-in-out;
          }
        }
      }


      &.is-active {
        @include media-breakpoint-up(md) {
          &::before {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
          }

          &::after {
            left: 0;
            transition: left 0.3s ease-in-out;
          }
        }

        .o-player-section-v2__navigation-item-text {
          color: $black;
          transition: color 0.3s ease-in-out;
        }
      }

      &-text {
        position: relative;
        z-index: 1;
        font-size: 18px;
        color: $grey-400;
        line-height: 24px;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
        margin-right: 1rem;
        pointer-events: none;

        @include media-breakpoint-up(md) {
          margin-right: 0;
          line-height: 28px;
        }
      }

      &-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 75.516449%;
    max-width: 987px;
    padding: 4rem 3rem 4rem 2rem;
    background-color: $blue-100;
    border-radius: 0 1rem 1rem 0;
    z-index: 1;
  }

  &__item {
    display: none;
    padding: 0 1.5rem 1.5rem;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    &.is-active {
      display: block;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  &__player-wrapper {
    position: relative;
    background-image: linear-gradient(to top, $white 26%, rgba(239, 247, 255, 0) 100%);
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 56.494192%;
      max-width: 535px;
      margin-right: 3rem;
      margin-top: 0;
      padding: 2rem;
      align-self: center;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 52.8px;
      background-image: linear-gradient(to bottom, #eff7ff, rgba(239, 247, 255, 0));
      z-index: 1;
      pointer-events: none;
    }
  }

  &__chat {
    overflow: hidden;
    height: 256px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
      height: 300px;
    }

    .dot-typing {
      position: relative;
      left: -9999px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $primary;
      color: $primary;
      box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
        10014px 0 0 0 $primary;
      animation: dotTyping 1.5s infinite ease-in-out;

      @include media-breakpoint-up(md) {
        width: 10px;
        height: 10px;
      }
    }

    .chat {
      &__avatar {
        position: absolute;
        right: -48px;
        bottom: -24px;
        padding-top: 0;
        width: 71.8px;
        height: 76px;

        @include media-breakpoint-up(md) {
          width: 78px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__message {
        position: relative;
        padding: 1rem 2rem;
        max-width: 216.3px;
        margin-bottom: 2rem;
        font-size: 11px;
        line-height: 1.45;
        display: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);

        @include media-breakpoint-up(md) {
          padding: 1rem 2rem;
          max-width: 374px;
          font-size: 14px;
          margin-bottom: 6rem;
        }

        &.is-avatar {
          margin-right: 6rem;
          padding-right: 4rem;

          &+.chat__additional-messages--left {
            margin-right: calc(78px + 2rem);
          }
        }

        &.has-additional-messages {
          margin-bottom: 0;
        }

        p {
          margin: 0;
          font-size: 14px;
          line-height: 18px;
          color: $grey-400;
        }

        &:first-child {
          margin-top: 6rem;

          &+.chat__additional-messages {
            margin-bottom: 6rem;

            .chat__additional-message:last-child {
              margin-bottom: 0;
            }
          }
        }

        &--left {
          margin-left: auto;
          border-radius: 1rem 1rem 0 1rem;
        }

        &--right {
          margin-right: auto;
          border-radius: 1rem 1rem 1rem 0;
        }

        &--white {
          border: solid 1px #dcdce5;
          background-color: $white;
          color: $black;
        }

        &--blue {
          border: solid 1px #dcdce5;
          background-color: #d5e1fe;
        }

        &--typing {
          padding: 1.625rem 2.875rem;
          height: 35px;

          @include media-breakpoint-up(md) {
            height: 42px;
            padding: 2rem 3rem;
          }
        }

        &--avatar-typing {
          margin-right: 6rem;
          padding-right: 6rem;
        }
      }

      &__additional-messages {
        transform: translateY(-5px);
        max-width: 235px;
        width: fit-content;

        &--left {
          margin-left: auto;
          margin-right: 2rem;
        }

        &--right {
          margin-left: 2rem;
          margin-right: 0;
        }

        @include media-breakpoint-up(md) {
          max-width: 374px;
        }
      }

      &__additional-message {
        display: none;
        pointer-events: none;
        opacity: 0;
        width: fit-content;
        font-size: 14px;
        padding: 0.5rem 2rem;
        border-radius: 0.5rem;
        color: $white;
        background-color: #7c9d91;
        margin-bottom: 0.5rem;
        max-width: 200px;


        &:last-child {
          margin-bottom: 2rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 6rem;
          }
        }

        &--right {
          margin-left: 0;
          margin-right: 2rem;
        }

        &--left {
          margin-left: 2rem;
          margin-right: auto;
        }
      }

      .message {
        &__typing {
          position: relative;
          width: 23px;
          display: flex;
          justify-content: center;

          @include media-breakpoint-up(md) {
            width: 46px;
          }
        }
      }
    }
  }

  &__player {
    margin-top: 2rem;
  }


  &__content {
    width: 40.971488%;
    max-width: 388px;
    align-self: center;
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-book;
    color: $black;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 1rem;
  }

  &__phone-cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dcdce5;

    @include media-breakpoint-up(md) {
      padding-top: 0;
      border-top: none;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #dcdce5;
    }

    &-text {
      font-size: 14px;
      line-height: 22px;
      color: $black;
      margin-right: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-link {
      display: flex;
      align-items: center;

      &:hover {
        .o-player-section-v2__phone-cta-number {
          color: $blue-300;
          transition: color 0.3s ease-in-out;
        }
      }
    }

    &-icon {
      flex-shrink: 0;

      svg {
        width: 24px;
        height: auto;

        @include media-breakpoint-up(md) {
          width: 32px;
        }
      }
    }

    &-number {
      font-family: $font-family-roboto;
      font-weight: $font-weight-book;
      color: $blue-400;
      font-size: 14px;
      line-height: 22px;
      transition: color 0.3s ease-in-out;

      @include media-breakpoint-up(md) {
        font-family: $font-family-geomanist;
        font-weight: $font-weight-medium;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &__description {
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 22px;
    color: $grey-400;
    margin-top: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &+.o-player-section-v2__quote {
      margin-top: 1rem;
    }
  }

  &__quote {
    margin-top: 1.5rem;

    &-text {
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      font-style: italic;
      color: $grey-400;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &-signature {
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      color: #919aaf;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      margin-top: 1rem;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  &__buttons {
    margin-top: 0.5rem;

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }
  }

  &__button {
    margin-top: 1rem;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }

    &:not(.a-arrow-button) {
      width: 100%;
    }
  }
}
.m-social-media {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0 -1rem;

  @include media-breakpoint-up(md) {
    margin: 0 -0.75rem;
  }

  &__item {}

  .item {
    &__link {
      display: block;
      width: 5rem;
      height: 5rem;
      padding: 1rem;

      @include media-breakpoint-up(md) {
        width: 3.75rem;
        height: 3.75rem;
        padding: .75rem;
      }

      svg {
        display: block;
        width: 3rem;
        height: 3rem;

        @include media-breakpoint-up(md) {
          width: 2.25rem;
          height: 2.25rem;
        }
      }

      &--twitter {
        svg {
          path {
            fill: #1B9DF0;
          }
        }
      }

      &--facebook {
        svg {
          path {
            fill: #1877F2;
          }
        }
      }

      &--linkedin {
        svg {
          path {
            fill: #0A66C2;
          }
        }
      }
    }
  }
}
.o-video-two-columns-section {
  &__container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--video {
      &-mobile {
        &-top {}

        &-bottom {
          @include media-breakpoint-down(md) {
            flex-direction: column-reverse;

            .o-video-two-columns-section__col {
              &--content {
                margin-bottom: 0;
                margin-top: 2rem;
              }
            }

            .o-video-two-columns-section__buttons {
              display: flex;

              &--mobile {
                display: none;
              }
            }
          }
        }
      }

      &-desktop {
        &-left {
          @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
          }
        }

        &-right {}
      }
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      width: calc(50% - 1.5rem);
    }

    &--content {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &--video {
      display: flex;
      flex-direction: column;
    }
  }

  &__video-slider {
    .swiper {
      &-pagination {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        margin-top: 2rem;

        &-bullet {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: #dcdce5;
          margin: 0 0.75rem;

          @include media-breakpoint-up(sm) {
            width: 2rem;
            height: 2rem;
          }

          &-active {
            background-color: #919aaf;
          }
        }
      }
    }
  }

  &__video {
    width: 100%;
    border-radius: 1rem;

    @include media-breakpoint-up(md) {
      border-radius: 2rem;
    }

    &-wrapper {
      display: flex;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 1rem;
        opacity: 1;
        pointer-events: none;
        transition: opacity $transition-base;

        @include media-breakpoint-up(md) {
          border-radius: 2rem;
        }
      }

      &.is-playing {
        &::after {
          opacity: 0;
          transition: opacity $transition-base;
        }

        .o-video-two-columns-section__video-play-button {
          opacity: 0;
          pointer-events: none;
          transition: opacity $transition-base;
        }
      }

      &--iframe {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;

        &::after {
          display: none;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;

        @include media-breakpoint-up(md) {
          border-radius: 2rem;
        }
      }
    }

    &-play-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 1;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      padding: 0;
      border: none;
      background-color: $blue-400;
      transition: opacity $transition-base;

      @include media-breakpoint-up(md) {
        width: 6.75rem;
        height: 6.75rem;
      }

      svg {
        width: 4rem;
        height: 4rem;

        @include media-breakpoint-up(md) {
          width: 6.75rem;
          height: 6.75rem;
        }
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 26px;
    line-height: 32px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 56px;
      text-align: left;
    }
  }

  &__content-items {
    @include media-breakpoint-up(md) {
      max-width: 384px;
    }
  }

  &__description {
    color: $grey-400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
      text-align: left;
    }

    ul li {
      &:not(:last-child) {
        margin-bottom: 0.75rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  &__buttons {
    display: none;
    flex-direction: column;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      display: flex;
      margin-top: 3rem;
    }

    &--mobile {
      display: flex;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.a-arrow-button {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      &::after {
        position: relative;
        margin-left: 2rem;
      }
    }
  }
}
.o-recommended-articles {
  background-color: $blue-100;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 3rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 8rem;
  }

  &__inner-container {
    @include media-breakpoint-up(lg) {
      max-width: 760px;
      margin-left: auto;
    }
  }

  &__heading {
    color: $grey-400;
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0;
    }
  }

  &__items {
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 3rem;
      margin-top: 3rem;
    }
  }
}
.a-hamburger {
  background-color: transparent;
  border: none;
  padding: 1rem;
  margin: -1rem;

  &.is-tier-list-active,
  &.js-block-transition {
    .a-hamburger__lines {
      &::before,
      &::after {
        transition: none;
      }
      &-inner {
        transition: none;
      }
    }
  }

  &__container {
    position: relative;
    width: 28px;
    height: 28px;
    background-color: $primary;
    border-radius: 50%;
    display: block;
  }

  &__lines {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 14px;
    height: 10px;
    transform: translate(-50%, -50%);

    &:before,
    &:after {
      content: "";
      transition: all $transition-base;
    }

    &-inner,
    &:before,
    &:after {
      position: absolute;
      right: 0;
      height: 1px;
      border-radius: 2px;
      background-color: $white;
      width: 100%;
    }

    &-inner {
      top: 5px;
      transition: opacity $transition-base;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  &.is-active .a-hamburger {
    &__lines {
      &-inner {
        opacity: 0;
      }

      &::before,
      &::after {
        width: 100%;
      }

      &::before {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &::after {
        bottom: 50%;
        transform: translateY(50%) rotate(45deg);
      }
    }
  }
}

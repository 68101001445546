.o-tags {
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 3.125rem;
    margin-bottom: 4.5rem;
  }

  &__tags {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__tag {
    display: flex;
    margin-bottom: 1.5rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__link {
    padding: 0.5rem 1rem;
    background-color: #d5e1fe;
    border-radius: 8px;
    color: $black;
    font-weight: $font-weight-normal;
    font-size: 14px;
    transition: none;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      padding: 1rem 1.25rem;
    }

    &:hover {
      transition: none;
      color: $black;
    }
  }
}
.o-step-list-section-v2 {
  &__container {
    max-width: 1370px;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin: 0;
    padding: 3rem 0;
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &-item {
      flex: 1 1 auto;
      margin-right: 0;

      &.width-1 {
        width: 100%;
      }

      &.width-2 {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }

      &.width-3 {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 33.3333%;
        }
      }

      &.width-4 {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 25%;
        }
      }

      &.width-5 {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 20%;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.is-active {
        .list-item {
          &__number {
            color: #eaf0fe;
          }
        }
      }
    }

    .list-item {
      position: relative;

      &__number {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 100px;
        line-height: 0.8;
        font-weight: $font-weight-medium;
        color: #f2f3f5;
      }

      &__heading {
        position: relative;
        font-size: 3rem;
        margin-bottom: 2rem;
      }

      &__content {
        position: relative;
        z-index: 1;
        font-size: 16px;
        color: $black;
        margin-bottom: 1rem;
        margin-top: 1.75rem;
        margin-left: 2.5rem;
      }
    }
  }
}
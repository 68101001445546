.a-cookiebot-toggler {
  color: #919aaf;
  background-color: transparent;
  padding: 0;
  border: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $blue-400;
    transition: color 0.3s ease-in-out;
  }
}
.o-cta-card {
  &__additional-container {
    position: relative;
    background-color: $purple-200;
    padding: 4rem 0;
    overflow: hidden;
    border-radius: 12px;

    @include media-breakpoint-up(md) {
      padding: 6rem 0;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  &__wave {
    position: absolute;
    width: 100%;
    pointer-events: none;
    z-index: 1;

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        top: -15px;
        right: -400px;
        opacity: 0.7;
      }

      svg {
        width: 100%;
      }
    }

    &--mobile {
      bottom: -100px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-size: 24px;
    font-weight: $font-weight-book;
    color: $white;
    text-align: center;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 38px;
    }
  }

  &__description {
    margin-top: 1rem;
    font-size: 16px;
    line-height: 1.25;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      margin-top: 2rem;
      font-weight: $font-weight-book;
      line-height: 1.44;
    }
  }

  &__buttons {
    margin-top: 3rem;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }
}
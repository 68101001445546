.o-tier-list-section {
  position: relative;
  z-index: 997;

  &--active {
    z-index: 998;
  }

  &__section-heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 24px;
    text-align: center;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      margin-bottom: 6rem;
    }
  }

  &__wrapper {
    &--2-cols {
      .o-tier-list-section__col {
        &--label {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 28%;
          }
        }

        &--value {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 36%;
          }
        }
      }
    }

    &--3-cols {
      .o-tier-list-section__col {
        &--label {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 28%;
          }
        }

        &--value {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 24%;
          }
        }
      }
    }
  }

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom,
        #fff 87%,
        rgba(255, 255, 255, 0) 100%);
    padding: 3rem 2rem 5rem;
    z-index: 1;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      z-index: 1;
      padding: 0;
    }

    .o-tier-list-section__col {
      padding-bottom: 5rem;
      padding-top: 5rem;
      margin-bottom: 0;

      &:not(:last-child) {
        border-bottom: 2px solid #dcdce5;
      }

      @include media-breakpoint-up(md) {
        padding-top: 4rem;
        padding-bottom: 3rem;

        &:not(:last-child) {
          border-bottom: none;
        }
      }
    }

    .o-tier-list-section__col--label {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }
    }
  }

  &__col {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      padding-bottom: 1.5rem;
      margin-bottom: 0;
    }

    &--label {
      justify-content: space-between;
      padding-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        padding-right: 2rem;
        padding-left: 3rem;
        padding-bottom: 1.5rem;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    &--value {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1.5rem;
    }

    &--checkmark {
      padding: 0;
    }

    &--border-bottom {
      border-bottom: 2px solid #dcdce5;
    }
  }

  &__col-heading {
    font-family: $font-family-geomanist;
    font-size: 24px;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
    text-align: center;
  }

  &__col-button {
    display: none;
    width: 70%;
    max-width: 192px;
    text-align: center;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      display: inline-block;
      max-width: unset;
      margin-top: 2rem;
    }

    &--text-white {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &--text-black {
      color: #000;

      &:hover {
        color: #000;
      }
    }

    &--toggler {
      display: inline-block;
      border: none;
      margin-top: 2rem;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__col-icon {
    display: flex;
  }

  &__col-text {
    font-size: 14px;
    line-height: 22px;
    font-weight: $font-weight-normal;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      padding: 0 1.5rem;
      font-weight: $font-weight-medium;
      text-align: center;
      line-height: 1.44;
    }
  }

  &__table {
    width: 100%;
    display: none;
    flex-direction: column;
    padding: 0;
    margin-bottom: 5rem;

    @include media-breakpoint-up(md) {
      display: block;
      flex-direction: unset;
    }

    &:not(:last-child) {
      margin-bottom: 5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 6rem;
      }
    }
  }

  &__table-name {
    font-family: $font-family-geomanist;
    font-size: 20px;
    margin: 0;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  &__row {
    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-up(md) {
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(even) {
      background-color: $blue-100;
    }

    &:last-child {
      .o-tier-list-section__col--border-bottom {
        border-bottom: none;
      }

      .o-tier-list-section__col--value {
        margin-bottom: 0;
      }
    }

    &--header {
      .o-tier-list-section__col--label {
        padding-bottom: 3rem;
        padding-top: 0;
        justify-content: flex-start;
      }
    }

    .o-tier-list-section {
      &__col {
        &--value {
          align-items: flex-start;

          @include media-breakpoint-up(md) {
            align-items: center;
          }

          &:not(.o-tier-list-section__col--checkmark) {
            padding-top: 0;

            @include media-breakpoint-up(md) {
              padding-top: 1.5rem;
            }
          }
        }
      }
    }
  }

  &__row-title {
    margin: 0;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 22px;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      font-weight: $font-weight-normal;
      line-height: 1.44;
    }

    a {
      color: #5651ed;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $blue-400;
        transition: color 0.2s ease-in-out;
      }
    }

    &--checkmark {
      font-weight: $font-weight-normal;
    }

    &--checkmark-empty {
      font-style: italic;
      text-decoration: line-through;
      color: #919aaf;
    }
  }

  &__actions {
    display: flex;
    margin-left: 3rem;

    &>* {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__details {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    padding: 0;

    &:hover {
      .o-tier-list-section__details {
        &-icon {
          svg {
            path {
              fill: $blue-400;
              transition: fill $transition-base;
            }
          }
        }
      }
    }

    &-icon {
      display: flex;
      cursor: pointer;

      svg {
        path {
          transition: fill $transition-base;
        }
      }
    }
  }

  &__tooltip {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    &:hover {
      .o-tier-list-section__tooltip-content {
        opacity: 1;
        transition: opacity $transition-base;
      }

      .o-tier-list-section__tooltip-icon {
        svg {
          path {
            fill: $blue-400;
            transition: fill $transition-base;
          }
        }
      }
    }
  }

  &__tooltip-icon {
    display: flex;
    cursor: pointer;

    svg {
      path {
        transition: fill $transition-base;
      }
    }
  }

  &__tooltip-content {
    position: absolute;
    top: 0;
    right: -0.5rem;
    transform: translateY(calc(-100% - 0.5rem));
    margin-bottom: 0;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    width: max-content;
    max-width: 212px;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-base;
  }

  &__player {
    flex-shrink: 0;
    cursor: pointer;

    &-icon {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }

  &__popups {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 998;
    opacity: 0;
    pointer-events: none;
    transform: scale(0.9);
    transition: opacity 0.15s ease-in-out 0.1s, transform 0.25s ease-in-out;

    &--active {
      opacity: 1;
      pointer-events: all;
      transform: scale(1);
      transition: opacity 0.15s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  &__popup {
    overflow: scroll;
    height: 100vh;
    padding: 43px 0 0 0;
    transition: transform $transition-base;

    @include media-breakpoint-up(md) {
      padding: 43px 16px 0 16px;
    }

    .o-tier-list-section__col-button {
      display: inline-block;
    }

    .o-tier-list-section__table {
      display: flex;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
  }

  &__nav-button {
    border: none;
    background-color: transparent;
    padding: 0;
    opacity: 0;
    pointer-events: none;

    svg {
      g {
        filter: unset;
      }

      path.arrow-path {
        fill: $blue-400;
      }
    }

    &--prev {
      transform: rotate(180deg);
    }

    &--next {}

    &--visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}
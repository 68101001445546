.o-faq-section {
  &__container {
    max-width: 792px;

    @include media-breakpoint-up(lg) {
      max-width: 808px;
    }
  }

  &__items {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    border-bottom: 1px solid #dcdce5;

    &.is-active {
      .o-faq-section {
        &__item {
          &-row {
            &--question {
              padding-bottom: 1rem;
            }

            &--answer {
              display: block;
            }
          }

          &-heading {
            color: $blue-400;
            transition: color $transition-base;
          }

          &-dropdown-icon {
            svg {
              transform: rotate(180deg);
              transition: transform $transition-base;

              path {
                fill: $blue-400;
                transition: fill $transition-base;
              }
            }
          }
        }
      }
    }

    &-row {
      &--question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 2rem 0;
      }

      &--answer {
        display: none;
      }
    }

    &-heading {
      color: $black;
      font-family: $font-family-geomanist;
      font-size: 18px;
      line-height: 24px;
      font-weight: $font-weight-medium;
      margin-bottom: 0;
      transition: color $transition-base;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-dropdown-icon {
      margin-left: 3rem;
      flex-shrink: 0;

      svg {
        transition: transform $transition-base;

        path {
          transition: fill $transition-base;
        }
      }
    }

    &-content {
      color: $black;
      font-size: 14px;
      line-height: 22px;
      font-weight: $font-weight-normal;
      padding-bottom: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }

      ul {
        padding-left: 2.5rem;

        @include media-breakpoint-up(md) {
          padding-left: 3rem;
        }

        li {
          &::marker {
            font-size: 2.5rem;
            line-height: 22px;

            @include media-breakpoint-up(md) {
              font-size: 3rem;
              line-height: 28px;
            }
          }
        }
      }

      ol {
        padding-left: 14px;

        @include media-breakpoint-up(md) {
          padding-left: 18px;
        }

        li {
          &::marker {
            color: $black;
            font-size: 14px;

            @include media-breakpoint-up(md) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
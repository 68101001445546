.o-newsletter-form {
  &--with-logos {
    .o-newsletter-form {
      &__additional-container {
        @include media-breakpoint-up(md) {
          padding: 5rem 0 2rem;
        }
      }
    }
  }

  &__additional-container {
    position: relative;
    background-color: $purple-200;
    padding: 4rem 0;
    border-radius: 12px;
    max-width: 880px;
    margin: 0 auto;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      padding: 5rem 0;
    }
  }

  &__content {
    max-width: 768px;
    margin: 0 auto;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  &__wave {
    position: absolute;
    width: 100%;
    pointer-events: none;
    z-index: 1;

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        top: -15px;
        right: -400px;
        opacity: 0.7;
      }

      svg {
        width: 100%;
      }
    }

    &--mobile {
      bottom: -100px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-size: 24px;
    font-weight: $font-weight-book;
    color: $white;
    text-align: center;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 38px;
      line-height: 40px;
    }
  }

  &__description {
    margin-top: 1rem;
    font-size: 16px;
    line-height: 1.25;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      margin-top: 2rem;
      line-height: 28px;
    }
  }

  &__form {
    max-width: 565px;
    margin: 3rem auto 0;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__input {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      max-width: 565px;
    }

    &--half {
      &:nth-child(odd) {
        @include media-breakpoint-up(md) {
          max-width: calc(35% - 1.5rem);
        }
      }

      &:nth-child(even) {
        @include media-breakpoint-up(md) {
          max-width: calc(65% - 1.5rem);
        }
      }
    }

    &.has-error {
      .a-input {
        &__label {
          color: #ff8787;
        }

        &__input {
          border-color: #ff8787;
        }
      }

      .choices__inner {
        border-color: #ff8787;
      }
    }

    .a-input {
      &__label {
        color: $white;
      }

      &__input {}
    }

    .choices {
      &__inner {
        background-color: #fff;
        border-color: #dcdce5;
      }

      &__list {
        &--dropdown {
          border-color: #dcdce5 !important;
        }
      }
    }
  }

  &__inputs .a-select__custom-text-option {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      max-width: 565px;
    }

    &--half {
      &:nth-child(odd) {
        @include media-breakpoint-up(md) {
          max-width: calc(35% - 1.5rem);
        }
      }

      &:nth-child(even) {
        @include media-breakpoint-up(md) {
          max-width: calc(65% - 1.5rem);
        }
      }
    }

    &.has-error {
      .a-input {
        &__label {
          color: #ff8787;
        }

        &__input {
          border-color: #ff8787;
        }
      }

      .choices__inner {
        border-color: #ff8787;
      }
    }

    .a-input {
      &__label {
        color: $white;
      }

      &__input {}
    }
  }

  &__consent {
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
  }

  &__checkbox {
    display: flex;
    align-items: flex-start;

    &.has-error {
      .a-checkbox__icon {
        border-color: #ff8787;
      }
    }

    .a-checkbox {
      &__icon {
        background-color: $white;
        margin-right: 1.5rem;
        margin-top: 1rem;
      }

      &__label {
        color: $white;
        margin-bottom: 0;
        line-height: 18px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
          line-height: 22px;
        }

        a {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  &__button {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 100%;
    font-weight: $font-weight-book;
    line-height: 10px;

    @include media-breakpoint-up(md) {
      max-width: 195px;
      font-size: 18px;
      line-height: 24px;
    }

    &.is-loading {
      pointer-events: none;

      .o-newsletter-form {
        &__loader {
          display: flex;
          background-color: $white;
        }

        &__loader-ellipsis {
          &>* {
            background-color: $purple-200;
          }
        }
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
  }

  &__notification {
    max-width: calc(100% - 4rem);

    @include media-breakpoint-up(md) {
      max-width: 416px;
    }
  }

  &__logos {
    width: 100%;
    max-width: 565px;
    border-top: 1px solid #dcdce5;
    margin-top: 3rem;
  }
}
.o-feature-list {
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      max-width: calc(760px + 32px);
      padding-left: 16px;
      padding-right: 16px;
    }

    @include media-breakpoint-up(lg) {
      max-width: calc(760px + 48px);
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__table {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &-name {
      font-family: $font-family-geomanist;
      font-weight: $font-weight-medium;
      margin: 0;
      padding: 0 2rem 1.5rem;
      border-bottom: 1px solid #dcdce5;
      font-size: 18px;
      line-height: 24px;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
        padding: 0 0 1.5rem;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem 2rem;

    @include media-breakpoint-up(md) {
      align-items: center;
      padding: 1.5rem 3rem;
    }

    &:nth-child(odd) {
      background-color: $blue-100;
    }

    &-title {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      color: $grey-400;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }

      a {
        color: #5651ed;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: $blue-400;
          transition: color 0.2s ease-in-out;
        }
      }
    }
  }

  &__actions {
    display: flex;
    margin-left: 3rem;

    &>* {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__details {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    padding: 0;

    &:hover {
      .o-feature-list__details {
        &-icon {
          svg {
            path {
              fill: $blue-400;
              transition: fill $transition-base;
            }
          }
        }
      }
    }

    &-icon {
      display: flex;
      cursor: pointer;

      svg {
        path {
          transition: fill $transition-base;
        }
      }
    }
  }

  &__tooltip {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    &:hover {
      .o-feature-list__tooltip-content {
        opacity: 1;
        transition: opacity $transition-base;
      }

      .o-feature-list__tooltip-icon {
        svg {
          path {
            fill: $blue-400;
            transition: fill $transition-base;
          }
        }
      }
    }
  }

  &__tooltip-icon {
    display: flex;
    cursor: pointer;

    svg {
      path {
        transition: fill $transition-base;
      }
    }
  }

  &__tooltip-content {
    position: absolute;
    top: 0;
    right: -0.5rem;
    transform: translateY(calc(-100% - 0.5rem));
    margin-bottom: 0;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    width: max-content;
    max-width: 212px;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-base;
  }

  &__player {
    flex-shrink: 0;
    cursor: pointer;

    &-icon {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }
}
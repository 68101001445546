.o-form {
  &__notification {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin: 0;
    padding: 12px;
    border-radius: 4px;
    border: solid 1px #dcdce5;
    background-color: $white;
    font-size: 14px;
    color: #000;
    transform: translate(-50%, 70%);
    width: max-content;
    max-width: calc(100vw - 2rem);
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 9px 12px;
      font-size: 16px;
      max-width: 416px;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 25px;
      margin-right: 12px;
      mask-size: contain;
      mask-repeat: no-repeat;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        width: 32px;
        height: 33px;
        margin-right: 2rem;
      }
    }

    &.is-active {
      display: flex;
    }

    &.is-error {
      &::before {
        mask-image: url("../../../../../assets/private/img/alert-circle.svg");
        background-color: $red-error;
      }
    }

    &.is-success {
      &::before {
        mask-image: url("../../../../../assets/private/img/check-circle.svg");
        background-color: $green-success;
      }
    }
  }
}

.o-blog-posts-list {
  margin-bottom: 10rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 17.5rem;
  }

  &__container {
    max-width: 936px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &:not(:last-child) {
      margin-bottom: 5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 8rem;
      }
    }

    &--big {
      @include media-breakpoint-up(md) {
        margin-bottom: 5rem;
        flex-direction: row-reverse;

        .o-blog-posts-list__col {
          &--content {
            max-width: 39.20454545454545%;
          }

          &--thumbnail {
            max-width: 57.95454545454546%;
          }
        }

        .o-blog-posts-list__group {
          &--desktop {
            margin-top: 0;
          }
        }

        .o-blog-posts-list__title {
          margin-bottom: 2rem;
        }
      }
    }

    &--medium {
      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        max-width: calc(50% - 20px);
        margin-bottom: 15rem;

        .o-blog-posts-list__col {
          max-width: 100%;

          &--thumbnail {
            margin-bottom: 1.5rem;
          }
        }

        .o-blog-posts-list__group {
          &--desktop {
            display: none;
          }

          &--mobile {
            display: flex;
            margin-bottom: 1.5rem;
          }
        }

        .o-blog-posts-list__title {
          font-size: 18px;
        }
      }
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      width: 100%;
    }

    &--content {
      @include media-breakpoint-up(md) {
        max-width: 54.090909090909086%;
      }
    }

    &--thumbnail {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        max-width: 43.18181818181818%;
        margin-bottom: 0;
      }
    }
  }

  &__group {
    justify-content: space-between;
    margin-bottom: 1rem;

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
      }
    }

    &--mobile {
      display: flex;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__category {
    font-size: 14px;
    line-height: 1.44;
    color: $blue-400;
    font-weight: $font-weight-book;
    transition: color $transition-base;

    &:hover {
      transition: color $transition-base;
    }

    @include media-breakpoint-up(md) {
      font-weight: $font-weight-normal;
    }
  }

  &__date {
    font-size: 14px;
    line-height: 1.44;
    color: $grey-400;
    font-weight: $font-weight-normal;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  &__title-link {
    font-weight: $font-weight-book;
    color: $black;
    transition: color $transition-base;

    &:hover {
      transition: color $transition-base;
    }

    @include media-breakpoint-up(md) {
      font-weight: $font-weight-medium;
    }
  }

  &__excerpt {
    font-size: 14px;
    color: $grey-400;
    font-weight: $font-weight-normal;
    line-height: 1.44;
  }

  &__img-wrapper {
    overflow: hidden;

    &--big-thumbnail {
      img {
        aspect-ratio: 510 / 287;
      }
    }

    &--thumbnail {
      img {
        aspect-ratio: 2 / 1;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      transition: transform $transition-base;

      &:hover {
        transform: scale(1.05);
        transition: transform $transition-base;
      }
    }
  }
}
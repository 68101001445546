.o-landing-header {
  &__wave {
    &--blue {
      color: #d5e1fe;
    }

    &--grey {
      color: #919aaf;
    }
  }

  .wave {
    &__container {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 600px;
        transform: translate(-34%, -25%);

        @include media-breakpoint-up(md) {
          width: auto;
          transform: translate(-35%, -14%);
        }
      }
    }
  }

  &__container {
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }

  &__col {
    &--content {
      @include media-breakpoint-up(md) {
        width: 57%;
      }
    }

    &--img {
      margin-top: 3rem;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: 43%;
      }

      &:not(.o-landing-header__col--animated) {
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  &__heading {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 1.4;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      max-width: 514px;
      font-size: 18px;
    }

    ul li {
      &:not(:last-child) {
        margin-bottom: 0.25rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__buttons {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }

  &__button {
    margin-right: 2rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
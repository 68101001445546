.o-breadcrumbs {
  margin-bottom: 1.5rem;

  &--big {
    margin-bottom: 5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 8.125rem;
    }

    .o-breadcrumbs__item {
      &--category {
        .o-breadcrumbs__name {
          color: $blue-400;
        }
      }
    }

    .o-breadcrumbs__link,
    .o-breadcrumbs__name,
    .o-breadcrumbs__separator {
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 34px;
      }
    }
  }

  &__container {}

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  &__item {
    &--category {}

    &--current {
      width: 100%;
      margin-top: 2rem;

      .o-breadcrumbs__name {
        font-size: 26px;
        font-weight: $font-weight-medium;
        color: $blue-400;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: 32px;
          line-height: initial;
        }
      }
    }
  }

  &__link {
    font-size: 16px;
    color: #222222;
    line-height: 1.44;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    &:hover {
      color: $blue-400;
      transition: color $transition-base;
    }
  }

  &__name {}

  &__separator {
    font-size: 16px;
    color: $grey-400;
    margin: 0 0.75rem;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.44;
      margin: 0 1rem;
    }
  }
}
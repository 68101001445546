.o-secondary-header {
  &__wave {
    &--blue {
      color: #D5E1FE;
    }

    &--grey {
      color: #919AAF;
    }
  }

  .wave {
    &__container {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 600px;
        height: 600px;
        transform: translate(-34%, -25%);

        @include media-breakpoint-up(md) {
          width: auto;
          height: auto;
          transform: translate(-35%, -14%);
        }
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;

    &--reverse {
      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;

        .o-secondary-header {
          &__img {
            margin: 0 auto 6rem;
          }
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    text-align: center;
    margin-bottom: 0;
  }

  &__description {
    margin-top: 2rem;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
      font-size: 18px;
    }
  }

  &__buttons {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }

  &__button {
    margin-right: 2rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__img {
    height: auto;
    display: block;
    max-width: 100%;
    margin: 6rem auto 0;

    &--mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}
.o-blog-nav {
  position: relative;
  padding-top: 3rem;
  z-index: 1;
  transition: max-width $transition-base 0.2s, transform $transition-base, padding-top $transition-base;
  margin: 0 auto;

  @media screen and (min-width: 992px) and (max-width: 1270px) {
    max-width: calc(100vw - 390px); // 390px = logo width + request a demo button width + margins
  }

  @media screen and (min-width: 1271px) {
    max-width: 880px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 6rem;
  }

  @include media-breakpoint-down(md) {
    &.is-expanded {
      .o-blog-nav__categories-expand {
        .o-blog-nav__category-arrow {
          transform: rotate(180deg);
          transition: transform $transition-base;
        }
      }

      .o-blog-nav__content-wrapper {
        border-color: transparent;
      }

      .o-blog-nav__category-items {
        display: flex;
        padding-bottom: 3rem;
        padding-top: 3rem;
        max-height: 1000px;
        transition: max-height $transition-base, padding-bottom $transition-base,
          padding-top $transition-base;
      }
    }
  }

  &__container {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 880px;

    @include media-breakpoint-down(md) {}
  }

  &__content-wrapper {
    @include make-container();
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: solid 1px #dcdce5;
    max-width: 100%;
    margin-left: 0;
    transition: padding-bottom $transition-base, margin-left $transition-base 0.2s, max-width $transition-base 0.2s;

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__categories-wrapper {
    opacity: 1;
    max-width: 100px;
    transition: opacity 0.4s linear, max-width 0.4s linear;

    @include media-breakpoint-up(md) {
      max-width: 100%;
    }

    &.is-hidden {
      max-width: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.4s linear, max-width 0.4s linear;
    }
  }

  &__categories-expand {
    font-size: 18px;
    display: flex;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__category-items {
    @include make-container();
    position: absolute;
    left: 0;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    background-color: $white;
    transition: max-height $transition-base, padding-bottom $transition-base,
      padding-top $transition-base;

    @include media-breakpoint-up(lg) {
      overflow: initial;
      flex-direction: row;
      position: relative;
      max-height: initial;
      padding: 0;
    }
  }

  &__category-item {
    position: relative;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding-top: 2rem;

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin-right: 4rem;
      }
    }

    &--sub {
      padding-top: 2rem;

      @include media-breakpoint-up(lg) {
        padding-top: 0;

        &:not(:last-child) {
          padding-bottom: 1.5rem;
        }
      }
    }

    @include media-breakpoint-down(md) {
      &.is-expanded {
        .o-blog-nav__category-submenu-wrapper {
          opacity: 1;
          max-height: 1000px;
          pointer-events: all;
          transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out 0.2s;
        }

        .o-blog-nav__category-arrow {
          transform: rotate(180deg);
          transition: transform $transition-base;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;

      &:hover {
        .o-blog-nav__category-arrow {
          transform: rotate(180deg);
          transition: transform $transition-base;

          svg path {
            fill: $blue-400;
            transition: fill $transition-base;
          }
        }

        .o-blog-nav__category-submenu-wrapper {
          opacity: 1;
          pointer-events: all;
          transition: opacity $transition-base;
        }
      }
    }
  }

  &__category-label,
  &__category-link {
    font-size: 16px;
    color: #222222;
    cursor: pointer;
    transition: color $transition-base;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        color: $blue-400;
        transition: color $transition-base;
      }
    }
  }

  &__category-label {
    display: flex;
  }

  &__category-link {}

  &__category-arrow {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    transition: transform $transition-base;

    svg path {
      transition: fill $transition-base;
    }
  }

  &__category-submenu-wrapper {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out, max-height 0.2s ease-in-out 0.2s;

    @include media-breakpoint-up(lg) {
      width: max-content;
      position: absolute;
      left: 0;
      bottom: 0;
      max-height: initial;
      padding-top: 1rem;
      left: 0;
      transform: translate(-2rem, 100%);
    }
  }

  &__category-submenu {
    padding-left: 1.5rem;

    @include media-breakpoint-up(lg) {
      padding: 2rem;
      background-color: $white;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: solid 1px #dcdce5;
    }
  }
}
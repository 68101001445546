#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText,
#CookiebotWidget {
  display: none !important;
}

#CybotCookiebotDialog {
  top: auto !important;
  transform: translateX(-50%) !important;
  bottom: 0 !important;
}

#CybotCookiebotDialog[data-template=popup] {
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

#CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
  max-height: 14em !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
  min-height: 0 !important;
}

#CookiebotWidget.CookiebotWidget-open {
  display: block !important;
}

.CookiebotWidget-main-logo {
  display: none !important;
}

@media screen and (max-width: 992px) {

  #CybotCookiebotDialog *,
  #CybotCookiebotDialogBodyUnderlay * {
    font-size: 12px;
  }
}
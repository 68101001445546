.o-text-editor-section {
  &__content {
    max-width: 808px;
    margin: 0 auto;

    h2 {
      margin-top: 3rem;
    }

    h3 {
      margin-top: 8rem;
    }

    h4 {
      margin-top: 6rem;
    }

    h2,
    h3,
    h4 {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      font-weight: 500;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: $grey-400;
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    ul {
      list-style-type: none;
      padding: 0 0 0 2rem;

      li {
        padding-left: 2rem;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 6px;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: $primary;

          @include media-breakpoint-up(md) {
            top: 9px;
          }
        }
      }
    }

    ol {
      padding: 0 0 0 4rem;

      li {
        padding-left: .5rem;
      }
    }

    ul,
    ol {
      margin: 3rem 0;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        position: relative;
        font-size: 14px;
        line-height: 1.25;
        color: $grey-400;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 1.4;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
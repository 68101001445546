.o-form-section-v2 {

  &__container {
    max-width: 1208px;
  }

  &__sticky {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $white;
    padding: 2rem;
    box-shadow: 0 0 16px 0 rgba(39, 56, 80, 0.1);
    z-index: 100;
    transition: opacity 0.2s ease-in-out;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &.is-hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }

    &-link {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      font-weight: $font-weight-book;
      padding: 1rem;
    }
  }

  &__inner {
    position: relative;
    max-width: 1152px;
    margin: 0 auto;
  }

  &__arrow {
    display: none;
    position: absolute;
    left: -40px;
    bottom: 25px;
    transform: translate(-50%, 0);

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__blob-1 {
    display: none;
    position: absolute;
    top: -21.5px;
    right: -60px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__blob-2 {
    display: none;
    position: absolute;
    left: -100px;
    bottom: -5px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__form-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 3rem 2rem;
    border-radius: 2rem;
    background-color: #fff;
    box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
    border: 1px solid #dcdce5;
    z-index: 1;

    @include media-breakpoint-up(md) {
      padding: 3rem;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }
  }

  &__row {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: stretch;
    }
  }

  &__col {
    width: 100%;

    &--content {
      display: flex;
      align-items: center;
      padding: 0 0 3rem 0;
      border-bottom: 1px solid #dcdce5;

      @include media-breakpoint-up(md) {
        max-width: 662px;
        padding: 3rem 3rem 3rem 0;
        margin-right: 3rem;
        border-bottom: none;
        border-right: 1px solid #dcdce5;
      }
    }

    &--form {
      padding: 3rem 0 0;

      @include media-breakpoint-up(md) {
        max-width: 418px;
        padding: 0;
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__headings {
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }

    &--with-steps {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__heading {
    text-align: center;
    font-size: 18px;
    line-height: 24px;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 56px;
    }
  }

  &__r-subheading {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: $grey-400;
    max-width: 800px;
    margin: 1rem auto 0;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 32px;
      margin: 2rem auto 0;
    }
  }

  &__subheading {
    text-align: center;
    font-size: 14px;
    font-weight: $font-weight-normal;
    line-height: 20px;
    margin-top: 0.5rem;
    color: $grey-400;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 32px;
      margin-top: 0;
    }
  }

  &__description {
    margin-bottom: 3rem;

    &>* {
      color: $grey-400;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }

      strong {
        font-weight: $font-weight-book;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icons-with-text {
    list-style: none;
    padding: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 6rem;
    }

    .item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 5rem;
        }
      }

      &__icon {
        width: 24px;
        flex-shrink: 0;
        margin-right: 2rem;

        @include media-breakpoint-up(md) {
          width: 40px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__text {
        &>* {
          color: $grey-400;
          font-size: 14px;
          line-height: 22px;

          @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 28px;
          }

          strong {
            font-weight: $font-weight-book;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__quotations {
    margin-top: 3rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }

  &__logos {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dcdce5;
    padding-top: 2rem;
    margin: 0 0 -1rem;

    @include media-breakpoint-up(md) {
      margin: 0 0 -2rem;
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    &--mobile {
      display: flex;
      margin-top: 3rem;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .item {
      margin: 0 0.5rem 1rem;

      @include media-breakpoint-up(md) {
        margin: 0 1rem 2rem;
      }

      &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 48px;

        @include media-breakpoint-up(md) {
          width: 129px;
          height: 56px;
        }

        img,
        svg {
          width: auto;
          height: auto;
          max-width: 110px;
          max-height: 48px;

          @include media-breakpoint-up(md) {
            max-width: 129px;
            max-height: 56px;
          }
        }
      }
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__hidden-fields {
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-base;
  }

  &__input {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      width: calc(50% - 1.5rem);
      margin-bottom: 3rem;
    }

    &--full-width {
      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    &.js-intl-tel {
      .iti {
        width: 100%;
      }
    }

    .choices {
      &__inner {
        background-color: #fff;
        border-color: #dcdce5;
      }

      &__list {
        &--dropdown {
          border-color: #dcdce5 !important;
        }
      }
    }
  }

  &__inputs .a-select__custom-text-option {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__consent {
    font-size: 16px;
    line-height: 1.25;
    color: $grey-400;

    .a-checkbox {
      &__icon {
        margin-top: 0.5rem;

        @include media-breakpoint-up(md) {
          margin-top: 1rem;
        }

      }

      &__label {
        font-size: 12px;
        line-height: 18px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 2rem;
    font-size: 18px;
    position: relative;

    &.is-loading {
      pointer-events: none;

      .o-form-section-v2 {
        &__loader {
          display: flex;
        }
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
  }

  &__notification {
    position: relative;
    left: unset;
    bottom: unset;
    transform: none;
    max-width: unset;
    width: 100%;
    margin-top: 2rem;
  }
}
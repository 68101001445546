.a-bullet-list {
  &__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-left: 1.5rem;
    }
  }

  &__list-item {
    display: flex;
    align-items: flex-start;
    color: $grey-400;
    font-size: 16px;
    line-height: 1.25;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.44;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }

    &--dot {
      position: relative;
      padding-left: 2rem;

      @include media-breakpoint-up(md) {
        padding-left: 2.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: $blue-400;
      }
    }

    &--checkmark {
    }
  }

  &__list-item-icon {
    margin-right: 0.75rem;

    @include media-breakpoint-up(md) {
      margin-right: 1.5rem;
    }
  }
}

.c-tier-list-section-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  @include media-breakpoint-up(md) {
    background-color: rgba(39, 56, 80, 0.6);
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.3s ease-in-out;

    .c-tier-list-section-popup {
      &__container {
        // transform: scale(1);
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  &__container {
    max-width: 870px;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 2rem 2rem 6rem 2rem;
    // transform: scale(0.8);
    transform: translateY(10rem);
    transition: transform 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
      height: initial;
      max-height: 90vh;
      border-radius: 1rem;
      margin: 3rem;
      padding: 2rem 3rem 3rem 3rem;
    }
  }

  &__row {
    &--1 {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        border-bottom: 1px solid #dcdce5;
      }
    }

    &--2 {
      display: flex;
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    font-size: 18px;
    line-height: 24px;
    color: $grey-400;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
      margin-right: 3rem;
      color: $black;
    }
  }

  &__close {
    flex-shrink: 0;
    border: none;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(39, 56, 80, 0.4);
    padding: 0;
    margin-left: auto;
    margin-bottom: 2rem;
    transition: background-color 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
      margin-left: 3rem;
      margin-bottom: 0;
    }

    &:hover {
      background-color: rgba(39, 56, 80, 1);
      transition: background-color 0.3s ease-in-out;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__description {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      margin-right: 3rem;
      max-width: 263px;
      font-size: 18px;
      line-height: 28px;
    }

    &>* {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-left: 3rem;
      margin-bottom: 0;
      max-width: 511px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
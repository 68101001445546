.a-language-switcher {
  &--desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      position: relative;
      display: block;
    }

    &:hover {
      .a-language-switcher {
        &__dropdown {
          opacity: 1;
          pointer-events: all;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }
  }

  &--mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    .a-language-switcher {

      &__current,
      &__dropdown {
        display: none;
      }
    }
  }

  &--mobile-open-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.0625rem;
    margin-right: 1.5rem;
    background: transparent;
    border: 1px solid #dcdce5;
    opacity: 0;
    pointer-events: none;
    border-radius: 0.5rem;
    transition: opacity 0.2s ease-in-out, border-color 0.2s ease-in-out;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &.is-active {
      border-color: $blue-400;
      transition: opacity 0.2s ease-in-out, border-color 0.2s ease-in-out;

      .a-language-switcher {
        &__dropdown {
          svg {
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;

            path {
              fill: $blue-400;
              transition: fill 0.2s ease-in-out;
            }
          }
        }
      }
    }

    .a-language-switcher {
      &__dropdown {
        position: relative;
        transform: none;
        opacity: 1;
        padding: 0;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.5rem;

        svg {
          transition: transform 0.2s ease-in-out;

          path {
            transition: fill 0.2s ease-in-out;
          }
        }
      }
    }
  }

  &__mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &-current {
      display: none;

      &-icon {
        display: flex;
        flex-shrink: 0;
      }
    }

    &-popup {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      opacity: 0;
      min-height: 100vh;
      pointer-events: none;
      transition: opacity $transition-base;

      &.is-active {
        opacity: 1;
        pointer-events: all;
        transition: opacity $transition-base;
      }

      &-text {
        color: #919aaf;
        font-size: 18px;
        line-height: 28px;
        font-weight: $font-weight-normal;
        margin: 0;
      }

      &-items {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &-item {
        &:not(:last-child) {
          border-bottom: 1px solid #dcdce5;
        }

        &-link {
          display: flex;
          align-items: center;
          padding: 2rem 0;
        }

        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        &-name {
          font-family: $font-family-geomanist;
          margin-left: 1rem;
          font-size: 18px;
          line-height: 24px;
          font-weight: $font-weight-normal;
          color: $black;
        }

        &-checkmark {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          margin-left: 1rem;
        }
      }
    }
  }

  &__current {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;

    &-icon {
      display: flex;
      flex-shrink: 0;
      margin-right: 1rem;
    }

    &-dropdown {
      display: flex;
      flex-shrink: 0;
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    bottom: 0;
    width: max-content;
    padding-top: 2rem;
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    &-wrapper {
      padding: 2rem;
      list-style: none;
      background-color: $white;
      border: 1px solid #dcdce5;
      border-radius: 0.5rem;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    }

    &-triangle {
      position: absolute;
      right: 1rem;
      top: 2rem;
      transform: translateY(-100%) translateY(1px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 12px 10px;
      border-color: transparent transparent #dcdce5 transparent;

      &--inner {
        left: 0;
        top: 0;
        transform: translateX(-50%) translateY(2px);
        border-width: 0 9px 11px 9px;
        border-color: transparent transparent #fff transparent;
      }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &-link {
        display: flex;
        align-items: center;

        &:hover {
          .a-language-switcher {
            &__dropdown-item {
              &-name {
                color: $blue-400;
                transition: color 0.2s ease-in-out;
              }
            }
          }
        }
      }

      &-icon {
        flex-shrink: 0;
        display: flex;
        margin-right: 1rem;
      }

      &-name {
        color: $black;
        font-size: 18px;
        line-height: 24px;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}
.o-blog-posts-section {
  &__container {
    max-width: 1256px;
  }

  .swiper {
    &-wrapper {
      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-slide {
      height: auto;
      padding: 16px 8px;

      @include media-breakpoint-up(md) {
        padding: 16px 12px;
      }
    }
  }

  &__post {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 0 16px 0 rgba(39, 56, 80, 0.1);
    border-radius: 0.5rem;

    &:hover {
      .o-blog-posts-section__post {
        &-heading {
          color: $blue-300;
          transition: color $transition-base;
        }

        &-link {
          color: $blue-300;
          transition: color $transition-base;

          svg {
            transform: translateX(2px) rotate(-90deg);
            transition: transform $transition-base;

            path {
              fill: $blue-300;
              transition: fill $transition-base;
            }
          }
        }
      }
    }

    &-image {
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 0.5rem 0.5rem 0 0;
        object-fit: cover;
      }

      &--mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }

        img {
          aspect-ratio: 95 / 66;
        }
      }

      &--desktop {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }

        img {
          aspect-ratio: 2 / 1;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 2rem;
      height: 100%;
      border-radius: 0 0 0.5rem 0.5rem;
    }

    &-heading {
      color: $black;
      font-size: 18px;
      line-height: 24px;
      font-weight: $font-weight-medium;
      transition: color $transition-base;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-excerpt {
      font-size: 14px;
      line-height: 22px;
      color: $grey-400;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid #dcdce5;
      padding-top: 1rem;
      margin-top: auto;
    }

    &-link {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: $blue-400;
      transition: color $transition-base;

      svg {
        width: 8px;
        height: 8px;
        transform: rotate(-90deg);
        margin-left: 1rem;
        transition: transform $transition-base;

        path {
          transition: fill $transition-base;
        }
      }
    }
  }
}
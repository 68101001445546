.a-floating-button {
  position: fixed;
  right: 2rem;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
  background-color: $white;
  border: none;
  z-index: 100;

  &__icon {
    display: flex;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-400;
  }
}
.a-checkbox {
  display: flex;
  width: 100%;

  input {
    display: none;
  }

  &__icon {
    position: relative;
    flex: 0 0 auto;
    display: inline-block;
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
    border: solid 1px #dcdce5;
    border-radius: 4px;
    margin-right: 1rem;
    transition: background-color $transition-base, border-color $transition-base;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      width: 3.25rem;
      height: 3.25rem;
    }

    &:before {
      content: '';
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      width: 1.75rem;
      height: 1.75rem;
      mask-image: url('../../../../../assets/private/img/checkmark.svg');
      background-color: $primary;
      transform: translate(-50%, -50%);
      transition: opacity $transition-base;
    }
  }

  &__label {
    flex: 1 1 auto;
    display: inline;
    cursor: pointer;
    font-size: 12px;
    line-height: 1.33;
    color: $grey-400;

    @include media-breakpoint-up(md) {
      font-size: 13px;
      line-height: 1.38;
    }
  }

  &.is-active {
    .a-checkbox {
      &__icon {
        border-color: $primary;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &.has-error {
    .a-checkbox {
      &__icon {
        border-width: 2px;
        border-color: $red-error;
      }
    }
  }
}
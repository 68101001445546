.o-benefits-section-2 {
  &__container {}

  &__items {
    margin: 0 -12px;
    padding: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    list-style: none;
    margin: 0 12px;
    padding: 3rem 2rem;
    border-radius: 2rem;
    background-color: $white;
    box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);

    @include media-breakpoint-up(md) {
      height: auto;
      padding: 5rem 3rem;
      max-width: 486px;
    }

    @include media-breakpoint-up(md) {
      &--desktop-size {
        &-small {}

        &-medium {
          padding: 4rem 3rem;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &-icon {
      width: 100%;

      &+* {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
          margin-top: 3rem;
        }
      }

      &--mobile-size {
        &-small {
          img {
            max-width: 48px;
          }
        }

        &-medium {
          img {
            max-width: 120px;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &--desktop-size {
          &-small {
            img {
              max-width: 56px;
            }
          }

          &-medium {
            img {
              max-width: 160px;
            }
          }
        }
      }

      &--inline {
        display: flex;
        align-items: flex-start;

        img {
          margin-right: 2rem;

          @include media-breakpoint-up(md) {
            margin-right: 3rem;
          }
        }

        .o-benefits-section-2__item-heading {
          margin: 0;
        }

        .o-benefits-section-2__item-desc {
          margin: 1rem 0 0 0;

          *:last-child {
            margin-bottom: 0;
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-heading {
      font-family: $font-family-geomanist;
      font-size: 18px;
      line-height: 24px;
      font-weight: $font-weight-medium;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-desc {
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }

      &>* {
        font-size: 14px;
        line-height: 22px;
        color: $grey-400;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      ul li {
        &:not(:last-child) {
          margin-bottom: 0.75rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 1.25rem;
          }
        }
      }
    }

    &-audio-button {
      @include media-breakpoint-up(md) {
        margin-top: 3rem;
      }

      &-wrapper {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
          margin-top: auto;
        }
      }
    }

    &-button {
      margin-top: 2rem;

      @include media-breakpoint-up(md) {
        margin-top: auto;
      }
    }
  }
}
.page-template-searchpage,
.search-results {
  overflow: visible;

  .l-wrapper {
    &__main {
      padding-top: 16.5rem;

      @include media-breakpoint-up(md) {
        padding-top: 23rem;
      }

      .container {
        max-width: 936px;
      }

      .o-recommended-articles {
        background-color: $white;

        &__inner-container {
          margin-right: auto;
        }

        &__heading {
          font-size: 18px;
          line-height: 24px;
          color: $black;

          @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
.m-search-form {
  display: flex;
  align-items: center;

  &.is-active {
    .m-search-form__control-wrapper {
      max-width: 300px;
      transition: max-width 0.4s linear;

      @include media-breakpoint-up(md) {
        max-width: 400px;
      }
    }
  }

  &--organism {
    margin-bottom: 4.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 8rem;
    }

    .m-search-form__wrapper {
      border: 1px solid #dcdce5;
      border-radius: 4px;

      @include media-breakpoint-up(md) {
        border: none;
      }
    }

    .m-search-form__icon {
      &--search {
        svg {
          @include media-breakpoint-up(md) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .m-search-form__control-wrapper {
      width: 100%;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        max-width: 90%;
      }
    }

    .m-search-form__input {
      width: 100%;
      margin-right: 0;
      font-size: 18px;
      color: $black;

      @include media-breakpoint-up(md) {
        font-size: 34px;
        margin-left: 1.5rem;
        padding-bottom: 5px;
        border-bottom: 1px solid $black;
      }

      &::placeholder {
        font-size: 18px;

        @include media-breakpoint-up(md) {
          font-size: 34px;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    padding: 1rem 0.5rem;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--search {}

    &--close {}
  }

  &__control-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    max-width: 0;
    transition: max-width 0.4s linear;
  }

  &__input {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    min-width: 206px;
    color: $grey-400;
    font-size: 16px;
    font-weight: $font-weight-normal;
    border: none;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      min-width: 232px;
      margin-right: 5rem;
      margin-left: 1rem;
    }

    &::placeholder {
      font-size: 16px;
      color: #919aaf;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    &:focus {
      outline: none;
    }
  }
}
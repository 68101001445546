.o-form-section {
  &__inner {
    position: relative;
    max-width: 664px;
    margin: 0 auto;
  }

  &__arrow {
    display: none;
    position: absolute;
    left: -40px;
    bottom: 25px;
    transform: translate(-50%, 0);

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__blob-1 {
    position: absolute;
    top: -30px;
    right: 0;
    transform: translate(50%, 0);
  }

  &__blob-2 {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateX(-50%);
    width: 120px;

    svg {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 293px;
    }
  }

  &__form-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 3rem 3rem 5rem;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(57, 57, 57, 0.08);
    border: 1px solid #dcdce5;
    z-index: 1;

    @include media-breakpoint-up(md) {
      padding: 5rem;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }
  }

  &__logos {
    width: 100%;
    border-top: 1px solid rgba(220, 220, 229, 0.5);
    margin-top: 3rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }
}
.o-table-section {
  &__container {
    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &__wrapper {
    overflow-x: auto;
  }

  &__table {
    width: 100%;

    @include media-breakpoint-down(sm) {
      width: max-content;
    }

    &-tbody {}

    &-tr {
      &:nth-child(odd) {
        td {
          background-color: #eff7ff;
        }
      }

      &:nth-child(even) {
        td {
          background-color: #f4f9fe;
        }
      }
    }

    &-td {
      border: 1px solid $white;
      padding: 1rem 2rem;
      max-width: 254px;
      vertical-align: top;

      &>* {
        color: #919aaf;
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      p {
        margin-bottom: 0;
      }

      strong {
        font-weight: $font-weight-book;
      }
    }
  }

  &__caption {
    color: #4a4a4a;
    font-style: italic;
    font-size: 14px;
    line-height: 24px;
    margin-top: 1.5rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
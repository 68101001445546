.o-lifecycle-section {

  &__mobile-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .mobile-list {
    &__item {
      max-width: 320px;
      display: flex;
      align-items: center;
      margin: 0 auto 5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .item {
      &__icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58.5px;
        height: 58.5px;
        border-radius: 50%;
        flex-shrink: 0;
        background-color: $white;
        padding: 2rem;
        margin-right: 3.9375rem;

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 76px;
          height: 76px;
          background-color: #354c6d;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }

      &__icon {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }

      &__content {
        position: relative;
        width: calc(100% - 6rem);
        display: flex;
        align-items: center;
      }

      &__description {
        max-width: 136px;
        font-size: 14px;
        line-height: 22px;
        color: $black;

        p {
          margin: 0;
        }
      }

      &__number {
        font-size: 100px;
        line-height: 0.8;
        font-weight: $font-weight-medium;
        color: #f2f3f5;
        margin-right: 0.875rem;
      }
    }
  }

  &__desktop-graph {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
.o-event-summary {
  &__container {
    max-width: 1004px;
  }

  &__blob {
    display: none;
    position: absolute;
    pointer-events: none;
    z-index: -1;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &--1 {
      left: -11rem;
      top: -10rem;
    }

    &--2 {
      right: -12.25rem;
      bottom: -5.25rem;
    }
  }

  &__content {
    background-color: $white;
    border-radius: 2rem;
    padding: 2rem;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include media-breakpoint-up(lg) {
      padding: 4rem 3rem;
    }
  }

  &__col {
    width: 100%;

    &--1 {
      @include media-breakpoint-up(md) {
        max-width: 367px;
        margin-right: 4rem;
      }
    }

    &--2 {
      margin-top: 4rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        max-width: 443px;
      }
    }
  }

  &__logo {
    margin-bottom: 2rem;

    img {
      width: 120px;
      height: 100%;

      @include media-breakpoint-up(lg) {
        width: 160px;
      }
    }
  }

  &__heading {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    color: $grey-400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 5rem;
    }
  }

  &__details {
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 5rem;
    }
  }

  &__detail {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #dcdce5;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;

      @include media-breakpoint-up(lg) {
        width: 3rem;
        height: 3rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-label {
      color: #919aaf;
      margin-right: 1rem;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-value {
      color: $black;
      margin-left: auto;
      width: 50%;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -0.5rem;

    @include media-breakpoint-up(md) {
      margin: -0.75rem;
    }

    @include media-breakpoint-up(lg) {
      margin: -1.5rem;
    }
  }

  &__button {
    margin: 0.5rem;
    padding: 0.875rem 2.375rem;
    font-size: 14px;
    line-height: 16px;
    width: calc(100% - 8px);

    @include media-breakpoint-up(md) {
      margin: 0.75rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 24px;
      margin: 1.5rem;
    }

    @include media-breakpoint-up(md) {
      &--width {
        &-25 {
          width: calc(25% - 12px);

          @include media-breakpoint-up(lg) {
            width: calc(25% - 24px);
          }
        }

        &-40 {
          width: calc(40% - 12px);

          @include media-breakpoint-up(lg) {
            width: calc(40% - 24px);
          }
        }

        &-50 {
          width: calc(50% - 12px);

          @include media-breakpoint-up(lg) {
            width: calc(50% - 24px);
          }
        }

        &-60 {
          width: calc(60% - 12px);

          @include media-breakpoint-up(lg) {
            width: calc(60% - 24px);
          }
        }

        &-75 {
          width: calc(75% - 12px);

          @include media-breakpoint-up(lg) {
            width: calc(75% - 24px);
          }
        }

        &-100 {
          width: calc(100% - 12px);

          @include media-breakpoint-up(lg) {
            width: calc(100% - 24px);
          }
        }
      }
    }
  }

  &__our-representatives {
    &-heading {
      color: $grey-400;
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
      padding-bottom: 1rem;
      border-bottom: 1px solid #dcdce5;
      margin-bottom: 3rem;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-people-items {}

    &-people-item {
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        justify-content: space-between;
      }

      &:not(:last-child) {
        margin-bottom: 3rem
      }

      &-col {
        width: 100%;

        &--1 {
          max-width: 72px;
          margin-right: 3.25rem;
        }

        &--2 {
          @include media-breakpoint-up(md) {
            max-width: 345px;
          }
        }
      }

      &-image {
        position: relative;

        img {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          object-position: center;
          object-fit: cover;
        }

        svg {
          position: absolute;
          right: -0.5rem;
          bottom: -0.5rem;
        }
      }

      &-name {
        margin: 0;
        color: $black;
        font-weight: $font-weight-medium;
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      &-position {
        margin: 0;
        color: $grey-400;
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

.calendly-popup-content iframe {
  min-height: 700px;
}
.o-statistics-section {
  &__boxes {
    margin: 0 auto;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    &--display-management {
      .box {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }

        &--is-mobile {
          display: block;
        }
      }
    }

    &--items {
      &-1 {
        .o-statistics-section {
          &__box {
            @include media-breakpoint-up(md) {
              width: 100%;
            }

            .box__content {
              @include media-breakpoint-up(md) {
                max-width: unset;
              }
            }
          }
        }
      }

      &-2 {
        .o-statistics-section {
          &__box {
            @include media-breakpoint-up(md) {
              width: 50%;
            }

            .box__content {
              @include media-breakpoint-up(md) {
                max-width: unset;
              }
            }
          }
        }
      }

      &-3 {
        .o-statistics-section {
          &__box {
            @include media-breakpoint-up(md) {
              width: 33.3333%;
            }

            .box__content {
              @include media-breakpoint-up(md) {
                max-width: unset;
              }
            }
          }
        }
      }

      &-4 {
        .o-statistics-section {
          &__box {
            @include media-breakpoint-up(md) {
              width: 25%;
            }

            .box__content {
              @include media-breakpoint-up(md) {
                max-width: unset;
              }
            }
          }
        }
      }

      &-5 {
        .o-statistics-section {
          &__box {
            @include media-breakpoint-up(md) {
              width: 20%;
            }

            .box__content {
              @include media-breakpoint-up(md) {
                max-width: unset;
              }
            }
          }
        }
      }
    }
  }

  &__box {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    min-width: 180px;

    @include media-breakpoint-up(md) {
      width: 20%;
      padding: 0 3rem;
      margin-bottom: 5rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dcdce5;

      @include media-breakpoint-up(md) {
        border-bottom: none;
        border-right: 1px solid #dcdce5;
      }
    }
  }

  .box {
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        display: block;
        max-width: 184px;
      }
    }

    &__value {
      display: block;
      font-size: 52px;
      line-height: 60px;
      font-weight: $font-weight-medium;
      color: $primary;
      text-align: right;
      margin-right: 2rem;
      width: 100%;
      max-width: 200px;

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
        text-align: center;
        max-width: unset;
        margin-right: 0;
      }
    }

    &__label {
      display: block;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      width: 100%;
      max-width: 200px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
        max-width: unset;
        text-align: center;
      }
    }
  }
}
.single-blog {
  overflow: visible;

  .o-blog-nav {
    @include media-breakpoint-up(lg) {
      max-width: 1200px;
    }

    &__container {
      @include media-breakpoint-up(lg) {
        max-width: 1200px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  .l-wrapper {
    &__main {
      padding-top: 17.5rem;

      @include media-breakpoint-up(md) {
        padding-top: 20.5rem;
      }

      .container {}

      .o-text-editor-section__content {
        max-width: 100%;
      }

      .o-cta-card__wave--desktop {
        @include media-breakpoint-up(md) {
          right: -180px;
        }
      }

      .o-cta-bar {
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
          max-width: 880px;
        }

        &__container {
          @include media-breakpoint-up(lg) {
            padding-left: 24px !important;
            padding-right: 24px !important;
          }
        }

        &__wave {
          @include media-breakpoint-up(lg) {
            right: 250px;
          }
        }
      }

      .wp-block-separator,
      .wp-block-audio,
      .wp-block-embed {
        margin: 0 16px;

        @include media-breakpoint-up(lg) {
          max-width: 880px;
          margin-left: 0;
          margin-right: 0;
        }
      }

      .a-separator {
        @include media-breakpoint-up(lg) {
          max-width: 880px;
          margin: 0 auto;
        }
      }

      .wp-block-audio {
        margin-top: 4rem;

        audio {
          width: 100%;
        }
      }

      .wp-block-embed {
        margin-top: 4rem;

        .wp-block-embed__wrapper {
          position: relative;

          &::before {
            content: "";
            display: block;
            padding-top: 56.25%;
          }

          iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

.l-single-blog {
  position: relative;

  &--unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__container {
    @include media-breakpoint-down(md) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__row {
    &--1 {
      margin: 0 auto;
      padding-bottom: 3rem;
      margin-bottom: 3.75rem;
      border-bottom: 1px solid #dcdce5;

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
      }

      .container {
        @include media-breakpoint-up(lg) {
          padding: 0;
          margin: 0;
        }
      }
    }

    &--2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__col {
    width: 100%;

    &--header {
      @include media-breakpoint-up(lg) {
        max-width: 270px;
        margin-right: 3rem;
      }
    }

    &--featured-image {
      @include media-breakpoint-up(lg) {
        max-width: 760px;
      }
    }

    &--sidebar {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        position: sticky;
        top: 16.5rem;
        max-width: 270px;
        margin-right: 122px;
      }
    }

    &--main {
      @include media-breakpoint-up(lg) {
        max-width: 760px;
      }

      .l-section {
        &--background-white {
          @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: 0;
          }

          @include media-breakpoint-up(lg) {
            .container {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }

      .o-share-media {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      .o-text-editor-section {
        &__content {
          h3 {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  .o-download-section {
    @include media-breakpoint-up(md) {
      border-radius: 1.5rem;
    }

    &__container {
      @include media-breakpoint-up(md) {
        padding: 4rem;
      }
    }

    &__inputs {
      flex-direction: column;
    }

    &__input {
      max-width: 100%;
    }

    &__checkbox {
      .a-checkbox__label {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__button {
      max-width: 100%;
    }
  }
}
.o-mentioned-by-section {
  &__heading {
    font-family: $font-family-geomanist;
    font-size: 18px;
    font-weight: $font-weight-book;
    margin: 0 auto 2rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      margin: 0 auto 4rem;
    }

    &--grey {
      color: #919aaf;
    }

    &--white {
      color: $white;
    }

    &--black {
      color: $black;
    }
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      margin: 0 -4rem;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    width: 33.3333%;
    margin: 1rem 0;

    @include media-breakpoint-up(lg) {
      display: list-item;
      width: initial;
      margin: 0 4rem 4rem;
    }
  }

  .item {
    &__img {
      position: relative;
      width: 96px;
      height: 42px;
      display: block;

      @include media-breakpoint-up(lg) {
        width: 171px;
        height: 81px;
      }

      @include media-breakpoint-up(md) {
        &:hover {
          svg {
            opacity: 1;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          }

          img {
            opacity: 1;
            transform: translateY(-50%);
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          }

          .item__img-inner {
            opacity: 0;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          }

          .item__text {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.3s ease-in-out;
          }
        }
      }

      svg,
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 96px;
        height: 42px;
        display: block;
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

        @include media-breakpoint-up(lg) {
          width: 171px;
          height: 81px;
        }
      }

      img {
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        max-height: 81px;
        object-fit: contain;
      }
    }

    a.item__img {
      @include media-breakpoint-up(md) {
        &:hover {
          svg {
            transform: translateY(-1rem);
            opacity: 1;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          }

          img {
            transform: translateY(calc(-50% - 1rem));
            opacity: 1;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          }

          .item__img-inner {
            opacity: 0;
            transform: translateY(-1rem);
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          }
        }
      }
    }

    &__img-inner {
      position: absolute;
      left: 0;
      top: 0;
      width: 96px;
      height: 42px;
      display: block;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: #919aaf;
      opacity: 1;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        width: 171px;
        height: 81px;
      }
    }

    &__text {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      color: $blue-400;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  &__original-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      margin: 0 -4rem;
    }
  }

  &__original-item {
    display: flex;
    justify-content: center;
    width: 33.3333%;
    margin: 1rem 0;

    @include media-breakpoint-up(lg) {
      display: list-item;
      width: initial;
      margin: 0 4rem 4rem;
    }

    &-wrapper {
      position: relative;
      display: block;
      max-width: 96px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 171px;
      }

      img {
        width: 100%;
        max-height: 42px;
        transition: transform 0.3s ease-in-out;

        @include media-breakpoint-up(lg) {
          max-height: 81px;
        }
      }
    }

    &-text {
      display: none;
      position: absolute;
      left: 50%;
      bottom: 0;
      width: max-content;
      text-align: center;
      color: $blue-400;
      opacity: 0;
      transform: translateX(-50%);
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        a.o-mentioned-by-section__original-item-wrapper {
          img {
            transform: translateY(-4rem);
            transition: transform 0.3s ease-in-out;
          }

          .o-mentioned-by-section__original-item-text {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.3s ease-in-out;
          }
        }
      }
    }
  }

  &__slider {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      padding: 4rem 0;
    }
  }

  &__slide-track {
    animation: scroll var(--animation-speed) linear infinite;
    display: flex;
    width: calc(128px * (var(--slides-count) * 2)); // slide width (96px) + margin (2 * 2rem) * (slides count * 2)

    @include media-breakpoint-up(lg) {
      width: calc(235px * (var(--slides-count) * 2)); // slide width (171px) + margin (2 * 4rem) * (slides count * 2)
    }

    &:hover {
      animation-play-state: paused;
    }

    &--original-items {
      align-items: center;
    }
  }

  &__slide {
    width: 96px;
    margin: 0 2rem;

    @include media-breakpoint-up(lg) {
      width: 171px;
      margin: 0 4rem;
    }

    img {
      width: 96px;
      height: 42px;

      @include media-breakpoint-up(lg) {
        width: 171px;
        height: 81px;
      }
    }

    &--original-items {
      img {
        height: auto;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          a.o-mentioned-by-section__original-item-wrapper {
            img {
              transform: translateY(-4rem);
              transition: transform 0.3s ease-in-out;
            }

            .o-mentioned-by-section__original-item-text {
              opacity: 1;
              pointer-events: all;
              transition: opacity 0.3s ease-in-out;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      a.item__img {
        img {
          height: 100%;
          top: 0;
          transform: none;
        }
      }

      &:hover {
        a.item__img {
          svg {
            opacity: 1;
            transform: translateY(-4rem);
            transition: all 0.3s ease-in-out;
          }

          img {
            height: 100%;
            opacity: 1;
            transform: translateY(-4rem);
            transition: all 0.3s ease-in-out;
          }

          .item__img-inner {
            opacity: 0;
            transform: translateY(-4rem);
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-160px * var(--slides-count))); // slide width (96 + 8rem) * slides count
  }
}

@include media-breakpoint-up(lg) {
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-235px * var(--slides-count))); // slide width + margin (171 + 8rem) * slides count

    }
  }
}
.m-logos {
  &--items-margin {
    &-small {
      .m-logos {
        &__items {
          margin: 0 -0.5rem;
        }

        &__item {
          margin: 1rem 0.5rem;
        }
      }
    }

    &-normal {
      .m-logos {
        &__items {
          margin: 0 -1rem;
        }

        &__item {
          margin: 1rem 1rem;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    &--items-justify {
      &-center {
        justify-content: center;
      }

      &-start {
        justify-content: flex-start;
      }
    }
  }

  &__item {
    img {
      max-width: 250px;
      height: auto;
    }
  }
}
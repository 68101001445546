// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-geomanist: "Geomanist";
$font-family-roboto: "Roboto", sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 16px !default; // Assumes the browser default, typically `16px`
$font-size-lg: 18px !default;
$font-size-sm: 14px !default;

$font-weight-normal: 400 !default;
$font-weight-book: 500 !default;
$font-weight-medium: 600 !default;

$h1-font-size: 52px !default;
$h2-font-size: 40px !default;
$h3-font-size: 34px !default;
$h4-font-size: 24px !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

$h1-font-size-mobile: 26px;
$h2-font-size-mobile: 24px;
$h3-font-size-mobile: 20px;
$h4-font-size-mobile: 18px;

$headings-margin-bottom: 1rem !default;
$headings-font-family: $font-family-geomanist !default;
$headings-font-weight: $font-weight-medium !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($primary, 15%) !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;
$line-height-base: 1.25;

.o-posts-not-found {
  margin-top: 8rem;
  margin-bottom: 8rem;

  @include media-breakpoint-up(md) {
    margin: 15rem auto 12rem auto;
    max-width: 446px;
  }

  &__heading {
    color: $black;
    font-size: 20px;
    font-weight: $font-weight-book;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      font-weight: $font-weight-medium;
    }
  }

  &__description {
    &>* {
      color: $grey-400;
      font-size: 14px;
      line-height: 1.44;
      margin-bottom: 0;
    }

    a {
      color: $grey-400;
      transition: color $transition-base;

      &:hover {
        color: $blue-400;
        transition: color $transition-base;
      }
    }
  }
}
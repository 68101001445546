.m-footer-group {
  margin-bottom: 1.25rem;
  width: 100%;
  max-width: 50%;

  @include media-breakpoint-up(sm) {
    max-width: 180px;
  }

  @include media-breakpoint-up(md) {
    width: initial;
    padding: 2rem;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: 288px;
  }

  &:not(.m-footer-group--contact) {
    padding-right: 1rem;

    @include media-breakpoint-up(xs) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  &--contact {
    order: -1;
    max-width: 100%;
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #dcdce5;

    @include media-breakpoint-up(md) {
      order: initial;
      max-width: unset;
      margin-bottom: 0;
      border-bottom: none;
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .m-footer-group {
      &__list {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(xs) {
          display: block;
        }
      }

      &__item {
        width: 50%;

        @include media-breakpoint-up(md) {
          width: initial;
        }

        &:last-child {
          margin-bottom: 2rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &:empty {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__heading {
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $black;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin-bottom: 2rem;
    color: #919aaf;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }

    &--link {
      transition: color $transition-base;

      &:hover {
        color: $primary;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-link {
      display: inline-flex;
      align-items: baseline;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        line-height: 22.5px;
      }

      &:hover {
        .m-footer-group__item-external {
          svg path {
            fill: $primary;
            transition: fill $transition-base;
          }
        }
      }
    }

    &-text,
    &-phone,
    &-phone-prefix,
    &-link-prefix,
    &-link {
      font-size: 14px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    &-text,
    &-phone,
    &-link {
      color: #919aaf;
    }

    &-link,
    &-phone {
      transition: color $transition-base;

      &:hover {
        color: $primary;
      }
    }

    &-phone-prefix,
    &-link-prefix {
      margin-right: 8px;
      color: #222222;
    }

    &-external {
      display: flex;
      align-items: center;
      margin-left: 1rem;

      svg path {
        fill: #919aaf;
        transition: fill $transition-base;
      }
    }
  }
}
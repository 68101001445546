.m-event-list {
  &__items {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include media-breakpoint-up(md) {
      max-height: 380px;
      display: block;
      padding: 2rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.m-event-list-item {
  display: block;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem 0 rgba(39, 56, 80, 0.1);
  background-color: $white;
  opacity: 0.5;
  flex-shrink: 0;
  width: 100%;
  max-width: 212px;
  transition: opacity 0.2s ease-in-out;

  @include media-breakpoint-up(md) {
    max-width: initial;
    padding: 2rem 3rem;
  }

  &:hover {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &:first-child {
    margin-left: 2rem;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 2rem;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  &:not(:last-child) {
    margin-right: 2rem;

    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  &:not(.is-active) {
    cursor: pointer;
  }

  &.is-active {
    opacity: 1;
    background-color: #5384fa;

    .m-event-list-item {
      &__date {
        color: #dcdce5;
      }

      &__location {
        color: #dcdce5;
      }

      &__title {
        color: $white;
      }

      &__desc {
        color: #f2f3f5;

        * {
          color: #f2f3f5;
        }

        a {
          color: #f2f3f5;
        }
      }
    }
  }

  &__row {
    &--1 {
      display: inline-flex;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }

    &--2 {}

    &--3 {
      @include media-breakpoint-up(md) {
        margin-top: 0.5rem;
      }
    }
  }

  &__date {
    font-family: $font-family-roboto;
    color: #5384fa;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__location {
    margin: 0;
    font-family: $font-family-roboto;
    color: #5384fa;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__title {
    margin: 0;
    font-family: $font-family-geomanist;
    color: $grey-400;
    font-size: 18px;
    line-height: 24px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__desc {
    font-family: $font-family-roboto;
    color: $grey-400;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 28px;
    }

    * {
      margin: 0;
      font-family: $font-family-roboto;
      color: $grey-400;
      font-size: 14px;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    a {
      color: $grey-400;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
.o-exposed-text-section {
  &__text {
    font-family: $font-family-geomanist;
    font-weight: $font-weight-medium;
    color: $grey-400;
    font-size: 18px;
    line-height: 24px;
    padding: 3rem 2rem;
    border-top: 1px solid #dcdce5;
    border-bottom: 1px solid #dcdce5;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
      padding: 4rem 3rem;
    }
  }
}
.a-select {
  .choices__inner {
    transition: border-color $transition-base;
  }

  &.has-error {
    .a-input__label {
      color: $red-error;
      transition: color $transition-base;
    }

    .choices__inner {
      border-color: $red-error;
      transition: border-color $transition-base;
    }
  }

  select {
    width: 100%;
  }

  .choices__list {
    &--dropdown {
      .choices__item.choices__placeholder {
        display: none;
      }
    }
  }
}

.a-select__custom-text-option {
  display: none;

  &.is-active {
    display: block;
  }
}